<template>
  
<div class="hello contact">
    
    <div class="left">
    <h1 v-if="language=='fr'">CONTACTEZ-NOUS !
      <a href="https://www.facebook.com/visages.eyewear"><img src="../../public/assets/images/fb_icon.svg" height="15" /></a>
         <a href="https://www.linkedin.com/company/visages?trk=public_profile_topcard-current-company"><img src="../../public/assets/images/linkedin.svg" height="25" width="25" /></a>
         <a href="https://www.instagram.com/visages.eyewear?igsh=azU4MXRlbjhoZ3l3"><img src="../../public/assets/images/insta_icon.svg" height="15" /></a>
       
    </h1>
    <h1 v-else>CONTACT US
      <a href="https://www.facebook.com/visages.eyewear"><img src="../../public/assets/images/fb_icon.svg" height="15" /></a>
         <a href="https://www.linkedin.com/company/visages?trk=public_profile_topcard-current-company"><img src="../../public/assets/images/linkedin.svg" height="25" width="25" /></a>
         <a href="https://www.instagram.com/visages.eyewear?igsh=azU4MXRlbjhoZ3l3"><img src="../../public/assets/images/insta_icon.svg" height="15" /></a>
       
    </h1>

    <div class="faq-category contenudesk" v-if="language=='fr'">

<div class="question"><img src="../../public/assets/images/arrow_white.png" width="55" /> Comment VISAGES assure-t-elle la précision des lunettes sur-mesure à distance ?</div>
<div class="answer">
    VISAGES utilise un algorithme de scan 3D enrichi par Intelligence artificielle  pour garantir un ajustement parfait de chaque paire de lunettes. Après commande sur www.visages.com et le téléchargement de notre application mobile, un scan 3D de votre visage capture vos caractéristiques morphologiques avec une précision au dixième de millimètre. Cette technologie permet de créer des lunettes parfaitement ajustées, offrant confort et esthétique sur-mesure.
</div>
<div class="question"><img src="../../public/assets/images/arrow_white.png" width="55" />  Quel est le processus pour commander une paire de lunettes sur-mesure?</div>
<div class="answer">
    <ul>
        <li >Sélection du modèle :&nbsp;Choisissez votre modèle préféré.</li>
        <li >Personnalisation :&nbsp;Personnalisez votre paire (couleur, textures, branches, verres, gravures).</li>
        <li >Sélection du mode sur-mesure :&nbsp;Sélectionnez le mode sur-mesure, puis ajoutez-le au panier et validez votre commande.</li>
        <li >Scan du visage :&nbsp;Après l'achat, téléchargez notre application mobile pour effectuer le scan 3D.</li>
        <li>Livraison :&nbsp;Recevez vos lunettes sur-mesure directement chez vous.</li>
    </ul>
</div>
<div class="question"><img src="../../public/assets/images/arrow_white.png" width="55" /> Que faire si je ne suis pas satisfait(e) de mon sur-mesure?</div>
<div class="answer">
    Nous garantissons l'ajustement de vos lunettes sur-mesure. Si votre première commande ne vous satisfait pas à 100%, nous fabriquerons un nouvel article à nos frais. Vous pouvez soumettre votre demande via la page contact, jusqu'à 10 jours après votre achat. 
</div>
<div class="question"><img src="../../public/assets/images/arrow_white.png" width="55" /> Comment fonctionne l’impression 3D?</div>
<div class="answer">
    L'impression 3D est une méthode de fabrication additive qui, contrairement aux techniques traditionnelles soustractives, utilise précisément la matière nécessaire pour chaque produit fabriqué. Nous bénéficions d'une grande variété de technologies d'impression 3D (SLS, MJF, DLP, SLM...), et fabriquons ainsi à partir de matériaux biosourcés, de résines translucides, de nylons multicolores ou encore de titane. VISAGES vise à exploiter pleinement cette technologie pour permettre aux artistes et aux marques de publier librement leurs créations sur la plateforme visages.com, en expérimentant de nouveaux concepts. Produites en Europe, sans contraintes de stock, à l’unité, à la demande, et avec une garantie zéro déchet, ces lunettes représentent un mouvement révolutionnaire pour l’industrie lunetière que VISAGES souhaite étendre.
</div>

<div class="question"><img src="../../public/assets/images/arrow_white.png" width="55" /> Quel est le délai de livraison pour ma commande?</div>
<div class="answer">
    Le délai de livraison varie selon le produit et sera indiqué lors du paiement.
</div>



</div>

<div class="faq-category contenudesk" v-else>

<div class="question"><img src="../../public/assets/images/arrow_white.png" width="55" /> How does VISAGES ensure the precision of custom glasses remotely?</div>
<div class="answer">
    VISAGES uses a 3D scanning algorithm enhanced by artificial intelligence to ensure a perfect fit for each pair of glasses. After ordering on www.visages.com and downloading our mobile app, a 3D scan of your face captures your unique features with 1/10th of a millimeter accuracy. This technology creates perfectly fitted glasses, offering both comfort and custom aesthetics.
</div>
<div class="question"><img src="../../public/assets/images/arrow_white.png" width="55" />  What is the process for ordering a pair of custom glasses?</div>
<div class="answer">
    <ul>
        <li >Model selection: Choose your favorite model.</li>
        <li >Customisation: Customize your pair (color, textures, temples, lenses, engravings).</li>
        <li >Made-to-fit option: Select the made-to-fit option, then add it to your cart and complete your order.</li>
        <li >Face scan: After purchase, download our mobile app to perform the 3D scan.</li>
        <li>Delivery: Receive your made-to-fit glasses directly at home.</li>
    </ul>
</div>
<div class="question"><img src="../../public/assets/images/arrow_white.png" width="55" /> What if I am not satisfied with my custom-made order?</div>
<div class="answer">
    We guarantee the fit of your custom-made glasses. If your first order does not satisfy you 100%, we will manufacture a new item at our expense. You can submit your request via the contact page, up to 10 days after your purchase.
</div>
<div class="question"><img src="../../public/assets/images/arrow_white.png" width="55" /> How does 3D printing work?</div>
<div class="answer">
    3D printing is an additive manufacturing method that, unlike traditional subtractive techniques, uses precisely the material needed for each product. We benefit from a wide variety of 3D printing technologies (SLS, MJF, DLP, SLM...), and thus manufacture using biosourced materials, translucent resins, multicolored nylons, and even titanium. VISAGES aims to fully harness this technology to allow artists and brands to freely publish their creations on the visages.com platform, experimenting with new concepts. Produced in Europe, without stock constraints, individually, on-demand, and with a zero-waste guarantee, these glasses represent a revolutionary movement for the eyewear industry that VISAGES seeks to expand.

</div>

<div class="question"><img src="../../public/assets/images/arrow_white.png" width="55" /> What is the delivery time for my order?</div>
<div class="answer">
    The delivery time varies depending on the product and will be indicated at checkout.
</div>



</div>
            <br />
            <router-link v-if="language=='fr'" class="link-faq contenudesk" to="/faq">Accédez à la FAQ</router-link>
            <router-link v-else class="link-faq contenudesk" to="/faq">Go to FAQ</router-link>
       
    </div>

      <content-loader  v-if="loading" class="right"
              viewBox="0 0 520 650"
              primaryColor="#f3f3f3"
              secondaryColor="#cccccc"
          >

            
           
            <rect x="0" y="100" rx="17" ry="17" width="250" height="34" />
            <rect x="270" y="100" rx="17" ry="17" width="250" height="34" />
          
            <rect x="00" y="150" rx="17" ry="17" width="520" height="34" />
            <rect x="00" y="200" rx="17" ry="17" width="520" height="34" />
            <rect x="00" y="250" rx="17" ry="17" width="520" height="34" />
            <rect x="00" y="300" rx="17" ry="17" width="520" height="100" />
            <rect x="0" y="350" rx="17" ry="17" width="250" height="34" />
        </content-loader>
    <div v-else class="right">
       
      
        <div class="content">
          
          <form v-on:submit.prevent="sendForm()" v-if="displayForm">
         
          <br>
          <div class="line-form">
            <input type="text" v-if="language=='fr'" v-model="form.name" name="form_contact_name" placeholder="Nom*">
            <input type="text" v-else v-model="form.name" name="form_contact_name" placeholder="First name*">
            <input type="text" v-if="language=='fr'" v-model="form.surname" name="form_contact_phone" placeholder="Prénom*">
            <input type="text" v-else v-model="form.surname" name="form_contact_phone" placeholder="Last name*">
          </div>
             <br>
             <select v-model="form.object" v-if="language=='fr'">

                <option value="" disabled selected>Sélectionnez l'objet de votre demande*</option>
                <option>Demande Presse</option>
                <option>Service après vente</option>
                <option>Demande collaboration</option>
                <option>Autre demande</option>
             </select>
             <select v-model="form.object"  v-else>

              <option value="" disabled selected>Select an object of request*</option>
              <option>Press request</option>
              <option>After sales service</option>
              <option>Collaboration request</option>
              <option>Other request</option>
            </select>
             <br>
            <input type="email" v-if="language=='fr'" v-model="form.email" name="form_contact_email" placeholder="E-mail*">
            <input type="email"  v-else v-model="form.email" name="form_contact_email" placeholder="E-mail*">
            <br>
            <input type="text" v-if="language=='fr'" v-model="form.phone" name="form_contact_email" placeholder="Téléphone*">
            <input type="text"  v-else v-model="form.phone" name="form_contact_email" placeholder="Phone*">
            <br>
            <textarea placeholder="Message*" v-model="form.message" name="form_contact_message"></textarea>
            <br>
          <button v-if="language=='fr'" class="send-form" type="submit">Envoyer </button>
          <button  v-else class="send-form" type="submit">Send </button>
          <br /><br />
          </form>
          <br />
          
          {{errorMessage}}
          <div v-if="successMessage!=''" class="success-message" >
            <template v-if="language=='fr'">
              <div class="content-success">Merci pour votre demande ! Votre message a bien été reçu et sera traité au plus vite.
                <br /><br />
                <center><img class="contact-valid" src="../../public/assets/images/ok.png" width="34" /></center>
                <br /><br />
                <a @click="successMessage=''" class="link">Rédiger un nouveau message</a>

              </div>
            </template>
            <template v-else>
              <div class="content-success">Thanks for contacting us! Your message has been received and we’ll respond shortly.
                <br /><br />
                <center><img class="contact-valid" src="../../public/assets/images/ok.png" width="34" /></center>
                <br /><br />
                <a @click="successMessage=''" class="link">Send a new message</a>

              </div>
            </template>
          </div>
          
                    
        </div>

        <div class="faq-category contenumob" v-if="language=='fr'">

<div class="question"><img src="../../public/assets/images/arrow_white.png" width="35" /> Comment VISAGES assure-t-elle la précision des lunettes sur-mesure à distance ?</div>
<div class="answer">
    VISAGES utilise un algorithme de scan 3D propriétaire, enrichi par intelligence artificielle, pour garantir un ajustement parfait de chaque paire de lunettes à distance. Après avoir passé commande sur www.visages.com et téléchargé notre application mobile, un scan 3D de votre visage est réalisé. Cette technologie avancée capture avec une précision médicale, au dixième de millimètre, vos caractéristiques morphologiques et optiques uniques. Cette approche innovante permet de créer des lunettes parfaitement adaptées à votre morphologie, offrant ainsi un confort inégalé et une esthétique sur-mesure.
</div>
<div class="question"><img src="../../public/assets/images/arrow_white.png" width="35" />  Quel est le processus pour commander une paire de lunettes sur-mesure?</div>
<div class="answer">
    <ul>
        <li >Sélection du modèle :&nbsp;Commencez par choisir votre modèle préféré.</li>
        <li >Personnalisation :&nbsp;Personnalisez votre paire (couleur, textures, branches, verres, gravures).</li>
        <li >Sélection sur-mesure :&nbsp;Pour l'option sur-mesure, ajoutez au panier et payez.</li>
        <li >Scan du visage :&nbsp;Après achat, téléchargez notre application mobile pour effectuer le scan 3D nécessaire pour la mise à la mesure.</li>
        <li>Livraison :&nbsp;Recevez vos lunettes sur-mesure chez vous en 7 à 15 jours après avoir effectué le scan.</li>
    </ul>
</div>
<div class="question"><img src="../../public/assets/images/arrow_white.png" width="35" /> Que faire si je ne suis pas satisfait(e) de mon sur-mesure?</div>
<div class="answer">
    Nous accordons une grande importance à l'ajustement de vos lunettes sur-mesure et à la garantie de notre service de mise à mesure. Nous avons tellement confiance en notre algorithme de mise a la mesure, que si l'ajustement de votre première commande ne vous satisfait pas à 100%, nous nous engageons à fabriquer un nouvel article, à nos frais. Vous avez la possibilité de soumettre votre demande ici, jusqu'à 1 mois après votre achat. Notre priorité est de vous assurer une satisfaction totale avec des lunettes qui vous correspondent parfaitement.
</div>
<div class="question"><img src="../../public/assets/images/arrow_white.png" width="35" /> Comment fonctionne l’impression 3D?</div>
<div class="answer">
    L'impression 3D est une méthode de fabrication additive qui, contrairement aux techniques traditionnelles soustractives, utilise précisément la matière nécessaire pour chaque paire de lunettes. Cette approche assure une production sans déchet, alignée avec notre engagement pour la durabilité et la responsabilité environnementale. Pour en savoir plus sur notre processus de fabrication par l’impression 3D, <a href="/comment-ca-marche">cliquez ici</a>.
</div>

<div class="question"><img src="../../public/assets/images/arrow_white.png" width="35" /> Quel est le délai de livraison pour ma commande?</div>
<div class="answer">
    Le délai de livraison de votre commande peut varier en fonction de votre panier et sera indiqué au moment du check-out.
</div>



</div>
<div class="faq-category contenumob" v-else>

<div class="question"><img src="../../public/assets/images/arrow_white.png" width="35" /> How does VISAGES ensure the precision of custom glasses remotely?</div>
<div class="answer">
VISAGES uses a proprietary 3D scanning algorithm, enhanced by artificial intelligence, to guarantee a perfect fit for every pair of glasses remotely. After placing an order on www.visages.com and downloading our mobile app, a 3D scan of your face is performed. This advanced technology captures your unique morphological and optical characteristics with medical precision, down to the tenth of a millimeter. This innovative approach allows us to create glasses perfectly suited to your morphology, offering unparalleled comfort and a custom aesthetic.   
</div>
<div class="question"><img src="../../public/assets/images/arrow_white.png" width="35" /> What is the process for ordering a pair of custom glasses?</div>
<div class="answer">
  <ul>
      <li >Model selection: Start by choosing your preferred model.</li>
      <li >Customisation: Customise your pair (color, textures, temples, lenses, engravings).</li>
      <li >Custom selection: For the custom option, add to cart and pay.</li>
      <li >Face scan: After purchase, download our mobile app to perform the necessary 3D scan for custom-fitting.</li>
      <li>Delivery: Receive your custom glasses at home.</li>
  </ul>
</div>
<div class="question"><img src="../../public/assets/images/arrow_white.png" width="35" /> What if I am not satisfied with my custom-made order?</div>
<div class="answer">
We place great importance on the fit of your custom-made glasses and the guarantee of our custom-fit service. We have so much confidence in our custom-fit algorithm that if the fit of your first order does not satisfy you 100%, we commit to manufacturing a new item, at our expense. You have the option to submit your request here, up to 1 month after your purchase. Our priority is to ensure your total satisfaction with glasses that fit you perfectly.
</div>
<div class="question"><img src="../../public/assets/images/arrow_white.png" width="35" /> How does 3D printing work?</div>
<div class="answer">
3D printing is an additive manufacturing method that, unlike traditional subtractive techniques, uses precisely the necessary material for each pair of glasses. This approach ensures a waste-free production, aligned with our commitment to sustainability and environmental responsibility. To learn more about our 3D printing manufacturing process, <a href="/en/comment-ca-marche">click here</a>.
</div>

<div class="question"><img src="../../public/assets/images/arrow_white.png" width="35" /> What is the delivery time for my order?</div>
<div class="answer">
The delivery time for your order may vary depending on your cart and will be indicated at the time of checkout.
</div>



</div>

        
<router-link v-if="language=='fr'" class="link-faq contenumob" to="/faq">Accédez à la FAQ</router-link>
            <router-link v-else class="link-faq contenumob" to="/faq">Go to FAQ</router-link>

          </div>
    </div>
  
</template>

<script>
import jQuery  from 'jquery';
import {APIRequest} from '@simb2s/senseye-sdk'
import { useMeta } from 'vue-meta'
import { ContentLoader } from 'vue-content-loader'
export default {
  name: 'Contact',
  components: {
    ContentLoader
  },
  props:["language"],
  data() {
    return {
        form:{
          name:'',
          surname:'',
          email:'',
          phone:'',
          object:'',
          message:'',
        },
        loading:false,
        errorMessage:'',
        successMessage:'',
        displayForm:true
    }
  },
  created() {

    useMeta({
    title: "VISAGES - Contact",
    
    htmlAttrs: { lang: this.language, amp: true },
    
})



    if(this.language=="fr")
    {
      document.querySelector('meta[name="description"]').setAttribute('content',"Contactez l'équipe VISAGES.")
    }
    else
    {
      document.querySelector('meta[name="description"]').setAttribute('content',"Contact the VISAGES team.")
      
    }
    

  },
  mounted(){
    jQuery(document).ready(function(){
								
          
            
                jQuery('.faq-category .question').click(function(){
                    
                    if(jQuery(this).next('.answer').is(':visible'))
                    {
                        jQuery(this).next('.answer').slideUp();
                        jQuery(this).removeClass('open');
                    }
                    else
                    {
                        jQuery(this).addClass('open');
                        jQuery(this).next('.answer').slideDown();
                    }
                    
                });
                
            });
  },
  watch:{
        language:function () {
          setTimeout(() => {
                jQuery('.faq-category .question').click(function(){
                
                if(jQuery(this).next('.answer').is(':visible'))
                {
                    jQuery(this).next('.answer').slideUp();
                    jQuery(this).removeClass('open');
                }
                else
                {
                    jQuery(this).addClass('open');
                    jQuery(this).next('.answer').slideDown();
                }
                
            });
            }, 500);
        }
    },
  methods:{
    async sendForm(){
      
      if(this.form.email=='')
      {
        if(this.language=='fr')
        {
          this.errorMessage='Tous les champs sont obligatoires !';
        }
        else
        {
          this.errorMessage='All fields are required!';
        }        
        this.successMessage='';
      }
      else
      {
        this.loading=true
        let Parameters=this.form
        try {
          await APIRequest.create('contacts',Parameters)
          this.errorMessage='';
          if(this.language=='fr')
          {

            this.successMessage='Votre demande a bien été envoyée !';
          }
          else
          {
            this.successMessage='Your request has been sent!';
          }
          //this.displayForm=false
          this.loading=false
        } catch (error) {
          if(this.language=='fr')
          {

            this.errorMessage="Une erreur s'est produite, veuillez réessayer";
          }
          else
          {
            this.errorMessage="An error occured, please retry.";
          }
          this.successMessage='';
          this.loading=false
        }
        
      }
    }
  }
  
}
</script>
<style scoped>
.success-message .content-success .link
{
  color:#000;
  cursor: pointer;
}
.contact-valid
{
  filter: brightness(100) invert(1);
}
.success-message .content-success
{
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  text-align: center;
  max-width:400px;
  font-size:16px;
}
.success-message
{
  position:absolute;
  top:0;
  width:100%;
  background:rgba(255,255,255,0.8);
  height:100%;
}
.link-faq
{
  color:#000;
  font-size:16px;
  font-weight: 600;
}
.line-form {
  width:100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.question{
    display: flex;
    align-items: center;
    border-bottom:0.25px solid black;
}
.question:first-child{
    border-top:0.25px solid black;
}
.section.faq {
    padding-top:0px;
    padding-bottom:250px;
    padding-left:20%;
    padding-right:20%;
}
.question {
    text-transform: none;
    font-size:16px;
    line-height:20px;
}
h2.title img,

.question img {
    margin-right:15px;
    display: inline-block;
}
.faq-category .question.open::after {
   
   
   content: '-';
 

}
.hello.contact .content .send-form:hover
{
  background:#fff !important;
  color:#000 !important;
}
.hello .content
{
  font-size:15px;
}
.hello.contact .content .send-form
{
  border-radius: 0 !important;
  background-color: #000 !important;
  font-size:15px !important;
  font-weight: 600 !important;
  padding-left: 80px !important;
    padding-right: 80px !important;
}
.faq-category .question::after {
   line-height: 2;
    font-size:17px;
    position: absolute;
    height: 40px;
    width: 40px;
    top: 50%;
    transform: translateY(-50%);
    right: -20px;
    transition: all 0.5s;
    content: '+';
    background:transparent;

}
.left {
  padding:5%;
}
@media screen and (max-width:1024px)
{
  .contenumob
  {
    display:block !important;
  }
  .contenudesk
  {
    display:none !important;
  }
  body .hello .right
  {
    padding-top:0 !important;
    padding-bottom:100px !important;
  }
}
@media screen and (max-width:760px)
{
  .success-message .content-success
  {
    width:80%;
  }
  body .hello .right
  {
    padding-top:0 !important;
    padding-bottom:100px !important;
  }
  h1 a
  {
    top:20px !important;
    width:40px !important;
    height:40px !important;
  }
  h1 a img
  {
    top:7px !important;
  }
}
h1 a img {
  display: inline-block;
  position:relative;
  top:17px;
  vertical-align: top;
}
h1 a {
  width:64px;
  position: relative;
  top:30px;
  height:64px;
  vertical-align: top;
  display: inline-block;
  border:2px solid black;
  margin-left:15px;
  border-radius:50%;
  text-align: center;
}
h1
{
  word-wrap: normal;
  word-break:auto-phrase;
  
  font-size:90px;
  font-weight: 300;
  
}
.hello.contact .content option{
  font-weight: normal;
    font-size: 14px;
    min-width: 200px;
    padding: 15px 15px;
    border: 0.5px solid rgb(129, 129, 129);
    border-radius: 0;
    background: transparent;
    color: rgb(0, 0, 0);
    display: block;
    text-decoration: none;
    
    margin-right: 2px;
    box-shadow: none;
}
.hello.contact .content select,
.hello.contact .content textarea
{
  font-weight: 400;
    font-size: 14px;
    min-width: 200px;
    font-family: "degular";
    width:100%;
    padding: 15px 15px;
    border: 0.5px solid rgb(129, 129, 129);
    border-radius: 0;
    background: transparent;
    color: rgba(113, 113, 113, 1);
    display: block;
    text-decoration: none;
    
    margin-right: 2px;
    box-shadow: none;
}
.hello.contact .content select::placeholder,
.hello.contact .content textarea::placeholder,
.hello.contact .content input::placeholder{
color:rgba(113, 113, 113, 1);
}

body .hello.contact .content .line-form input
{
  min-width: 0px;
  width:49%;
}
.hello.contact .content input[type=email],
.hello.contact .content input[type=text]
{
  font-weight: 400;
    font-size: 14px;
    min-width: 200px;
    width:100%;
    padding: 15px 15px;
    border: 0.5px solid rgb(129, 129, 129);
    border-radius: 0;
    background: transparent;
    color: rgba(113, 113, 113, 1);
    display: block;
    text-decoration: none;
    margin-right:0;
    box-shadow: none;
}
</style>