<template>
      <div class="section scan"> 
        <div class="scan-content">
          <div class="left">
            <div class="title">SCAN YOUR FACE</div>
            <div class="description">
              Get the app to scan your face and order custom-fitted products, to your unique traits. 
            </div>
            <div class="button-scan"><img  width="15" src="../../../../public/assets/home/apple-w.svg" /> <span>Sign-up as a *beta-tester*</span></div>
            <div class="qr contenudesk">
              <img src="../../../../public/assets/home/qr.jpg" />
            </div>
          </div>
          <div class="right">
            <img src="../../../../public/assets/home/iphone.png" />
          </div>
        </div>
      </div>
</template>

<script>
export default {
    name: "HomeVisu",
}
</script>

<style scoped>
.scan-content .right img {
  border-bottom-right-radius:39px;
  width:70%;
  margin-top:50px;
  margin-left:auto;
  margin-right:0;
}
.section.scan {
  padding-top:150px;
  padding-bottom:150px;
  padding-left:5%;
  padding-right:5%;

}
.scan-content {
  border-radius:39px;
  background:#F8F8F8;
  display:flex;
  padding-top:50px;
}
.scan-content .button-scan img {
  display:inline-block;
  margin-right:10px;
}


.scan-content .button-scan span {
  position: relative;
    top: -3px;
    font-size: 15px;
   
    margin-left: 10px;
}
.scan-content .button-scan{
    font-weight: normal;
    border-radius: 48px;
    margin-top:20px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
    background: #000;
    padding-right: 30px;
    text-align:center;
    display:inline-block;
    color: #fff;
}
.scan-content .title {
  font-size:50px;
  margin-bottom:20px;
}
.scan-content .qr img{
  width:150px;
}
.scan-content .qr
{
  margin-top:20px;
  margin-bottom:50px;
}
.scan-content .description {
  max-width:300px;
}
.scan-content .left {
  width:50%;
  padding-left:5%;
}
.scan-content .right {
  width:50%;
  text-align:right;
}
</style>