<template>
    <div id="popup-login" @click="closeModal($event)" class="popup-login login">

      <div class="popup" v-if="displayPopupDevices">
        <span class="bg"></span>

        <div class="content-popup">
        <span id="cross" @click="displayPopupDevices=false"><img src="../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="12" /></span>

       
          <div class="text" v-if="language=='fr'">
            Notre scan facial requiert un appareil Apple équipé de Face ID.
          </div>
          <div class="text" v-else>
            Our facial scan requires an Apple device equipped with Face ID.
          </div>
          <div class="list-title"  v-if="language=='fr'">
            LES APPAREILS APPLE COMPATIBLES INCLUENT :
          </div>
          <div class="list-title"  v-else>
            Compatible Devices Include:
          </div>
          <div class="list-items">
            <div class="list-item">
              <center>iPhone X, iPhone XR, iPhone XS, iPhone 11, iPhone 12, iPhone 13, iPhone 14, iPhone 15</center>
            </div>
            <div class="list-item">
            <center> iPad Pro 11p, iPad Pro 12,9p</center>
            </div>
          </div>
          <div class="list-title"  v-if="language=='fr'">
            COMMENT VÉRIFIER LE MODÈLE DE VOTRE APPAREIL ?
          </div>
          <div class="list-title" v-else>
            How to Check Your Device Model?
          </div>
          <div class="list-items" v-if="language=='fr'">
            <div class="list-item">
              <div class="num">1</div>
              Ouvrez l’application Réglages sur votre iPhone ou iPad.
            </div>
            <div class="list-item">
              <div class="num">2</div>
              Allez dans la section Général.
            </div>
            <div class="list-item">
              <div class="num">3</div>
              Cliquez sur Informations.
            </div>
            <div class="list-item">
              <div class="num">4</div>
              Vous trouverez le “Nom du modèle” affiché clairement ici.
            </div>
          </div>
          <div class="list-items" v-else>
            <div class="list-item">
              <div class="num">1</div>
              Open the Settings app on your iPhone or iPad.
            </div>
            <div class="list-item">
              <div class="num">2</div>
              Go to the General section.
            </div>
            <div class="list-item">
              <div class="num">3</div>
              Click on About.
            </div>
            <div class="list-item">
              <div class="num">4</div>
              You will find the “Model Name” displayed clearly here.
            </div>
          </div>

          <div class="text little" v-if="language=='fr'">
            Besoin d’aide ? <a class="link" href="/contact">Contactez-nous</a> dès maintenant et nous serons heureux de vous assister.
          </div>
          <div class="text little" v-else>
            Need help? <a class="link" href="/contact">Contact us</a> and we will be happy to assist you.
          </div>
        </div>
      </div>
			<div class="content">
				<span id="cross" @click="closeLogin()"><img src="../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="12" /></span>

        <content-loader v-if="loadingPopup==true" viewBox="0 0 520 230" primaryColor="#f3f3f3" secondaryColor="#cccccc">

          <rect x="0" y="0" rx="0" ry="0" width="230" height="230" />
          <rect x="320" y="20" rx="5" ry="5" width="150" height="10" />
          <rect x="320" y="60" rx="5" ry="5" width="150" height="10" />
          <rect x="320" y="100" rx="5" ry="5" width="150" height="10" />
          <rect x="320" y="140" rx="5" ry="5" width="150" height="10" />
          <rect x="320" y="180" rx="5" ry="5" width="150" height="10" />

        </content-loader>
				<div class="left" v-if="loadingPopup==false && betaAccess==false">
          <div class="gradient"></div>
        
          <p class="p" v-if="displayLoginForm">
            <div v-if="review==true">
              <span v-if="language=='fr'">Vous devez être connecté pour laisser un avis.</span>
              <span v-else>You must be logged in to post a review.</span>
            </div>
            <div v-else-if="saving==true">
              <span v-if="language=='fr'">Vous devez être connecté pour enregistrer un modèle.</span>
              <span v-else>You must be logged in to save a model.</span>
            </div>
            <div v-else>
              <div class="text-big" v-if="language=='fr'">
                Rejoignez notre programme Beta et accédez à l'expérience Sur-Mesure.
              </div>
              <div class="text-big" v-else>
                Join our Beta program today & Access the Custom Fit feature. Explore the future of eyewear with Visages.
              </div>
             
              <br /><br />
              <template  v-if="language=='fr'">
                <a target='_blank' href="/collection-beta-new">
                  <span class="a">
                    En savoir plus
                  </span> 
                  <img src="../../../public/assets/images/question.svg" class="question-img" />
                </a>
               </template>
              <template  v-else>
                <a target='_blank' href="/collection-beta-new">
                  <span class="a">
                    More informations
                  </span>
                  <img src="../../../public/assets/images/question.svg" class="question-img" />
                </a>

              </template>
            </div>
          </p>
          <p v-else-if="displayRegisterForm" class="p" >
            <div v-if="review==true">
              <span v-if="language=='fr'">Vous devez être connecté pour laisser un avis.</span>
              <span v-else>You must be logged in to post a review.</span>
            </div>
            <div v-else-if="saving==true">
              <span v-if="language=='fr'">Vous devez être connecté pour enregistrer un modèle.</span>
              <span v-else>You must be logged in to save a model.</span>
            </div>
            <div v-else>
              <div class="text-big" v-if="language=='fr'">
                Rejoignez notre programme Beta et accédez à l'expérience Sur-Mesure.
              </div>
              <div class="text-big" v-else>
                Join our Beta program today & Access the Custom Fit feature. Explore the future of eyewear with Visages.
              </div>
             
              <br /><br />
              <template  v-if="language=='fr'">
                <a target='_blank' href="/collection-beta-new">
                  <span class="a">
                    En savoir plus
                  </span> 
                  <img src="../../../public/assets/images/question.svg" class="question-img" />
                </a>
               </template>
              <template  v-else>
                <a target='_blank' href="/collection-beta-new">
                  <span class="a">
                    More informations
                  </span>
                  <img src="../../../public/assets/images/question.svg" class="question-img" />
                </a>

              </template>
            </div>
          </p>
          <p v-else class="p" >
          <div >
            <div class="text-big" v-if="language=='fr'">
                Rejoignez notre programme Beta et accédez à l'expérience Sur-Mesure.
              </div>
              <div class="text-big" v-else>
                Join our Beta program today & Access the Custom Fit feature. Explore the future of eyewear with Visages.
              </div>
             
              <br /><br />
              <template  v-if="language=='fr'">
                <a target='_blank' href="/collection-beta-new">
                  <span class="a">
                    En savoir plus
                  </span> 
                  <img src="../../../public/assets/images/question.svg" class="question-img" />
                </a>
               </template>
              <template  v-else>
                <a target='_blank' href="/collection-beta-new">
                  <span class="a">
                    More informations
                  </span>
                  <img src="../../../public/assets/images/question.svg" class="question-img" />
                </a>

              </template>
            </div>
            </p>

            <img src="../../../public/assets/images/beta-w.png" class="beta-left" />
        </div>
        <div class="right" :class="{full:betaAccess}" v-if="loadingPopup==false">
          <template v-if="displayLoginForm || displayRegisterForm">

            <template v-if="pending_message">
              <div class="footer">
                <a v-if="language=='fr'" @click="displayRegisterForm=false;displayLoginForm=true;pending_message=false;" class="choice-login-form" :class="{active:displayLoginForm}"  data-form="login">
                Connexion
                </a>

                <a v-else @click="displayRegisterForm=false;displayLoginForm=true;pending_message=false;" class="choice-login-form" :class="{active:displayLoginForm}"  data-form="login">
                Log in
                </a>
                
                <a v-if="language=='fr'" class="choice-login-form" data-form="register" @click="pending_message=false;displayRegisterForm=true;displayLoginForm=false"  :class="{active:displayRegisterForm}">
                Inscription
                </a>

                <a v-else class="choice-login-form" data-form="register" @click="pending_message=false;displayRegisterForm=true;displayLoginForm=false"  :class="{active:displayRegisterForm}">
                Register
                </a>
              </div>
              <div class="text" v-if="language=='fr'">
                  <br /><br />
                  <div class="bold">Votre demande a été envoyée.</div><br /><br />
                   Si elle est acceptée, vous recevrez un email dans votre boîte de réception.
                      <br />Veuillez suivre les instructions dans l'email pour activer votre compte.

                  <br /><br />
                  <center><img  src="../../../public/assets/images/sablier.png" width="15" /></center><br /><br />
                  <div class="button-white active" @click="closeLogin()">
                    Retour à la personnalisation
                  </div>
                
              </div>
              <div class="text" v-else>
                  <br /><br />
                  
                  <div class="bold">Your request has been sent.</div><br /><br /> If accepted, you will receive an email in your inbox. 
                  <br />Please follow the instructions in the email to activate your account. 
                  <br /><br />
                  <center><img  src="../../../public/assets/images/sablier.png" width="15" /></center><br /><br />

                  <div class="button-white active" @click="closeLogin()">
                    Back to customization
                  </div>
                
              </div>
            </template >
            <template v-else-if="displayNoneStatus">
              <div class="footer">
                <a v-if="language=='fr'" @click="displayNoneStatus=false;displayRegisterForm=false;displayLoginForm=true" class="choice-login-form" :class="{active:displayLoginForm}"  data-form="login">
                Connexion
                </a>

                <a v-else @click="displayNoneStatus=false;displayRegisterForm=false;displayLoginForm=true" class="choice-login-form" :class="{active:displayLoginForm}"  data-form="login">
                Log in
                </a>
                
                <a v-if="language=='fr'" class="choice-login-form" data-form="register" @click="displayNoneStatus=false;displayRegisterForm=true;displayLoginForm=false"  :class="{active:displayRegisterForm}">
                Inscription
                </a>

                <a v-else class="choice-login-form" data-form="register" @click="displayNoneStatus=false;displayRegisterForm=true;displayLoginForm=false"  :class="{active:displayRegisterForm}">
                Register
                </a>
              </div>

                <div class="text-bold"  v-if="language=='fr'">
                    Votre compte n'a pas encore été créé.
                </div>
                <div class="text-bold"  v-else>
                    YOUR ACCOUNT HASN’T YET BEEN CREATED.
                </div>
                <div class="text"  v-if="language=='fr'">
                    Votre compte n'a pas encore été créé. Rejoignez dès aujourd'hui notre programme Bêta et explorez l'avenir de la lunetterie avec Visages. Découvrez nos technologies de pointe et bénéficiez d'un accès exclusif à de nouveaux modèles, tout en ayant la possibilité de fournir des retours qui influenceront nos futures collections.
                </div>
                <div class="text"  v-else>
                    Your account hasn’t yet been created. Join our Beta program today and explore the future of eyewear with Visages. Discover our cutting-edge technologies, and receive exclusive access to new designs with the opportunity to provide feedback that will influence our future collections.
                </div>

                <br />
                <div class="button-white active" v-if="language=='fr'" @click="displayLoginForm=false;displayRegisterForm=true;displayNoneStatus=false;" >Faire une demande d'accès BETA</div>
                <div class="button-white active" v-else @click="displayLoginForm=false;displayRegisterForm=true;displayNoneStatus=false;" >Ask BETA access</div>
            </template>
            <template v-else>
              
                <div class="footer">
                <a v-if="language=='fr'" @click="displayRegisterForm=false;displayLoginForm=true" class="choice-login-form" :class="{active:displayLoginForm}"  data-form="login">
                Connexion
                </a>

                <a v-else @click="displayRegisterForm=false;displayLoginForm=true" class="choice-login-form" :class="{active:displayLoginForm}"  data-form="login">
                Log in
                </a>
                
                <a v-if="language=='fr'" class="choice-login-form" data-form="register" @click="displayRegisterForm=true;displayLoginForm=false"  :class="{active:displayRegisterForm}">
                Inscription
                </a>

                <a v-else class="choice-login-form" data-form="register" @click="displayRegisterForm=true;displayLoginForm=false"  :class="{active:displayRegisterForm}">
                Register
                </a>
              </div>
              
              <div class="login-form" v-if="displayLoginForm">
                <br /><br />
                <div class="input-line">
                  <input type="text" v-if="language=='fr'" :class="{error:errors_login.username}"  id="login" v-model="username" placeholder="Nom d'utilisateur" />
                  <input type="text" v-else :class="{error:errors_login.username}"  id="login" v-model="username" placeholder="Username" />
                </div>
                <div v-if="errors_login.username" class="error-message">
                    {{errors_login.username}}
                </div>  
                <div class="input-block">
                    <div class="input-line">
                      <input type="password" :class="{error:errors_login.password}" id="password" v-model="password" placeholder="******" /><a v-if="language=='fr'" class="forgot" @click="forgotPassword()">Mot de passe oublié ?</a><a v-else class="forgot" @click="forgotPassword()">Forgot password?</a>
                    </div>
                    <div v-if="errors_login.password" class="error-message">
                        {{errors_login.password}}
                    </div>
                  </div>
                <button v-if="language=='fr'" id="login_button" @click="postLogin()">Connexion</button>
                <button v-else id="login_button" @click="postLogin()">Log in</button>
                
                <div id="error-message">
                  <br />
                  <center>{{errorLogin}}</center>

                </div>
                <br /><br />
                
              </div>
              <div class="register-form" v-if="displayRegisterForm">
                <div >
                  <div class="input-line">
                    <input  type="text" :class="{error:errors_register.email}"  id="email_register" v-model="email" placeholder="Email*" />
                   
                  </div>
                  <div v-if="errors_register.email" class="error-message">
                        {{errors_register.email}}
                    </div>
                
                  <div class="form-line">
                    <input  v-if="language=='fr'" type="text" :class="{error:errors_register.surname}"  id="name_register" v-model="surname" placeholder="Prénom*" />
                    <input  v-else type="text" :class="{error:errors_register.surname}"  id="name_register" v-model="surname" placeholder="Surname*" />
                    
                 
                    <input  v-if="language=='fr'" type="text"  :class="{error:errors_register.name}"  id="surname_register" v-model="name" placeholder="Nom*" />
                    <input  v-else type="text"  :class="{error:errors_register.name}"  id="surname_register" v-model="name" placeholder="Name*" />
                    
                  </div>

                  <div v-if="errors_register.surname" class="error-message">
                        {{errors_register.surname}}
                  </div>
                  <div v-if="errors_register.name" class="error-message">
                      {{errors_register.name}}
                  </div>
                

                  <div class="form-line">
                    <input  v-if="language=='fr'" type="password" :class="{error:errors_register.password}"  id="password_register" v-model="password_register" placeholder="Mot de passe*" />
                    <input v-else type="password" :class="{error:errors_register.password}"  id="password_register" v-model="password_register" placeholder="Password*" />
                    
                 

                    <input  v-if="language=='fr'" type="password" :class="{error:errors_register.password_repeat}"  id="password_register" v-model="password_repeat" placeholder="Répétez mot de passe*" />
                    <input v-else type="password" :class="{error:errors_register.password_repeat}"  id="password_register" v-model="password_repeat" placeholder="Repeat password*" />
                   
                   
                  </div>
              
                  <span  v-if="language=='fr'" class="infos">Si vous avez déjà un compte, utilisez le mot de passe qui y est associé</span>
                    <span  v-else class="infos">If you already have an account, use the same password</span>

                    <div v-if="errors_register.password" class="error-message">
                       <br /> {{errors_register.password}}
                    </div>
                    
                    

                    <textarea  v-model="message_suscribtion" v-if="language=='fr'" placeholder="Pourquoi souhaitez-vous rejoindre l'accès BÊTA ? Comment avez-vous entendu parler de VISAGES ?"></textarea>
                    <textarea  v-model="message_suscribtion" v-else  placeholder="Why would you like to join BETA access ? How did you hear about VISAGES ? "></textarea>
                    <br />
                  <button  id="register_button" @click="registerUser()" v-if="language=='fr'">Inscription</button>
                  <button  id="register_button" @click="registerUser()" v-else>Register</button>
                  <div id="error-message-register">
                    <br />
                  <center>{{errorRegister}}</center>
                  </div>

                </div>
                
                <!-- <div id="success-message" class="success-popup-login" v-if="success">
                  <h2>Compte créé avec succès</h2>
                  <b>01.</b><br />
                  <b>Télécharger l’application mobile iOS.</b><br />
                  Cliquez-ici pour télécharger l’application mobile IOS Senseye. Pour utiliser l’application et bénéficier d’un sur-mesure, vous devez avoir un iPhone X ou modèle plus récent.
                  <br /><br />
                  <b>02.</b><br />
                  <b>Vos identifiants sur l’application mobile et le site internet sont les mêmes.</b><br />
                  Après avoir scan votre visage, vous pourrez récupérer votre panier directement sur l’application mobile, ou en revenant sur le site internet, à votre convenance.
                  
                  <div class="buttons">
                    <button class="continue" @click="closeLogin()">Continuer sur le site</button>
                    <button class="download">Télécharger</button>
                  </div>
                </div> -->
              </div>
          </template>
        </template>
          <template v-else-if="displayInit">
            <div class="content-init" v-if="step==1">
              <div v-if="accountNoScan" class="content-top">
                <span v-if="language=='fr'">VOTRE COMPTE NE DISPOSE PAS DE SCAN</span>
                <span v-else>NO SCAN FOUND IN YOUR ACCOUNT</span>
              </div>
              
              <div class="content-bottom">
                <div class="question" v-if="language=='fr'">
                  <center><img src="../../../public/assets/images/faceid.svg" width="35" /></center>
                  <br />
                  AVEZ-VOUS ACCÈS À UN IPHONE OU IPAD, ÉQUIPÉ DE FACE ID ?
                </div>
                <div class="question" v-else>
                  <center><img src="../../../public/assets/images/faceid.svg" width="35" /></center>
                  <br />
                  DO YOU HAVE ACCESS TO AN IPHONE OR IPAD EQUIPPED WITH FACE ID?
                </div>
                <div class="text" v-if="language=='fr'">Le sur-mesure nécessite un scan du visage après paiement</div>
                <div class="text" v-else>Custom-Fit requires a facial scan after check-out</div>
                <div class="buttons" v-if="language=='fr'">
                  <div class="button-white" @click="step=3">
                    Non
                  </div>
                  <div class="button-white active" @click="step=5">
                    Oui
                  </div>
                </div>
                <div class="buttons" v-else>
                  <div class="button-white" @click="step=3">
                    No
                  </div>
                  <div class="button-white active" @click="step=5">
                    Yes
                  </div>
                </div>

                <a  v-if="language=='fr'" class="dontknow" @click="displayPopupDevices=true">Je ne sais pas</a>
                <a  v-else class="dontknow" @click="displayPopupDevices=true">I don't know</a>
              </div>
            </div>
            <div class="content-init" v-if="step==3">
              
              <div class="content-bottom">
                <div class="question"  v-if="language=='fr'">
                  <center><img src="../../../public/assets/images/ampoule.svg" width="30" /></center>
                  <br />
                  NOUS SOMMES LÀ POUR TROUVER UNE SOLUTION!
                </div>

                <div class="question"  v-else>
                  <center><img src="../../../public/assets/images/ampoule.svg" width="30" /></center>
                  <br />
                  WE ARE HERE TO FIND A SOLUTION!
                </div>
               
                <div class="text"  v-if="language=='fr'">
                  Pour un sur-mesure, <span class="i">vous pouvez emprunter un appareil compatible à un proche après le paiement</span> pour effectuer le scan facial.
                </div>
                <div class="text"  v-else>
                  For a Custom-Fit, <span class="i">you can borrow a compatible device from a relative after check-out</span> to perform the facial scan.
                </div>
                <div class="or"  v-if="language=='fr'">OU</div>
                <div class="or" v-else>OR</div>
                <div class="text"  v-if="language=='fr'">
                  <span class="i">Choisissez l’option “Taille Unique”</span> et profitez pleinement de nos options de personnalisation pour créer une paire de lunettes unique.
                </div>
                <div class="text" v-else>
                  <span class="i">Choose the "One Size" option</span> and fully enjoy our customization options to create a unique pair of glasses.
                </div>
                <div class="buttons"  v-if="language=='fr'">
                  <div class="button-white" @click="step=1">
                    Retour
                  </div>
                  <div class="button-white active" @click="closeLoginUnique()">
                    Taille unique
                  </div>
                  
                </div>

                <div class="buttons" v-else>
                  <div class="button-white" @click="step=1">
                    Return
                  </div>
                  <div class="button-white active" @click="closeLoginUnique()">
                    One Size
                  </div>
                  
                </div>
              </div>
            </div>

            <div class="content-init" v-if="step==5">
              
              <div class="content-bottom">
                 <div class="question" v-if="language=='fr'">
                  <center><img src="../../../public/assets/images/pouce.svg" width="35" /></center>
                  <br />
                  VOUS ÊTES PRÊT(E) POUR LE SUR-MESURE!
                </div>
                <div class="question" v-else>
                  <center><img src="../../../public/assets/images/pouce.svg" width="35" /></center>
                  <br />
                  YOU ARE READY FOR THE CUSTOM-FIT!
                </div>
                
                <div class="text" v-if="language=='fr'"><span class="i">Finalisez votre commande</span> et vous recevrez des instructions détaillées pour réaliser votre scan.</div>
                <div class="text" v-else><span class="i">Complete your order</span> and you will receive detailed instructions for conducting your scan.</div>
                <div class="buttons" v-if="language=='fr'">
                
                  <div class="button-white big active" @click="closeLoginScan()">
                    Continuer
                  </div>
                </div>
                <div class="buttons" v-else>
                
                <div class="button-white big active" @click="closeLoginScan()">
                  Continue
                </div>
              </div>
              </div>
            </div>


            <div class="content-init" v-if="step==4">
             
              <div class="content-bottom">

                <div class="question" v-if="language=='fr'">
                  <center><img src="../../../public/assets/images/pouce.svg" width="35" /></center>
                  <br />
                  SCAN TROUVÉ AVEC SUCCÈS
                </div>
                <div class="question" v-else>
                  <center><img src="../../../public/assets/images/pouce.svg" width="35" /></center>
                  <br />
                  SCAN FOUND WITH SUCCESS
                </div>

              
    
                <div class="text"  v-if="language=='fr'">Votre compte dispose déjà d’un scan - Sélectionner le scan pour lequel vous souhaitez un ajustement personnalisé</div>
                <div class="text"  v-else>Your account already have a scan - Select the scan you want a custom-fit</div>
                <div class="buttons">
                
                  <div class="button-white active big" @click="closeLogin()"  v-if="language=='fr'">
                    Sélection du scan
                  </div>
                  <div class="button-white active big" @click="closeLogin()"  v-else>
                    Select a scan
                  </div>
                </div>
              </div>
            </div>
            <div class="content-init" v-if="step==2">
              <div class="title">LE SUR-MESURE NÉCESSITE UN SCAN DU VISAGE APRÈS PAIEMENT</div>
              <div class="content-bottom">
                <div class="question">À QUEL APPAREIL IOS AVEZ-VOUS ACCÈS ?<br />(<span class="blue">iPhone</span> ou <span class="blue">iPad</span>)</div>
                <div class="text">Notre technologie est disponible sur IOS, sur tous les appareils équipés de Face ID.</div>
                <div class="selects">
                  <div class="select" :class="{active:selectedIphone!=null}" @click="listiPhonesDisplayed = !listiPhonesDisplayed,listiPadsDisplayed=false">
                    <div class="elem" v-if="selectedIphone==null">iPhones</div>
                    <div class="elem" v-else>{{selectedIphone}}</div>
                    <div class="list" v-if="listiPhonesDisplayed">
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone X')">iPhone X</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone XR')">iPhone XR</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone XS')">iPhone XS</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone XS Max')">iPhone XS Max</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 11')">iPhone 11</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 11 Pro')">iPhone 11 Pro</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 11 Pro Max')">iPhone 11 Pro Max</div>

                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 12')">iPhone 12</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 12 Mini')">iPhone 12 Mini</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 12 Pro')">iPhone 12 Pro</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 12 Pro Max')">iPhone 12 Pro Max</div>

                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 13')">iPhone 13</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 13 Mini')">iPhone 13 Mini</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 13 Pro')">iPhone 13 Pro</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 13 Pro Max')">iPhone 13 Pro Max</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 14')">iPhone 14</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 14 Plus')">iPhone 14 Plus</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 14 Pro')">iPhone 14 Pro</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 14 Pro Max')">iPhone 14 Pro Max</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 15')">iPhone 15</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 15 Plus')">iPhone 15 Plus</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 15 Pro')">iPhone 15 Pro</div>
                      <div class="elem-list" @click.prevent="selectiPhone('iPhone 15 Pro Max')">iPhone 15 Pro Max</div>
                    </div>
                  </div>

                  <div class="select" :class="{active:selectedIpad!=null}" @click="listiPadsDisplayed = !listiPadsDisplayed,listiPhonesDisplayed=false">
                    <div class="elem"></div>
                    <div class="elem" v-if="selectedIpad==null">iPads</div>
                    <div class="elem" v-else>{{selectedIpad}}</div>
                    <div class="list" v-if="listiPadsDisplayed">
                      <div class="elem-list" @click.prevent="selectiPad('iPad Pro 11p')">iPad Pro 11p</div>
                      <div class="elem-list" @click.prevent="selectiPad('iPad Pro 12.9p')">iPad Pro 12.9p</div>
                    
                    </div>
                  </div>
                </div>

                <div v-if="selectedIpad != null" class="text">Vous avez sélectionné l’appareil « {{selectedIpad}} » celui-ci est bien compatible au sur-mesure</div>
                <div v-if="selectedIphone != null" class="text">Vous avez sélectionné l’appareil « {{selectedIphone}} » celui-ci est bien compatible au sur-mesure</div>

                <div class="buttons">
                  <div class="button-white"  @click="step=3">
                    Je ne vois pas mon appareil
                  </div>

                  <div  v-if="selectedIpad==null && selectedIphone==null" class="button-white active disabled" >
                    Continuer la personnalisation
                  </div>
                  <div  v-else class="button-white active" @click="closeLoginScan()">
                    Continuer la personnalisation
                  </div>
                </div>
              </div>
            </div>
          </template>
          

        </div>
       
				
			</div>
		</div>
</template>


<script>
import {APIRequest} from '@simb2s/senseye-sdk';
import { useAuthStore } from '@/store/authStore.js';
import jQuery  from 'jquery';
import emitter from 'tiny-emitter/instance'
import { ContentLoader } from 'vue-content-loader';

export default {
  components: { ContentLoader },
  name: 'PopupLogin',
  data() {
    return {
      accountNoScan:false,
      message_suscribtion:'',
      loadingPopup:true,
      useAuth: useAuthStore(),
      displayBetaAccessPopup:false,
      pending_message:false,
      displayNoneStatus:false,
      displayPopupDevices:false,
    selectedIpad:null,
    selectedIphone:null,
     user:null,
     listiPhonesDisplayed:false,
     listiPadsDisplayed:false,
     username:'',
     password:'',
     password_register:'',
     password_repeat:'',
     name:'',
     surname:'',
     userScans:null,
     email:'',
     step:1,
     phone:'',
     errorLogin:'',
      errorRegister:'',
      success:false,
      email_forgot:'',
      errorReset:'',
      displayInit:true,
      displayLoginForm:false,
      displayRegisterForm:false,
      errors_login:{},
      errors_register:{}
    }
  },
  async mounted(){

 
    if(this.liking==true || this.review==true  || this.saving==true)
    {
      this.displayLoginForm=true
      
    }
    if(this.betaAccess==true)
    {
      await this.getUserScans()
      if(this.userScans && this.userScans.length)
      {
         
      }
      else 
      { 
      
        this.accountNoScan=true
      }

    }
    else
    {
      if(localStorage.id_user_senseye_auth)
      {
        await this.checkBetaStatus(this.useAuth.user.email)
   
      }
      else
      {

        this.displayLoginForm=true
        
      }
      
    }
    
  


    this.loadingPopup=false

  },
  props:['review', 'saving', 'customing','liking','likingId','language','betaAccess'],
  methods:{
    async checkBetaStatus(email)
    {
      this.loadingPopup=true
      let status = await APIRequest.check_status_beta(email)

      if(status.status=="pending" || status.status==false )
      {
      
          this.displayLoginForm=true
          this.displayRegisterForm=false
          this.pending_message=true
        
      }
      else if(status.status=="ok"  || status.status==true)
      {
        
         
          this.$emit('changeBetaAccessEv', true)


          this.user_name=email

          this.displayBetaAccessPopup=true
        
          if(this.saving==false && this.customing==false)
          {
            
            if(this.liking==true) {
              this.closeLogin()
              
              emitter.emit('reload-glasses-like', this.likingId)
            }
            else {
              //document.location.reload()
              await this.getUserScans()
              if(this.userScans && this.userScans.length)
              {
                this.step=4
              }
              else 
              { 
                this.step=1
                this.accountNoScan=true
              }
              
              this.displayRegisterForm=false
              this.displayLoginForm=false
            }
            
          }
          else
          {


          
            if(this.customing==true)
            {
              
              
            
              await this.getUserScans()
              if(this.userScans && this.userScans.length)
              {
                this.step=4
              }
              else 
              { 
                this.step=1
                this.accountNoScan=true
              }
              
              this.displayRegisterForm=false
              this.displayLoginForm=false
              //this.closeLogin()
            }
            else if(this.saving==true)
            {
              this.closeLogin()
              
              this.$emit('reloadUserContentSavingEv')
              
            }
            else if(this.liking==true) {
              this.closeLogin()
            
              emitter.emit('reload-glasses-like', this.likingId)
            }
            
          }
        
      }
      else
      {
          this.displayRegisterForm=false
          this.displayLoginForm=true
          this.displayNoneStatus=true
          //this.error_login="Vous n'avez pas fait de demande avec cette adresse mail."
      }

      this.loadingPopup=false
    },
    async userRegister (user) {
     
        if(user!=null)
        {

          try {

                               
        //await emitter.emit('register',this.email,this.password_register,this.name,this.phone,this.surname,this.email) 
            let data={
                email: this.email,
                password:this.password_register,
                name: this.name,
                surname: this.surname,
                message: this.message_suscribtion,
                
            }
            let subscribtion = await APIRequest.create('beta-testers',data)

           

            } catch (error) {

           
            // this.error_subscribe="Une demande a déjà été effectuée avec cette adresse mail."

            }
          this.loadingPopup=false
          this.user=user
          this.errorRegister='';
          
          await this.loginUser(this.email,this.password_register)

          if(this.saving==false && this.customing==false)
          {
            if(this.liking==true) {
              
              emitter.emit('reload-glasses-like', this.likingId)
            }
          }
          else {
            if(this.customing==true) {
              this.$emit('reloadUserContentEv')
              

              // this.step=1
              
              
              // this.displayRegisterForm=false
              // this.displayLoginForm=false
              //this.closeLogin()
            }
            else if(this.liking==true) {
              
              emitter.emit('reload-glasses-like', this.likingId)
            }
            this.success=true
          }
        }
        else
        {
          await this.loginUser(this.email,this.password_register)
          this.loadingPopup=false
          // this.errors_register={}
          // if(this.language=='fr')
          //   this.errors_register.email="Votre e-mail contient une erreur où un compte avec cette adresse mail existe déjà."
          // else
          //   this.errors_register.email="Your email contains an error or an account already exists with this email."
          
        }

    },
    async userLogin (user) {
    
      if(user!==null)
      {
        if(this.displayRegisterForm==true)
        {
          // C'est une inscription avec un compte existant
          try {

                                      
        //await emitter.emit('register',this.email,this.password_register,this.name,this.phone,this.surname,this.email) 
            let data={
                email: this.email,
                password:this.password_register,
                name: this.name,
                surname: this.surname,
                message: this.message_suscribtion,
                
            }
            let subscribtion = await APIRequest.create('beta-testers',data)

          

            } catch (error) {

          
            // this.error_subscribe="Une demande a déjà été effectuée avec cette adresse mail."

            }
          this.loadingPopup=false
          this.user=user
          this.errorRegister='';
        }
        await this.checkBetaStatus(user.email)
        this.loadingPopup=false
      }
      else {
        if(this.displayRegisterForm==true)
        {
            this.errors_register={}
            if(this.language=='fr')
              this.errors_register.email="Un compte existe déjà avec cet email. Veuillez utiliser le mot de passe associé."
            else
              this.errors_register.email="An account already exists with this mail. Please use the same password."
        }
        else 
        {
          this.displayLoginForm=true
          this.displayRegisterForm=false
          if(this.language=='fr')
            this.errorLogin='Vos identifiants sont incorrects.';
          else 
            this.errorLogin='Your credentials are not corrects.';
        }
        this.loadingPopup=false
      }
    },
    selectiPhone(iphone){
      this.selectedIphone=iphone
      this.selectedIpad=null
    },
    selectiPad(iphone){
      this.selectedIphone=null
      this.selectedIpad=iphone
    },
    closeLoginScan(){

      this.$emit('selectDevicePopupEv',this.customing)
      
      this.closeLogin()
    },
    closeLoginUnique(){
      this.$emit('selectUniqueSizePopup')
      
      this.closeLogin()
    },
    async postLogin()
    {
      this.loadingPopup=true
      this.errors_login={}
      if(this.username == "") {
        this.errors_login.username="Ce champs est obligatoire."
      }
      if(this.password == "") {
        this.errors_login.password="Ce champs est obligatoire."
      }
      if(Object.keys(this.errors_login).length==0) {
          await this.loginUser(this.username,this.password)
      }
      else {
        this.loadingPopup=false
      }

    },
      forgotPassword()
    {

      this.$emit('displayForgotPasswordEv')
      

    },
      closeLogin()
    {
      this.$emit('closePopupLoginEv')
    
    },
    async getUserScans()
    {
      let userScans=await APIRequest.list('scans',localStorage.id_user_senseye_auth,null,2)
      this.userScans=userScans[0];
    },
    closeModal($event)
    {

      if($event)
      {

     
     
        if (jQuery($event.target).parents('.content').length) {

        }
        else
        {
          if(jQuery($event.target).attributes.id=="login_button") {

          }
          else {
            if(jQuery($event.target).attributes.id=="register_button") {

          }
          else {
            
            this.$emit('closePopupLoginEv')
          }
            
          }
          
        }
      }
      else
      {
        this.$emit('closePopupLoginEv')
      }
    },
    async registerUser(){
      this.loadingPopup=true
      this.errors_register={}
      let errormandat
      let errorpass
      if(this.language=='fr'){
        errormandat="Ce champs est obligatoire."
        errorpass="Les deux mots de passe ne sont pas identiques."
      }
      else
      {
        errormandat="This field is mandatory."
        errorpass="The 2 passwords are not identical."
      }
      
      if(this.name == "") {
        this.errors_register.name=errormandat
      }
      if(this.password_register == "") {
        this.errors_register.password=errormandat
      }
      if(this.password_repeat == "") {
        this.errors_register.password_repeat=errormandat
      }
      if(this.password_register != this.password_repeat){
        this.errors_register.password_repeat=errorpass
        this.errors_register.password=errorpass
      }
      if(this.surname == "") {
        this.errors_register.surname=errormandat
      }
      if(this.email == "") {
        this.errors_register.email=errormandat
      }
      if(Object.keys(this.errors_register).length==0)
      {
        await emitter.emit('register',this.email,this.password_register,this.name,this.phone,this.surname,this.email)
      }
      else {
        this.loadingPopup=false
      }
    },
    parseJwt (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    },
    async loginUser(username,password)
    {
        await emitter.emit('login',username,password)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.right center img
{
 
  filter: brightness(0);
}
.right .link
{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-weight: 600;
  display: block;
  cursor: pointer;
  color:#000;
  margin-bottom:50px;
}
.right .text-bold
{
  font-size:16px;
  text-align: center;
  margin-top:30px;
  font-weight: 600;
  text-transform: none;
}
.right .text .bold 
{
  font-weight: 600;
  font-size:16px;
}
.right .text
{
  font-size:13px;
  text-align: center;
  margin-top:30px;
  margin-bottom:30px;
}
.popup-login #cross
{
  z-index: 3;
}
@media screen and (max-width:760px) {
  #popup-login .content .right .footer
  {
    top:10px !important;
  }
  #popup-login .content .right .register-form
  {
    padding-top:50px !important;
  }

  #popup-login .content .right textarea
  {
    max-height:80px;
  }
  #popup-login .content .right .register-form input,#popup-login .content .right .login-form input,#popup-login .content .right textarea
  {
    font-size:11px !important;
    margin-bottom: 10px !important;
  }

  body #popup-login .content .right
  {
    min-height:447px !important;
  }

  #popup-login .content .left a .question-img
{
position: relative;
top:-3px;
  width:16px !important;
  margin-left:5px !important;
} 
  /* #popup-login .content .left .gradient
{
  
  
  background-image: url('../../../public/assets/images/bg-left-mob.jpg');

} */

  #popup-login .content .left .text-big
{
  font-size:15px !important;
  font-weight: 500;
  width:70%;
  line-height: 1;
}
#popup-login .content .left a
{
  font-size:10px !important;
  font-weight: 500;
  line-height: 1;

  margin-top:-20px;
}
  .content-init .content-bottom .buttons .button-white
  {
    padding: 10px 10px !important;
    font-size: 11px !important;
  }
  .content-init .content-bottom .text-bold
  {
    font-size:14px !important;
  }
  .content-init .content-bottom .buttons
  {
    margin-bottom: 20px !important;
  }
}
@media screen and (min-width:760px) and (max-width:1024px) {
  body #popup-login .content .right
  {
    min-height:590px !important;
  }
}
@media screen and (max-width:1024px) {
 
 
  body #popup-login .content .left a
  {
    font-size: 14px;
  }
  body #popup-login .content .left .text-big
  {
    font-size:27px;
  }
  #popup-login .content .left {
 height:auto !important;
  }
  #popup-login .content .left .p {
    font-size:14px !important;
  
  }
  #popup-login .content .left .gradient::before {
  
  border-radius: 10px 10px 0px 0px !important;
}
  #popup-login .content .left .gradient {
  
    border-radius: 10px 10px 0px 0px !important;
  }
  #popup-login .content .right {
    width:100% !important;
    left:0 !important;
    padding:20px;
  }
  #popup-login .content {
    padding:0 !important;
  }
  #popup-login .content {
    display: flex;
    flex-direction: column;
  }
  .content-init .content-bottom .question {
    font-size: 21px !important;
  }
  .content-popup .list-item .num
  {
    margin-left:0 !important;
    margin-right:10px !important;
  }
  .content-popup .list-item
  {
    font-size: 12px !important;
  }
  .content-popup
   {
    width:90vw;
    max-width:90vw !important;
   }
  .content-init .content-bottom .text,.dontknow
  {
    font-size: 14px !important;
  }
  .content-init .title  {
    font-size: 12px !important;
    margin-top: 20px !important;
  }
}
.infos
{
  font-size:9px;
  display:block;
  margin-top:-10px;
  margin-bottom:10px;
}
textarea{
  font-size: 13px;
    position: relative;
    border: 0.5px solid rgba(85, 85, 85, 0.5);
    border-radius: 3px;
    color: #717171;
}
.form-line input 
{
  width:49%;
}
.form-line
{
  justify-content: space-between;
  display: flex;
}
.beta-left
{
  position:absolute;
  right:20px;
  bottom:20px;
  width:25%;
}
.selects .select.active {

  background: transparent linear-gradient(94deg, #C0C0C0 0%, #0000FFE6 100%) 0% 0% no-repeat padding-box;
  color:#fff;
}
.dontknow
{
  display:inline-block;
  clear: both;
  font-size:15px;
  cursor: pointer;
  color:#000;
  text-decoration: none;
  border-bottom:1px solid black;
  margin-bottom: 50px;
}
.selects .select::after {
  width:18px;
  height:18px;
  top:8px;
}
.selects {
  display: flex;
  margin-top:20px;
  justify-content: space-between;
}
.selects .select .list .elem-list:hover {
  background: rgba(0, 0, 0, 0.3) 0% 0% no-repeat padding-box;
}
.selects .select .list .elem-list
{
  transition:all 0.3s;
  background: rgba(0, 0, 0, 0.1) 0% 0% no-repeat padding-box;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  padding:9px;
  color:#000;
  border-top:1px solid #ccc;
}
.selects .select .list{
  position:absolute;
  max-height: 300px;
  overflow: auto;
  left:0;
  z-index:5000;
  top:34px;
  width:100%;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  text-align: center;
 
}
.selects .select {
  position:relative;
  width:45%;
  z-index:500;
  background: rgba(0, 0, 0, 0.1) 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
border-radius: 8px;
font-size:13px;
padding:9px;
text-align: center;
opacity: 1;
backdrop-filter: blur(50px);
-webkit-backdrop-filter: blur(50px);
}
.content-init .title {
  font-size:18px;
  margin-top:100px;
}
.content-init 
{
  
  text-align: center;
  
}
.content-init .content-bottom .buttons .button-white.disabled {
  opacity:0.2;
  background:#000;
  color:#fff;
}
.content-init .content-bottom .buttons .button-white.gradient:hover {
  background:#fff;
  color:#000;
}
.content-init .content-bottom .buttons .button-white.active,.right .text .button-white.active {
  background:#000;
  color:#fff;
}
.content-init .content-bottom .buttons .button-white:hover,.right .text .button-white:hover {
  background:#000;
  color:#fff;
}
.content-init .content-bottom .buttons .button-white.gradient
{
  border:0;
  color:#fff;
  background: transparent linear-gradient(90deg, rgba(192, 192, 192, 1) 0%, rgba(0, 0, 255, 0.9) 100%) 0% 0% no-repeat padding-box;
}
.content-init .content-bottom .buttons .button-white
{
width:49%;
padding:13px 20px;
border:1px solid black;
transition: all 0.3s;
cursor: pointer;
font-size:13px;
font-weight: 600;
}

.right .text .button-white
{
width:100%;
padding:13px 20px;
border:1px solid black;
transition: all 0.3s;
cursor: pointer;
font-size:15px;
border-radius: 10px;
font-weight: 600;
}
.content-init .content-bottom .buttons {
  display:flex;
  margin-top:30px;
  margin-bottom:20px;
  align-items: center;
  justify-content: space-between;
}
.question.u
{
  display:inline-block;
  clear: both;
  margin-left:auto;
  margin-right:auto;
  border-bottom: 1px solid #000;
}
.content-init .content-bottom .text-bold
{
  margin-top:20px;
  font-weight: 600;
  font-size:18px;
}
.content-init .content-bottom .buttons .button-white.big {
  width:100%;
}

.content-init .content-bottom .text .i
{
  font-weight: 600;
  font-style:italic;
}
.content-init .content-bottom .text
{
  margin-top:20px;
  font-size:18px;
}
.content-init .content-bottom .question .blue
{
  color:#7979EA;
}
.content-init .content-bottom .question
{
  font-size:35px;
  font-weight: 500;
  line-height: 1;
}
.content-init .content-bottom
{
  
  padding-top:30px;
  
}

.btn-white:hover {
  color:#fff;
  background-color: transparent;
}
.btn-white {
  transition: all 0.3s;
  cursor: pointer;
  background-color:#fff;
  white-space: nowrap;
  color:#000;
  border:1px solid #fff;
  text-decoration: none;
  font-size:13px;
  font-weight: 600;
  line-height: 0.7;
  padding:10px 20px 10px 20px;
}
.popup-login
        {
            animation: fadeanimation 0.4s ease;
        }
        
input.error {
  border:1px solid red !important;
}
.register-form input,.login-form input
{
  margin-bottom:20px;
}
#popup-login .content .right .register-form input::placeholder,#popup-login .content .right .login-form input::placeholder
{
 
  color:#717171;
}
#popup-login .content .right .login-form .input-block
{
  position:relative;
}
#popup-login .content .right .register-form
{
  padding-top:70px;
}
#popup-login .content .right .register-form input,#popup-login .content .right .login-form input
{
  font-size:13px;
  position:relative;
  border: 0.5px solid rgba(85, 85, 85, 0.5);
  border-radius: 3px;
  color:#717171;
}
.popup .bg
{
  position:absolute;
  left:0;
  top:0;
  width:100vw;
  height:100vh;
  background:rgba(0,0,0,0.6);
  z-index: 9999999999999999999999;
  content:' ';
}
.content-popup .text.little
{
  font-size:15px;
  text-align: center;
}
.content-popup .list-item .num
{
  width:25px;
 
  padding-top:3px;
  height:25px;
  text-align: center;
  font-size:15px;
  border:1px solid black;
  display:inline-block;
  margin-left:20px;
  border-radius: 50%;
  margin-right:20px;
}
.content-popup .list-item
{
  font-size:15px;
  font-weight: 300;
  line-height:1;
  padding:10px 20px;
  border-top:0.5px solid #c2c2c2;
}
.content-popup .list-items
{
  display:flex;
  flex-direction: column;
  margin-bottom:20px;
  background:#D1D1D6;
}
.content-popup .list-title
{
  line-height: 1;
  margin-top:10px;
  margin-bottom:10px;
  font-size:24px;
  padding-left:50px;
  padding-right:50px;
  font-weight: 500;
  text-align: center;
}
.content-popup .link
{
  color:#000;
}
.content-popup .text 
{
  font-size:18px;
  text-align: center;
  padding-left:50px;
  padding-right:50px;
}
.content-popup
{
  position:fixed;
  left:50%;
  top:50%;
  z-index:99999999999999999999999;
  background:#fff;
  transform: translate(-50%,-50%);
  min-width: 280px;
  max-width:700px;
  padding-top:50px;
  padding-bottom:50px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
border-radius: 10px;
}
.popup
{
  position:fixed;
  z-index:99;
  left:0;
  top:0;
}
.content-top
{
  font-size:18px;
  font-weight: 600;
  text-align: center;
  padding-bottom:30px;
  padding-top:30px;
  border-bottom:1px solid rgba(0,0,0,0.1);
}
#cross
{
  position: absolute;
  
    cursor: pointer;
    border-radius: 50%;
    padding: 7px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
}
#popup-login .content .right .footer
{
  position:absolute;
  width: fit-content;
  left:50%;
  top:20px;
  transform:translateX(-50%);
  display:inline-block;
  margin-top:20px;
  margin-bottom:20px;
  border-bottom:1px solid #c0c0c0;
}
#popup-login .content .right .choice-login-form.active
{
  border-bottom:2px solid black;
  font-weight: 600;
}
#popup-login .content .right button:hover
{
  background-color:#fff;
}
#login_button,#register_button {
  border-radius: 10px !important;
  font-size:13px !important;
  font-weight: 600 !important;
}
#popup-login .content .right button
{
  width:100%;
  font-size:15px;
  background-color:#000;
}
#popup-login .content .right a.forgot
{
font-size:8px;
color:#000;
position:absolute;
right:10px;
top:50%;
transform:translateY(-50%);
text-decoration: none;
    border-bottom: 1px solid black;
cursor: pointer;
}
#popup-login .content .right .choice-login-form
{
  font-size:14px;
  text-transform: uppercase;
  cursor:pointer;
  color:#000;
  text-decoration: none;
  padding-left:5px;
  padding-right:5px;
}
#popup-login .content
{
 
box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

#popup-login .content .left a .question-img
{
  filter: brightness(0) invert(1);
  width:24px;
  margin-left:10px;
} 
#popup-login .content .left .a 
{
  display:block;
  height:fit-content;
  border-bottom:1px solid #fff;
}
#popup-login .content .left a 
{
  font-size:15px;
  font-weight: 500;
  cursor: pointer;
 
  text-decoration: none;
  color:#fff;
  display:flex;
  width:fit-content;
}
#popup-login .content .left .text-big
{
  font-size:30px;
  font-weight: 500;
  line-height: 1;
}
#popup-login .content .left .p
{
  font-size:15px;
  padding-left:5%;
  width:100%;
  z-index: 5;
  text-align: left;
  padding-right:5%;
  position:relative;
  color:#fff;
  font-weight: 600;
}
#popup-login .content .left .p .underline
{
  border-bottom:1px solid black;
}
#popup-login .content .left .gradient::before
{
  content:' ';
  position: absolute;
  width:100%;
  height:100%;
  border-radius: 10px 0px 0px 10px;
  background:#000;
  opacity: 0.5;
}
#popup-login .content .left .gradient
{
  position:absolute;
  left:0;
 
background: transparent linear-gradient(45deg, rgba(0, 0, 0, 1) -40%, rgba(0, 0, 224, 1) 100%) 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity:1;
  width:100%;
  background-position: right bottom;
  background-size:auto 105%;
  background-repeat: no-repeat;
  height:100%;
  top:0;
  border-radius: 10px 0px 0px 10px;
  background-color:#000;
}
#popup-login .content .left
{
  
	position:absolute;
	left:0;
  display: flex;
  background-size: cover;
  background-position: center center;
  align-items: flex-start;
  justify-content: center;
	top:0;
  padding-bottom:20px;
  padding-top:20px;
	height:100%;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
border-radius: 10px 0px 0px 10px;

	vertical-align: top;
	width:48%;
	float:left;
}
body #popup-login .content .right.full
{
  width:100%;
  left:0;
  padding-left:5%;
  padding-right:5%;
}
body #popup-login .content .right
{
  width:50%;
  justify-content: center;
  min-height: 500px;
  flex-direction: column;
  left:50%;
  display:flex;
  padding-left:5%;
  padding-right:5%;
}
.error-message {
    position:relative;
    text-align: left;
    top:-15px;
    left:0;
    color:red;
    font-size:11px;
}
.input-line {
    position:relative;
    
}
</style>
