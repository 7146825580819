<template>
        <div class="carousel-cell product">
            <PlaceholderImage />
            <a :href="selectedFilter=='solar' ? '/collection/37/type/solar':'/collection/37'"></a>
            <div class="product-image-hover">
                <img src="https://api.sens-eye.fr/render_parts/model37_shadow_glass_4.png" class="" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model37_hinges_color30_4.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model37_temples_texturestyle4_color29_4.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/api/photo-single-test?photo=model37_frame_textureposition0_texturestyle4_color4_4.png&hue=160&saturation=20&brightness=90" class="render-layer2" style="opacity: 1;">
                <img :src="selectedFilter=='solar' ? 'https://api.sens-eye.fr/render_parts/model37_lenses_lensescolor3_4.png':'https://api.sens-eye.fr/render_parts/model37_lenses_lensescolor0_4.png'" class="render-layer2" :style="selectedFilter=='solar' ? 'opacity: 0.7;':'opacity: 1;'">
            </div>
            <div class="photo-single product-image collec">
                <img src="https://api.sens-eye.fr/render_parts/model37_shadow_glass_2.png" class="" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model37_hinges_color30_2.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model37_temples_texturestyle4_color29_2.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/api/photo-single-test?photo=model37_frame_textureposition0_texturestyle4_color4_2.png&hue=160&saturation=20&brightness=90" class="render-layer2" style="opacity: 1;">
                <img :src="selectedFilter=='solar' ? 'https://api.sens-eye.fr/render_parts/model37_lenses_lensescolor3_2.png':'https://api.sens-eye.fr/render_parts/model37_lenses_lensescolor0_2.png'" class="render-layer2" :style="selectedFilter=='solar' ? 'opacity: 0.7;':'opacity: 1;'">
            </div>
            <div class="personnalisation-actions beta">
                |&nbsp;&nbsp;&nbsp; <img src="../../../../public/assets/images/beta.png" width="55" height="auto">
            </div>
            <div class="colors-count">6 <span v-if="language=='fr'">COULEURS</span><span v-else>COLORS</span></div>
            <img src="../../../../public/assets/images/edit1.svg" class="like" width="18">
            <div class="bottom">
                <div class="left">
                    <span class="product-title">Chaï metal <span class="subtitle"><br>Visages</span></span>
                    <span class="product-price"><span class="from" v-if="language=='fr'">À partir de</span><span class="from" v-else>From</span> <span>160</span>€</span>
                </div>
                <div class="colors">
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/61c60209387cb.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/671f83e0e5412.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/671bd1272f248.jpeg');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/6639053ae9973.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/663905572e535.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/663905a26228d.png');"></div>
                        </a>
                    </div>
                    <div class="plus"><!--v-if--></div>
                </div>
            </div>
        </div>

        <div class="carousel-cell product">
            <PlaceholderImage />
            <a :href="selectedFilter=='solar' ? '/collection/22/type/solar':'/collection/22'"></a>
            <div class="product-image-hover">
                <img src="https://api.sens-eye.fr/render_parts/model22_shadow_glass_4.png" class="" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model22_hinges_color30_4.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/api/photo-single-test?photo=model22_temples_texturestyle4_color4_4.png&hue=160&saturation=20&brightness=90" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/api/photo-single-test?photo=model22_frame_textureposition0_texturestyle4_color4_4.png&hue=160&saturation=20&brightness=90" class="render-layer2" style="opacity: 1;">
                <img :src="selectedFilter=='solar' ? 'https://api.sens-eye.fr/render_parts/model22_lenses_lensescolor3_4.png':'https://api.sens-eye.fr/render_parts/model22_lenses_lensescolor0_4.png'" class="render-layer2" :style="selectedFilter=='solar' ? 'opacity: 0.7;':'opacity: 1;'">
            </div>
            <div class="photo-single product-image collec">
                <img src="https://api.sens-eye.fr/render_parts/model22_shadow_glass_2.png" class="" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model22_hinges_color30_2.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/api/photo-single-test?photo=model22_temples_texturestyle4_color4_2.png&hue=160&saturation=20&brightness=90" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/api/photo-single-test?photo=model22_frame_textureposition0_texturestyle4_color4_2.png&hue=160&saturation=20&brightness=90" class="render-layer2" style="opacity: 1;">
                <img :src="selectedFilter=='solar' ? 'https://api.sens-eye.fr/render_parts/model22_lenses_lensescolor3_2.png':'https://api.sens-eye.fr/render_parts/model22_lenses_lensescolor0_2.png'" class="render-layer2" :style="selectedFilter=='solar' ? 'opacity: 0.7;':'opacity: 1;'">
            </div>
            <div class="personnalisation-actions beta">
                |&nbsp;&nbsp;&nbsp; <img src="../../../../public/assets/images/beta.png" width="55" height="auto">
            </div>
            <div class="colors-count">6 <span v-if="language=='fr'">COULEURS</span><span v-else>COLORS</span></div>
            <img src="../../../../public/assets/images/edit1.svg" class="like" width="18">
            <div class="bottom">
                <div class="left">
                    <span class="product-title">Chaï <span class="subtitle"><br>Visages</span></span>
                    <span class="product-price"><span class="from" v-if="language=='fr'">À partir de</span><span class="from" v-else>From</span> <span>160</span>€</span>
                </div>
                <div class="colors">
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/61c60209387cb.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/671f83e0e5412.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/671bd1272f248.jpeg');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/6639053ae9973.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/663905572e535.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/663905a26228d.png');"></div>
                        </a>
                    </div>
                    <div class="plus"><!--v-if--></div>
                </div>
            </div>
        </div>
        <div class="carousel-cell product">
            <PlaceholderImage />
            <a :href="selectedFilter=='solar' ? '/collection/33/type/solar':'/collection/33'"></a>
            <div class="product-image-hover">
                <img src="https://api.sens-eye.fr/render_parts/model33_shadow_glasses_4.png" class="" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model33_endpiece_color24_4.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model33_frame_textureposition0_texturestyle4_color5_4.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model33_temples_texturestyle4_color20_4.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model33_hinges_temples_color25_4.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model33_lenses_lensescolor0_4.png" class="render-layer2" style="opacity: 1;">
            </div>
            <div class="photo-single product-image collec">
                <img src="https://api.sens-eye.fr/render_parts/model33_shadow_glasses_2.png" class="" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model33_endpiece_color24_2.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model33_frame_textureposition0_texturestyle4_color5_2.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model33_temples_texturestyle4_color20_2.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model33_hinges_temples_color25_2.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model33_lenses_lensescolor0_2.png" class="render-layer2" style="opacity: 1;">
            </div>
            <div class="personnalisation-actions beta">
                |&nbsp;&nbsp;&nbsp; <img src="../../../../public/assets/images/beta.png" width="55" height="auto">
            </div>
            <div class="colors-count">5 <span v-if="language=='fr'">COULEURS</span><span v-else>COLORS</span></div>
            <img src="../../../../public/assets/images/edit1.svg" class="like" width="18">
            <div class="bottom">
                <div class="left">
                    <span class="product-title">Human Expansion <span class="subtitle"><br>Sébastien Brusset</span></span>
                    <span class="product-price"><span class="from" v-if="language=='fr'">À partir de</span><span class="from" v-else>From</span> <span>590</span>€</span>
                </div>
                <div class="colors">
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/6639053ae9973.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/663905572e535.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/663905a26228d.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/65e5ec02134ec.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/663905dcab6c9.png');"></div>
                        </a>
                    </div>
                    <div class="plus"></div>
                </div>
            </div>
        </div>
        <div v-if="selectedFilter=='solar'" class="carousel-cell product">
            <PlaceholderImage />
            <a :href="selectedFilter=='solar' ? '/collection/29/type/solar':'/collection/29'"></a>
            <div class="product-image-hover">
                <img src="https://api.sens-eye.fr/render_parts/model29_shadow_glass_4.png" class="" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model29_temples_texturestyle4_color2_4.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model29_hinges_temples_color1_4.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model29_frame_textureposition0_texturestyle4_color2_4.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model29_lenses_lensescolor3_4.png" class="render-layer2" style="opacity: 0.7;">
            </div>
            <div class="photo-single product-image collec">
                <img src="https://api.sens-eye.fr/render_parts/model29_shadow_glass_2.png" class="" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model29_temples_texturestyle4_color2_2.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model29_hinges_temples_color1_2.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model29_frame_textureposition0_texturestyle4_color2_2.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model29_lenses_lensescolor3_2.png" class="render-layer2" style="opacity: 0.7;">
            </div>
            <div class="personnalisation-actions beta">
                |&nbsp;&nbsp;&nbsp; <img src="../../../../public/assets/images/beta.png" width="55" height="auto">
            </div>
            <div class="colors-count">1 <span v-if="language=='fr'">COULEURS</span><span v-else>COLORS</span></div>
            <img src="../../../../public/assets/images/edit1.svg" class="like" width="18">
            <div class="bottom">
                <div class="left">
                    <span class="product-title">Tête en l'air <span class="subtitle"><br>Rêveur Lucide</span></span>
                    <span class="product-price"><span class="from" v-if="language=='fr'">À partir de</span><span class="from" v-else>From</span> <span>280</span>€</span>
                </div>
                <div class="colors">
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/61c60209387cb.png');"></div>
                        </a>
                    </div>
                    <div class="plus"></div>
                </div>
            </div>
        </div>
        <div class="carousel-cell product" v-if="selectedFilter=='solar'">
            <PlaceholderImage />
            <a :href="selectedFilter=='solar' ? '/collection/30/type/solar':'/collection/30'"></a>
            <div class="product-image-hover">
                <img src="https://api.sens-eye.fr/render_parts/model30_shadow_glass_4.png" class="" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model30_temples_texturestyle4_color5_4.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model30_frame_textureposition0_texturestyle4_color5_4.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model30_lenses_lensescolor3_4.png" class="render-layer2" style="opacity: 0.7;">
                <img src="https://api.sens-eye.fr/render_parts/model30_hinges_temples_color1_4.png" class="render-layer2" style="opacity: 1;">
            </div>
            <div class="photo-single product-image collec">
                <img src="https://api.sens-eye.fr/render_parts/model30_shadow_glass_2.png" class="" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model30_temples_texturestyle4_color5_2.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model30_frame_textureposition0_texturestyle4_color5_2.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model30_lenses_lensescolor3_2.png" class="render-layer2" style="opacity: 0.7;">
                <img src="https://api.sens-eye.fr/render_parts/model30_hinges_temples_color1_2.png" class="render-layer2" style="opacity: 1;">
            </div>
            <div class="personnalisation-actions beta">
                |&nbsp;&nbsp;&nbsp; <img src="../../../../public/assets/images/beta.png" width="55" height="auto">
            </div>
            <div class="colors-count">5 <span v-if="language=='fr'">COULEURS</span><span v-else>COLORS</span></div>
            <img src="../../../../public/assets/images/edit1.svg" class="like" width="18">
            <div class="bottom">
                <div class="left">
                    <span class="product-title">CURVE BALL <span class="subtitle"><br>Rêveur Lucide</span></span>
                    <span class="product-price"><span class="from" v-if="language=='fr'">À partir de</span><span class="from" v-else>From</span> <span>280</span>€</span>
                </div>
                <div class="colors">
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/6639053ae9973.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/663905572e535.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/663905a26228d.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/65e5ec02134ec.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/663905dcab6c9.png');"></div>
                        </a>
                    </div>
                    <div class="plus"></div>
                </div>
            </div>
        </div>
        <div class="carousel-cell product">
            <PlaceholderImage />
            <a :href="selectedFilter=='solar' ? '/collection/28/type/solar':'/collection/28'"></a>
            <div class="product-image-hover">
                <img src="https://api.sens-eye.fr/render_parts/model28_shadow_glass_4.png" class="" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model28_endpiece_color24_4.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model28_temples_texturestyle4_color20_4.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/api/photo-single-test?photo=model28_frame_textureposition0_texturestyle4_color4_4.png&hue=160&saturation=20&brightness=90" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model28_hinges_temples_color25_4.png" class="render-layer2" style="opacity: 1;">
                <img :src="selectedFilter=='solar' ? 'https://api.sens-eye.fr/render_parts/model28_lenses_lensescolor3_4.png':'https://api.sens-eye.fr/render_parts/model28_lenses_lensescolor0_4.png'" class="render-layer2" :style="selectedFilter=='solar' ? 'opacity: 0.7;':'opacity: 1;'">
            </div>
            <div class="photo-single product-image collec">
                <img src="https://api.sens-eye.fr/render_parts/model28_shadow_glass_2.png" class="" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model28_endpiece_color24_2.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model28_temples_texturestyle4_color20_2.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/api/photo-single-test?photo=model28_frame_textureposition0_texturestyle4_color4_2.png&hue=160&saturation=20&brightness=90" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model28_hinges_temples_color25_2.png" class="render-layer2" style="opacity: 1;">
                <img :src="selectedFilter=='solar' ? 'https://api.sens-eye.fr/render_parts/model28_lenses_lensescolor3_2.png':'https://api.sens-eye.fr/render_parts/model28_lenses_lensescolor0_2.png'" class="render-layer2" :style="selectedFilter=='solar' ? 'opacity: 0.7;':'opacity: 1;'">
            </div>
            <div class="personnalisation-actions beta">
                |&nbsp;&nbsp;&nbsp; <img src="../../../../public/assets/images/beta.png" width="55" height="auto">
            </div>
            <div class="colors-count">5 <span v-if="language=='fr'">COULEURS</span><span v-else>COLORS</span></div>
            <img src="../../../../public/assets/images/edit1.svg" class="like" width="18">
            <div class="bottom">
                <div class="left">
                    <span class="product-title">Louis S <span class="subtitle"><br>Meyrowitz</span></span>
                    <span class="product-price"><span class="from" v-if="language=='fr'">À partir de</span><span class="from" v-else>From</span> <span>590</span>€</span>
                </div>
                <div class="colors">
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/6639053ae9973.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/663905572e535.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/663905a26228d.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/65e5ec02134ec.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/663905dcab6c9.png');"></div>
                        </a>
                    </div>
                    <div class="plus"></div>
                </div>
            </div>
        </div>
        <div class="carousel-cell product">
            <PlaceholderImage />
            <a :href="selectedFilter=='solar' ? '/collection/27/type/solar':'/collection/27'"></a>
            <div class="product-image-hover">
                <img src="https://api.sens-eye.fr/render_parts/model27_shadow_glass_4.png" class="" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model27_endpiece_color24_4.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/api/photo-single-test?photo=model27_frame_textureposition0_texturestyle4_color4_4.png&hue=160&saturation=20&brightness=90" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model27_hinges_temples_color25_4.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model27_temples_texturestyle4_color20_4.png" class="render-layer2" style="opacity: 1;">
                <img :src="selectedFilter=='solar' ? 'https://api.sens-eye.fr/render_parts/model27_lenses_lensescolor3_4.png':'https://api.sens-eye.fr/render_parts/model27_lenses_lensescolor0_4.png'" class="render-layer2" :style="selectedFilter=='solar' ? 'opacity: 0.7;':'opacity: 1;'">
            </div>
            <div class="photo-single product-image collec">
                <img src="https://api.sens-eye.fr/render_parts/model27_shadow_glass_2.png" class="" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model27_endpiece_color24_2.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/api/photo-single-test?photo=model27_frame_textureposition0_texturestyle4_color4_2.png&hue=160&saturation=20&brightness=90" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model27_hinges_temples_color25_2.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model27_temples_texturestyle4_color20_2.png" class="render-layer2" style="opacity: 1;">
                <img :src="selectedFilter=='solar' ? 'https://api.sens-eye.fr/render_parts/model27_lenses_lensescolor3_2.png':'https://api.sens-eye.fr/render_parts/model27_lenses_lensescolor0_2.png'" class="render-layer2" :style="selectedFilter=='solar' ? 'opacity: 0.7;':'opacity: 1;'">
            </div>
            <div class="personnalisation-actions beta">
                |&nbsp;&nbsp;&nbsp; <img src="../../../../public/assets/images/beta.png" width="55" height="auto">
            </div>
            <div class="colors-count">5 <span v-if="language=='fr'">COULEURS</span><span v-else>COLORS</span></div>
            <img src="../../../../public/assets/images/edit1.svg" class="like" width="18">
            <div class="bottom">
                <div class="left">
                    <span class="product-title">Louis C <span class="subtitle"><br>Meyrowitz</span></span>
                    <span class="product-price"><span class="from" v-if="language=='fr'">À partir de</span><span class="from" v-else>From</span> <span>590</span>€</span>
                </div>
                <div class="colors">
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/6639053ae9973.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/663905572e535.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/663905a26228d.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/65e5ec02134ec.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/663905dcab6c9.png');"></div>
                        </a>
                    </div>
                    <div class="plus"></div>
                </div>
            </div>
        </div>
        <div class="carousel-cell product">
            <PlaceholderImage />
            <a :href="selectedFilter=='solar' ? '/collection/26/type/solar':'/collection/26'"></a>
            <div class="product-image-hover">
                <img src="https://api.sens-eye.fr/render_parts/model26_shadow_glass_2.png" class="" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model26_frame_textureposition0_texturestyle4_color19_2.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model26_lenses_lensescolor0_2.png" class="render-layer2" style="opacity: 1;">
            </div>
            <div class="photo-single product-image collec">
                <img src="https://api.sens-eye.fr/render_parts/model26_shadow_glass_1.png" class="" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model26_frame_textureposition0_texturestyle4_color19_1.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model26_lenses_lensescolor0_1.png" class="render-layer2" style="opacity: 1;">
            </div>
            <div class="personnalisation-actions beta">
                |&nbsp;&nbsp;&nbsp; <img src="../../../../public/assets/images/beta.png" width="55" height="auto">
            </div>
            <div class="colors-count">3 <span v-if="language=='fr'">COULEURS</span><span v-else>COLORS</span></div>
            <img src="../../../../public/assets/images/edit1.svg" class="like" width="18">
            <div class="bottom">
                <div class="left">
                    <span class="product-title">Louis OP <span class="subtitle"><br>Meyrowitz</span></span>
                    <span class="product-price"><span class="from" v-if="language=='fr'">À partir de</span><span class="from" v-else>From</span> <span>3990</span>€</span>
                </div>
                <div class="colors">
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/655f145285406.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/6567ce5c060c4.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/6567ce76163ef.png');"></div>
                        </a>
                    </div>
                    <div class="plus"></div>
                </div>
            </div>
        </div>
        <div class="carousel-cell product">
            <PlaceholderImage />
            <a :href="selectedFilter=='solar' ? '/collection/25/type/solar':'/collection/25'"></a>
            <div class="product-image-hover">
                <img src="https://api.sens-eye.fr/render_parts/model25_shadow_glass_4.png" class="" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model25_temples_texturestyle4_color20_4.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model25_endpiece_color24_4.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model25_hinges_temples_color25_4.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/api/photo-single-test?photo=model25_frame_textureposition0_texturestyle4_color4_4.png&hue=110&saturation=30&brightness=100" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model25_frame1_textureposition0_texturestyle4_color19_4.png" class="render-layer2" style="opacity: 1;">
                <img :src="selectedFilter=='solar' ? 'https://api.sens-eye.fr/render_parts/model25_lenses_lensescolor3_4.png':'https://api.sens-eye.fr/render_parts/model25_lenses_lensescolor0_4.png'" class="render-layer2" :style="selectedFilter=='solar' ? 'opacity: 0.7;':'opacity: 1;'">
            </div>
            <div class="photo-single product-image collec">
                <img src="https://api.sens-eye.fr/render_parts/model25_shadow_glass_2.png" class="" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model25_temples_texturestyle4_color20_2.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model25_endpiece_color24_2.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model25_hinges_temples_color25_2.png" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/api/photo-single-test?photo=model25_frame_textureposition0_texturestyle4_color4_2.png&hue=110&saturation=30&brightness=100" class="render-layer2" style="opacity: 1;">
                <img src="https://api.sens-eye.fr/render_parts/model25_frame1_textureposition0_texturestyle4_color19_2.png" class="render-layer2" style="opacity: 1;">
                <img :src="selectedFilter=='solar' ? 'https://api.sens-eye.fr/render_parts/model25_lenses_lensescolor3_2.png':'https://api.sens-eye.fr/render_parts/model25_lenses_lensescolor0_2.png'" class="render-layer2" :style="selectedFilter=='solar' ? 'opacity: 0.7;':'opacity: 1;'">
            </div>
            <div class="personnalisation-actions beta">
                |&nbsp;&nbsp;&nbsp; <img src="../../../../public/assets/images/beta.png" width="55" height="auto">
            </div>
            <div class="colors-count">5 <span v-if="language=='fr'">COULEURS</span><span v-else>COLORS</span></div>
            <img src="../../../../public/assets/images/edit1.svg" class="like" width="18">
            <div class="bottom">
                <div class="left">
                    <span class="product-title">Louis infini <span class="subtitle"><br>Meyrowitz</span></span>
                    <span class="product-price"><span class="from" v-if="language=='fr'">À partir de</span><span class="from" v-else>From</span> <span>1790</span>€</span>
                </div>
                <div class="colors">
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/6639053ae9973.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/663905572e535.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/663905a26228d.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/65e5ec02134ec.png');"></div>
                        </a>
                    </div>
                    <div class="color">
                        <a class="contenudesk">
                            <div class="color-bg" style="background-image: url('https://api.sens-eye.fr/glasses/663905dcab6c9.png');"></div>
                        </a>
                    </div>
                    <div class="plus"></div>
                </div>
            </div>
        </div>
        
        
       
   
</template>

<script>
import PlaceholderImage from './PlaceholderImage.vue';
import AvailableSoon from '../../cards/AvailableSoonCardComponent.vue';
import {APIRequest} from "@simb2s/senseye-sdk"
import { ContentLoader } from 'vue-content-loader'
export default {
    name: "CollectionProduct",
    components: {
        AvailableSoon,
        ContentLoader,
        PlaceholderImage
    },
    props: ["glasses", "selectedFilter","selectedColorFilter","community","slider","language","beta","betaAccess", "landing"],
    watch: {
    // whenever question changes, this function will run
    selectedFilter: async function (newQuestion, oldQuestion) {
        this.loaded=false
        this.loadingFilter=true
        this.current_colors=[]
      await this.calculatePhotos()
      
      this.loaded=true
      

      
    }
  },
    methods:{
        loadStartHover(index){
            if(this.loaded==true)
            {
                this.photosHoverLoaded[index]-=1
            }
           
        },
        getBrightnessElementHover(index, layer) {
            if(this.photoHoverDatas[index][layer].presets.brightness != null) {
                return this.photoHoverDatas[index][layer].presets.brightness/100
            }
            else {
                return 1
            }
        },
        getHueElementHover(index, layer) {
            if(this.photoHoverDatas[index][layer].presets.hue != null) {
                return this.photoHoverDatas[index][layer].presets.hue/1.7
            }
            else {
                return 0
            }
        },
        getSaturationElementHover(index, layer) {
            if(this.photoHoverDatas[index][layer].presets.saturation != null) {
                return this.photoHoverDatas[index][layer].presets.saturation/100
            }
            else {
                return 1
            }
        },
        getOpacityElementHover(index, layer) {
            if(this.photoHoverDatas[index][layer].presets.opacity != null) {
                return this.photoHoverDatas[index][layer].presets.opacity/100
            }
            else {
                return 1
            }
        },

        getBrightnessElement(index, layer) {
            if(this.photoDatas[index].presets['layer'+layer].brightness != null) {
                return this.photoDatas[index].presets['layer'+layer].brightness/100
            }
            else {
                return 1
            }
        },
        getHueElement(index, layer) {
            if(this.photoDatas[index].presets['layer'+layer].hue != null) {
                return this.photoDatas[index].presets['layer'+layer].hue/1.7
            }
            else {
                return 0
            }
        },
        getSaturationElement(index, layer) {
            if(this.photoDatas[index].presets['layer'+layer].saturation != null) {
                return this.photoDatas[index].presets['layer'+layer].saturation/100
            }
            else {
                return 1
            }
        },
        getOpacityElement(index, layer) {
            if(this.photoDatas[index].presets['layer'+layer].opacity != null) {
                return this.photoDatas[index].presets['layer'+layer].opacity/100
            }
            else {
                return 1
            }
        },
        collectionPhotoLoaded(index) {
       
            this.collectionPhotosLoading[index]=false
        },
        updateSlider()
        {
            this.$emit('updateSliderEv')
        },
        likePhoto(glass,id,index)
        {
            this.$emit('likePhotoEv', glass, id, index)
        },
        calculatePhoto(layer_info) {
            let hue = layer_info.presets.hue_app ? layer_info.presets.hue_app : 100
            let saturation = layer_info.presets.saturation ? layer_info.presets.saturation : 100
            let brightness = layer_info.presets.brightness ? layer_info.presets.brightness : 100


            let url
            if(brightness==100 && hue==100 && saturation==100)
            {
            url = 'https://api.sens-eye.fr/render_parts/'+layer_info.render
            }
            else {
            url = 'https://api.sens-eye.fr/api/photo-single-test?photo='+layer_info.render+'&hue='+hue+'&saturation='+saturation+'&brightness='+brightness
            }

            //this.$emit('calculatePhotoEv',0)

            return url
            

        },
        async calculatePhotos(){
            this.photosLoaded=[]
            this.photosHoverLoaded=[]
            this.photoDatas=[]
            this.photoHoverDatas=[]
            this.current_colors=[]
            
            let model_parts_arr=[]
            for(let i=0;i<this.glasses.length;i++){
                this.photosLoaded.push(0)
                this.photosHoverLoaded.push(0)
                let current_colors=[]
                let request_modelparts = '?glass='+this.glasses[i].id
                //let model_parts =  await APIRequest.list_with_request('model-parts',request_modelparts)

                let model_parts_data=this.glasses[i].model_parts

                
                model_parts_arr.push(model_parts_data)
                

               
                

                for(let p=0;p<model_parts_data.length;p++)
                {
                    if((model_parts_data[p].type=='color' || model_parts_data[p].type=='texture_color') &&  model_parts_data[p].render_slug=='frame' )
                    {
                        if(this.current_colors.indexOf(i) == -1)
                        {
                            this.current_colors.push(model_parts_data[p].colors)
                        }
                        
                    }
                }

                // console.log('ok')
                // console.log(current_colors)
                // this.current_colors.push(current_colors)
                
            
                let request = '?glass='+this.glasses[i].id+'&branch=4'
                for(let j=0;j<model_parts_data.length;j++) {
                
                    
                
                if(model_parts_data[j].render_type!="shadow" && model_parts_data[j].render_type!="hinge" && model_parts_data[j].render_type!="manchon"){
                    if(model_parts_data[j].type=='color') {
                        if(model_parts_data[j].render_slug=="frame" || model_parts_data[j].render_slug=="frame1")
                        {
                            if(model_parts_data[j].material=="plastic")
                            {
                                if(this.glasses[i].default_color!=null && model_parts_data[j].render_slug!="frame1")
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glasses[i].default_color.id
                                }
                                else
                                {
                                    if(this.glasses[i].id==29)
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'=6'
                                    }
                                    else
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'=10'
                                    }
                                }
                               

                                
                                
                            }
                            else 
                            {
                                if(this.glasses[i].default_color!=null && model_parts_data[j].render_slug!="frame1")
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glasses[i].default_color.id
                                }
                                else
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'=19'
                                }
                            }
                            request+='&texture_style_'+model_parts_data[j].render_slug+'=0'
                        }
                        else if(model_parts_data[j].render_slug=="temples")
                        {
                            if(model_parts_data[j].material=="plastic")
                            {
                                if(this.glasses[i].default_color!=null)
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glasses[i].default_color.id
                                }
                                else
                                {
                                    if(this.glasses[i].id==29)
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'=6'
                                    }
                                    else
                                    {
                                        if(model_parts_data[j].colors.length)
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'='+model_parts_data[j].colors[0].id
                                        }
                                        else
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'=10'
                                        }
                                    }
                                }
                               
                                
                            }
                            else {
                                if(model_parts_data[j].colors.length)
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'='+model_parts_data[j].colors[0].id
                                        }
                                        else
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'=20'
                                        }
                            }
                            request+='&texture_style_'+model_parts_data[j].render_slug+'=0'
                        }
                        else {
                        if(model_parts_data[j].material=="plastic")
                        {
                            request+='&color_'+model_parts_data[j].render_slug+'=10'
                           
                        }
                        else {
                            request+='&color_'+model_parts_data[j].render_slug+'=19'
                            
                        }
                        
                        }
                        request+='&texture_'+model_parts_data[j].render_slug+'=4'
                    
                    }
                    else if(model_parts_data[j].type=='texture_color') { 
                
                
                        if(model_parts_data[j].render_slug=="frame" || model_parts_data[j].render_slug=="frame1")
                        {

                            if(this.glasses[i].default_color!=null && model_parts_data[j].render_slug!="frame1")
                            {
                                request+='&color_'+model_parts_data[j].render_slug+'='+this.glasses[i].default_color.id
                            }
                            else
                            {

                                if(model_parts_data[j].material=="plastic")
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'=10'
                                }
                                else 
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'=19'
                                }
                            }
                            
                            request+='&texture_style_'+model_parts_data[j].render_slug+'=0'
                        }
                        else if(model_parts_data[j].render_slug=="temples")
                        {
                
                        
                            if(model_parts_data[j].material=="plastic")
                            {
                                if(this.glasses[i].default_color!=null)
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glasses[i].default_color.id
                                }
                                else
                                {
                                request+='&color_'+model_parts_data[j].render_slug+'=10'
                                }
                            }
                            else {
                                if(model_parts_data[j].colors.length)
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'='+model_parts_data[j].colors[0].id
                                        }
                                        else
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'=20'
                                        }
                               
                            }
                        
                
                        request+='&texture_style_'+model_parts_data[j].render_slug+'=0'
                        }
                        else {
                        if(model_parts_data[j].material=="plastic")
                        {
                            request+='&color_'+model_parts_data[j].render_slug+'=10'
                        }
                        else {
                            request+='&color_'+model_parts_data[j].render_slug+'=19'
                        }
                        
                        }
                        
                        request+='&texture_'+model_parts_data[j].render_slug+'=4'
                
                    }
                    else if (model_parts_data[j].render_type=="lense")
                    {
                        // ici tint
                        let default_tint=4
                        if(this.selectedFilter=='optic')
                        {
                            default_tint=5
                        }
                        else 
                        {
                            if(this.glasses[i].default_tint!=null)
                            {
                                default_tint=this.glasses[i].default_tint.id
                            }
                               
                        }

                        
                        
                      
                        request+='&tint_'+model_parts_data[j].render_slug+'='+default_tint
                        
                    }
                    
                    
                    
                    }
                    else if (model_parts_data[j].render_type=="manchon")
                    {
                        request+='&color_'+model_parts_data[j].render_slug+'=24'
                   
                    }
                }
                
                
                let render_infos =  await APIRequest.list_renders('photos-layers-new',request)
                let render_infos_datas=render_infos
                
                if(this.glasses[i].id==26)
                {
                    this.photoHoverDatas.push(render_infos_datas.scene2)
                    this.photoDatas.push(render_infos_datas.scene1) 
                }
                else {
                    this.photoHoverDatas.push(render_infos_datas.scene4)
                    this.photoDatas.push(render_infos_datas.scene2) 
                }

                
            
                 
                
               
            }
            
            this.model_parts=model_parts_arr

            this.loaded=true

            
           
        },
        async calculatePhotoHover(index){
           
            let request = '?glass='+this.glasses[index].id+'&branch=4'

            let model_parts_data=this.model_parts[index]

            for(let j=0;j<this.model_parts[index].length;j++) {

                if(this.model_parts[index][j].render_type!="shadow" && this.model_parts[index][j].render_type!="hinge" && this.model_parts[index][j].render_type!="manchon"){
                    if(this.model_parts[index][j].type=='color') {
                        if(this.model_parts[index][j].render_slug=="frame" || this.model_parts[index][j].render_slug=="frame1")
                        {
                            if(this.selectedColorFilterHover!='all' && this.model_parts[index][j].render_slug=="frame") {
                                
                                request+='&color_'+this.model_parts[index][j].render_slug+'='+this.selectedColorFilterHover
                            }
                            else {
                                if(this.model_parts[index][j].material=="plastic")
                                {
                                    

                                    if(this.glasses[index].default_color!=null && model_parts_data[j].render_slug!="frame1")
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'='+this.glasses[index].default_color.id
                                    }
                                    else
                                    {
                                        if(this.glasses[index].id==29)
                                        {
                                            request+='&color_'+this.model_parts[index][j].render_slug+'=6'
                                        }
                                        else
                                        {
                                            request+='&color_'+this.model_parts[index][j].render_slug+'=10'
                                        }
                                    }
                                }
                                else 
                                {
                                    if(this.glasses[index].default_color!=null && model_parts_data[j].render_slug!="frame1")
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'='+this.glasses[index].default_color.id
                                    }
                                    else
                                    {
                                        request+='&color_'+this.model_parts[index][j].render_slug+'=19'
                                    }
                                }
                            }
                            //ici color
                            
                            request+='&texture_style_'+this.model_parts[index][j].render_slug+'=0'
                        }
                        else if(this.model_parts[index][j].render_slug=="temples")
                        {

                            if(this.selectedColorFilterHover!='all' && this.model_parts[index][j].material=="plastic") {
                                request+='&color_'+this.model_parts[index][j].render_slug+'='+this.selectedColorFilterHover
                            }
                            else {
                                if(this.model_parts[index][j].material=="plastic")
                                {
                                    if(this.glasses[index].default_color!=null)
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'='+this.glasses[index].default_color.id
                                    }
                                    else
                                    {
                                        if(this.glasses[index].id==29)
                                        {
                                            request+='&color_'+this.model_parts[index][j].render_slug+'=6'
                                        }
                                        else
                                        {
                                            request+='&color_'+this.model_parts[index][j].render_slug+'=10'
                                        }
                                    }
                                    
                                }
                                else 
                                {
                                    
                                    if(this.model_parts[index][j].colors.length)
                                        {
                                            request+='&color_'+this.model_parts[index][j].render_slug+'='+this.model_parts[index][j].colors[0].id
                                        }
                                        else
                                        {
 request+='&color_'+this.model_parts[index][j].render_slug+'=20'
                                        }

                                   
                                }
                            }
                            //ici color
                            request+='&texture_style_'+this.model_parts[index][j].render_slug+'=0'
                        }
                        else {
                            if(this.selectedColorFilterHover!='all' && this.model_parts[index][j].material=="plastic") {
                                request+='&color_'+this.model_parts[index][j].render_slug+'='+this.selectedColorFilterHover
                            }
                            else {
                                if(this.model_parts[index][j].material=="plastic")
                                {
                                    request+='&color_'+this.model_parts[index][j].render_slug+'=10'
                                }
                                else 
                                {
                                    request+='&color_'+this.model_parts[index][j].render_slug+'=19'
                                }
                            }
                        
                        }
                        request+='&texture_'+this.model_parts[index][j].render_slug+'=4'
                    
                    }
                    else if(this.model_parts[index][j].type=='texture_color') { 
                
                
                        if(this.model_parts[index][j].render_slug=="frame" || this.model_parts[index][j].render_slug=="frame1")
                        {
                            if(this.selectedColorFilterHover!='all') {
                                request+='&color_'+this.model_parts[index][j].render_slug+'='+this.selectedColorFilterHover
                            }
                            else {

                                if(this.glasses[index].default_color!=null && model_parts_data[j].render_slug!="frame1")
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glasses[index].default_color.id
                                }
                                else
                                {
                                    
                                    if(this.model_parts[index][j].material=="plastic")
                                    {
                                        request+='&color_'+this.model_parts[index][j].render_slug+'=10'
                                    }
                                    else 
                                    {
                                        request+='&color_'+this.model_parts[index][j].render_slug+'=19'
                                    }
                                }
                            }
                            
                            request+='&texture_style_'+this.model_parts[index][j].render_slug+'=0'
                        }
                        else if(this.model_parts[index][j].render_slug=="temples")
                        {
                
                            if(this.selectedColorFilterHover!='all' && this.model_parts[index][j].material=="plastic") {
                                request+='&color_'+this.model_parts[index][j].render_slug+'='+this.selectedColorFilterHover
                            }
                            else {
                                if(this.model_parts[index][j].material=="plastic")
                                {
                                    if(this.glasses[index].default_color!=null)
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'='+this.glasses[index].default_color.id
                                    }
                                    else
                                    {
                                        request+='&color_'+this.model_parts[index][j].render_slug+'=10'
                                    }
                                }
                                else 
                                {
                                    if(this.model_parts[index][j].colors.length)
                                        {
                                            request+='&color_'+this.model_parts[index][j].render_slug+'='+this.model_parts[index][j].colors[0].id
                                        }
                                        else
                                        {
                                            request+='&color_'+this.model_parts[index][j].render_slug+'=20'
                                        }
                                }
                            }
                
                        request+='&texture_style_'+this.model_parts[index][j].render_slug+'=0'
                        }
                        else {
                            if(this.selectedColorFilterHover!='all' && this.model_parts[index][j].material=="plastic") {
                                request+='&color_'+this.model_parts[index][j].render_slug+'='+this.selectedColorFilterHover
                            }
                            else {
                                if(this.model_parts[index][j].material=="plastic")
                                {
                                    request+='&color_'+this.model_parts[index][j].render_slug+'=10'
                                }
                                else 
                                {
                                    request+='&color_'+this.model_parts[index][j].render_slug+'=19'
                                }
                            }
                        
                        }
                        
                        request+='&texture'+this.model_parts[index][j].render_slug+'=4'
                
                    }
                    else if (this.model_parts[index][j].render_type=="lense")
                    {
                        // ici tint

                        // ici tint
                        let default_tint=4
                        if(this.selectedFilter=='optic')
                        {
                            default_tint=5
                        }
                        else
                        {
                            if(this.glasses[index].default_tint!=null)
                            {
                                default_tint=this.glasses[index].default_tint.id
                            }
                        }
                        request+='&tint_'+this.model_parts[index][j].render_slug+'='+default_tint
                        // request+='&tint_'+this.model_parts[index][j].render_slug+'=4'
                        
                    }
                    
                    
                    
                    }
                    else if (this.model_parts[index][j].render_type=="manchon")
                    {
                        request+='&color_'+this.model_parts[index][j].render_slug+'=24'
                   
                    }
                }
                this.loaded=true
                   

                let datas =  await APIRequest.list_renders('photos-layers-new',request)
                if(this.glasses[index].id==26)
                {
                    this.photoHoverDatas[index]=datas.scene2
                }
                else
                {
                    this.photoHoverDatas[index]=datas.scene4
                }
               

        },
        hoverColor(color, index) {
            this.selectedColorFilterHover=color
            this.collectionPhotosLoading[index]=true
           

            this.calculatePhotoHover(index)
            this.hideHover=false

        },
        resetColor(index) {
            this.hideHover=false
            this.selectedColorFilterHover='all'

            this.calculatePhotoHover(index)
            this.collectionPhotosLoading[index]=true
        },
    },
    data(){
        return{
            hideHover:false,
            collectionPhotosLoading:[],
            loop: 3,
            model_parts:null,
            loadingFilter:false,
            current_colors:[],
            loaded:false,
            photosLoaded:[],
            photosHoverLoaded:[],
            photoHoverDatas:null,
            photoDatas:null,
            selectedColorFilterHover:'all'
        }
    },
    async mounted(){
        await this.calculatePhotos()
        this.loaded=true
    },
    updated(){
       
        
    }
}
</script>

<style scoped>
.carousel-cell a
{
    text-decoration: none;
    color:#000;
    position:absolute;
    z-index:3;
    left:0;
    top:0;
    width:100%;
    height:100%;
}

.personnalisation-actions
{
    position:absolute;
    top:10px;
    text-align: left;
    left:15px;
    z-index:3;
}

.colors-count
{
    
    font-size:13px;

}
.colors-count .pls
{
background: linear-gradient(80deg, #95B4F2 0%, #9F96F0 100%) 0% 0% no-repeat padding-box;
-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.product {
  margin-bottom:150px;
}
.personnalisation-button::before
{
    content:' ';
    position:absolute;
    left:5px;
    top: 7px;
    width:13px;
    height:13px;
    background-size:100% 100%;
    display:block;
    background-image:url(../../../../public/assets/images/bullet.svg);
}
.personnalisation-button
{
    position:relative;
    font-size:13px;
    background: transparent linear-gradient(90deg, #CBDCF8 0%, #DDD5F7 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #FFFFFF00;
  border-radius: 8px;
  padding:5px 10px 5px 25px;
}
.like img {
    width:23px !important;
}
.comingsoon .text 
{
    position:absolute;
    left:50%;
    top:50%;
    font-size:20px;
    transform: translate(-50%,-50%);
}
.comingsoonimg
{
    max-height:200% !important;
    height:100% !important;
    position: absolute;
    top:0;
}
.comingsoon
{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    z-index:4;
    height:100%;
    background-color: rgba(0,0,0,0.5);
    backdrop-filter: blur(20deg);
}
.like
{
    cursor:pointer;
    position:absolute;
    top:10px;
    z-index:13;
    text-align: right;
    right:15px;
}.personnalisation-text
{
    position:relative;
    top:2px;
    margin-left:5px;
    color:transparent;
    font-size:12px;
    background: transparent linear-gradient(89deg, rgba(0, 0, 255, 0.6) 0%, #0000FFE6 100%) 0% 0% no-repeat padding-box;
opacity: 1;
background-clip:text;
}
.like {
    right:20px;
    top:20px; 
}
.personnalisation-actions.beta img {
    display:inline-block;
    width:55px !important;
    height:14px !important;
    position: relative;
    top:4px;
}
.personnalisation-actions {
    display:flex;
    left:20px;
    top:20px;
}
.product-image-space.loading {
    position:relative;
    z-index:2;
    backdrop-filter: blur(30px);
}
.product-image {
width:100%;
height:auto;
}
.product-image.collec {
    position:absolute;
    left:0;
    
    top:50%;
    transform: translateY(-50%);
    
}
.product-image-space {
    width:100%;
    height:auto;
    margin-top:30px;
    margin-bottom:30px;
}
.render-layer2 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 2;
}
.product.opaque {
    opacity:0 !important;
}
.product-price .from {
    font-size:13px;
color:#ACACAC;
font-weight: 400;
}
.product-price {
    font-size:21px !important;
    font-weight: 600;
}
.render-layer3 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 3;
}
div.products div.product .bottom .product-price {
    float:right;
}
.personnalisation-text img {
    display: inline-block;
}
.render-layer4 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 4;
}
.render-layer5 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 5;
}
.render-layer6 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 6;
}
.render-layer7 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 7;
}

.photo-single {
    position: relative;
    z-index: 2;
}
.product-image-hover {
    position:absolute;
    width:100%;
    height:auto;
    z-index:8;
    left:0;
    background: #F8F8F8;
    top:0;
    transition:all 0.5s;
    opacity:0;
}
.carousel-cell:hover .product-image-hover {
        opacity:1;
    }
.carousel-cell a
{
    text-decoration: none;
    color:#000;
    position:absolute;
    z-index:13;
    left:0;
    top:0;
    width:100%;
    height:100%;
}
.products .product .bottom .product-title .subtitle img {
    display: inline-block;
    position:relative;
    top:2px;
}
.products .product .bottom .product-title .subtitle {
    font-size:18px;
    font-weight: 400;
    text-transform: none;
}
.products .product .bottom .product-title
{
	display: inline-block;
    font-size:22px;
    font-weight: 600;
	text-transform: uppercase;
}

.products .product .bottom .left {
    width:100%;
    position:relative;
    left:-10px;
    line-height: 1;
    top:70px;
}
.products .product .bottom .right {
    text-align: right;
    width:60%;
}
.colors-count {
    position:absolute;
    right:20px;
    bottom:20px;
    z-index:2;
}
.products .product:hover .bottom .colors {
    opacity:1;
}
.products .product {
    margin-bottom:150px;
}
.products .product .bottom .colors {
    opacity:0;
    transition:all 0.3s;
    position:absolute;
    bottom:0;
    left:0;
    width:95%;
    padding:10px;
    display:flex;
    justify-content: center;
    background:#fff;
    align-items: center;
}
.load-global {
    width:80vw !important;
    max-width:80vw !important;
    position:absolute;
    left:50%;
    transform: translateX(-50%);
}
.loader-image {
    position:absolute;
    top:0;
    left:0;
    z-index:10;
}
.products .product .bottom .colors .color {
    display:flex;
    width:16px;
    cursor: pointer;
    height:16px;
    border:1px solid #C0C0C0;
    position:relative;
    border-radius:50%;
    margin-right:5px;
    margin-left:5px;
}
.products .product .bottom .colors .color:hover,
.products .product .bottom .colors .color.active {
    border:1px solid #000;
}
.products .product .bottom
{
    display:flex;

}
.products .product .bottom .colors .plus {
    margin-right: 20px;
}
.products .product .bottom .colors .plus img {
    width:11px;
}
.products .product .bottom .colors .color .color-bg {
    width:14px;
    height:14px;
    border-radius:50%;
    position:absolute;
    left:0px;
    top:0px;
    border:0;
    background-size:cover;
    background-position:center center;
}
div.products div.product .bottom {
    z-index:14;
    bottom: 10px;
    left:10px;
}
</style>