<template>
   <div class="collection element-big2">


<img class="img" src="../../../../../public/assets/images/biginfini.jpg" />

</div>

<div class="collection element-sustainability">
            <img class="zero" src="../../../../../public/assets/images/zero2.png"  />
            <div class="left"  :style="'background-image:url(/assets/images/collec5.jpg);'"></div>
            <div v-if="language=='fr'" class="right">
                
                <div class="round-element">
                    IMPRESSION 3D
                </div>
                <br />
                <div class="round-element">
                    À LA DEMANDE
                </div>
                <br />
                <div class="round-element">
                    ZÉRO DÉCHET
                </div>

                <div class="title">L'Éco-Innovation dans Chaque Monture</div>
                Chaque monture est imprimée en 3D, à la demande, en Europe, avec des matériaux éco-responsables. À travers nos techniques de fabrication innovantes, nous offrons des lunettes entièrement sur-mesure, tout en assurant un processus de production zéro déchet. 
            </div>
            <div v-else class="right">
                
                <div class="round-element">
                     3D PRINTED
                </div>
                <br />
                <div class="round-element">
                    ON DEMAND
                </div>
                <br />
                <div class="round-element">
                    ZERO WASTE
                </div>

                <div class="title">Eco-Innovation in Every Frame</div>
                Each product is fully 3D-printed on-demand in Europe using sustainable materials. Through our innovative manufacturing techniques, we ensure fully individualized eyewear with a zero waste manufacturing process - setting new standards in sustainability.
            </div>
        </div>


</template>

<script>

export default {
    name: "BrandProductionComponent",
    props: ["language"],
    components: {
       
  },
    methods: {    

        

                
    },
    data(){
      
        return{
          

        }
    },
    computed: {
    

    },
}
</script>

<style scoped>


@media screen and (min-width:760px) and (max-width:1024px)
  {
   body .element-sustainability
    {
        margin-top:0 !important;
    }
    .round-element
    {
        font-size: 12px !important;
        margin-top:7px !important;
    }
    .element-sustainability .zero
    {
        top:-50px !important;
        left:auto !important;
        right:0 !important;
        width:115px !important;
    }
    .element-sustainability .right .title
    {
        margin-top:15px;
        margin-bottom: 15px;
        font-size: 32px !important;
    }
   .element-sustainability .right {
        order:2;
        width:100% !important;
        padding-bottom:0px;
        padding-top:30px;
    }
    .element-sustainability .left {
        order:1;
        height:35vh !important;
        min-height: 350px !important;
        width:100% !important;
    }
     .element-sustainability {
        flex-direction: column;
    }
   .collection-content .element-sustainability {
        margin-top:50px !important;
        padding-bottom:100px;
    }

.element-big2
    {
        min-height: 40vh !important;
        margin-top:0 !important;
        margin-bottom: 0 !important;
    }
  }


@media screen and (max-width:760px)
  {
   body .element-sustainability
    {
        margin-top:0 !important;
    }
    .round-element
    {
        font-size: 12px !important;
        margin-top:7px !important;
    }
    .element-sustainability .zero
    {
        top:-50px !important;
        left:auto !important;
        right:0 !important;
        width:115px !important;
    }
    .element-sustainability .right .title
    {
        margin-top:15px;
        margin-bottom: 15px;
        font-size: 32px !important;
    }
   .element-sustainability .right {
        order:2;
        width:100% !important;
        padding-bottom:0px;
        padding-top:30px;
    }
    .element-sustainability .left {
        order:1;
        height:35vh !important;
        min-height: 350px !important;
        width:100% !important;
    }
     .element-sustainability {
        flex-direction: column;
    }
   .collection-content .element-sustainability {
        margin-top:50px !important;
        padding-bottom:100px;
    }

.element-big2
    {
        min-height: 40vh !important;
        margin-top:0 !important;
        margin-bottom: 0 !important;
    }
  }
    .element-big2 .img {
    width:80%;
    margin-left:auto;
    margin-right:auto;

}

.element-big2 {
    min-height:80vh;
    
    display:flex;
    align-items: center;
    justify-content: center;
    width:100%;

}

.round-element:hover
{
    background: rgba(117, 110, 93, 1);
    color:#fff;
}

.round-element {
    margin-top:10px;
    border: 1px solid rgba(117, 110, 93, 1);
border-radius: 34px;
font-size:25px;
width:auto;
transition: all 0.3s;
line-height: 1;
display:inline-block;
clear: both;
padding:0px 15px 4px 15px;
}

.element-sustainability .zero {
    position:absolute;
    top:-110px;
    animation: rotating 6s linear infinite;
    left:calc(50% - 45px);
    width:220px;
}

.element-sustainability .right .title {
    font-size: 60px;
    font-weight: 600;
    font-style: italic;
    word-wrap:unset;
    margin-top:10px;
    margin-bottom: 10px;

    line-height:0.8;
}
.element-sustainability .right {
    width:45%;
    padding-left:5%;
    position: relative;
    padding-right:5%;
    font-size: 15px;
    color:rgba(117, 110, 93, 1);
}
.element-sustainability .left {
    width:55%;
    min-height: 450px;
    height: 50vh;
    background:grey;
    background-size:cover;
    background-position: center center;
   
}
 .element-sustainability
{
    margin-top:250px !important;
    position:relative;
    padding-left:10%;
    padding-right:10%;
    display: flex;
    align-items: flex-end;
}

</style>