<template>
    
  <div class="page-template-home-closed-new">
    <div class="popup-beta-access" v-if="displayBetaAccessPopup && betaAccess">
        <div class="content" v-if="language=='fr'">
            
            <img src="../../public/assets/images/cross.svg" class="cross" @click="displayBetaAccessPopup=false;" width="40" />
            <div class="title">BIENVENUE SUR LA<br />
              COLLECTION &nbsp;&nbsp;| <img src="../../public/assets/images/beta.png" class="beta-title-white little" />
            </div>
            <div class="text">{{user_name}}, Votre demande d’accès à la bêta a été acceptée. Vous êtes désormais prêt à découvrir nos collections exclusives de lunettes imprimées en 3D.</div>
        </div>

        <div class="content" v-else>
            
            <img src="../../public/assets/images/cross.svg" class="cross" @click="displayBetaAccessPopup=false;" width="40" />
            <div class="title">WELCOME TO<br />
              COLLECTION &nbsp;&nbsp;| <img src="../../public/assets/images/beta.png" class="beta-title-white little" />
            </div>
            <div class="text">{{user_name}},
                Your access request has been accepted. You can now discover our 3D printed glasses exclusives collections.
               </div>
        </div>
    </div>
    <div class="beta-video-bg" v-if="betaAccess==false">
        <div class="video" style="padding:100% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/920177397?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&background=1&muted=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="1 - YOUR OWN DESIGN"></iframe></div>
    </div>
    <div class="beta-voile"  v-if="betaAccess==false"></div>


            
    <div class="beta-content" id="betacontainer" :class="{betaintro:displayIntroBeta,nointro:displayIntroBeta==false}"  v-if="betaAccess==false">
        <content-loader v-if="loading"  class="content" 
              viewBox="0 0 410 100"
              primaryColor="#121212"
              secondaryColor="#2c2c2c"
              >

               <rect x="5" y="0" rx="3" ry="3" width="400" height="16" />

               <rect x="5" y="30" rx="3" ry="3" width="400" height="16" />

               <rect x="5" y="60" rx="3" ry="3" width="400" height="16" />
                 
            </content-loader>
        <div class="content" :class="{pasintro:displayIntroBeta==false}" v-else>
            
            <img width="166" src="../../public/assets/images/2021/04/cropped-Groupe-463@2x.png" class="custom-logo-beta" alt="VISAGES">
            <h2>COLLECTION &nbsp;&nbsp;| <img src="../../public/assets/images/beta.png" class="beta-title-white" /></h2>



            <template v-if="displayNoneStatus==false">
                <div class="text-bold" v-if="language=='fr'">
                    Embarquez dans l'aventure dès maintenant. Bienvenue chez Visages.   
                </div>
                <div class="text-bold"  v-else>
                    Join the adventure now. Welcome to Visages.     
                </div>
                <div class="text" v-if="language=='fr'">
                    Rejoignez dès aujourd'hui notre programme Bêta et explorez l'avenir de la lunetterie avec Visages. Découvrez nos technologies de pointe et bénéficiez d'un accès exclusif à de nouveaux modèles tout en ayant la possibilité de fournir des retours qui influenceront nos futures collections.
                    
                </div>
                <div class="text" v-else>
                    Join our Beta program today and explore the future of eyewear with Visages. Discover our cutting-edge technologies, and receive exclusive access to new designs with the opportunity to provide feedback that will influence our future collections. 
                </div>
            </template>

            <template v-else>
                <div class="text-bold"  v-if="language=='fr'">
                    Votre compte n'a pas encore été créé.
                </div>
                <div class="text-bold"  v-else>
                    YOUR ACCOUNT HASN’T YET BEEN CREATED.
                </div>
                <div class="text"  v-if="language=='fr'">
                    Votre compte n'a pas encore été créé. Rejoignez dès aujourd'hui notre programme Bêta et explorez l'avenir de la lunetterie avec Visages. Découvrez nos technologies de pointe et bénéficiez d'un accès exclusif à de nouveaux modèles, tout en ayant la possibilité de fournir des retours qui influenceront nos futures collections.
                </div>
                <div class="text"  v-else>
                    Your account hasn’t yet been created. Join our Beta program today and explore the future of eyewear with Visages. Discover our cutting-edge technologies, and receive exclusive access to new designs with the opportunity to provide feedback that will influence our future collections.
                </div>
            </template>

            <div class="subscribe-beta form" v-if="displayIntroBeta==true">
                <center>
                    
                    <!-- <button @click="displaySubscribe=true;displayLoginForm=false;displayIntroBeta=false;" class="white" v-if="language=='fr'">Inscrivez-vous au BÊTA</button>
                    <button @click="displaySubscribe=true;displayLoginForm=false;displayIntroBeta=false;" class="white" v-else>Sign up to BETA</button>
                    <br />
                    <a class="link" @click="displaySubscribe=false;displayLoginForm=true;displayIntroBeta=false;" v-if="language=='fr'">Connectez-vous au BÊTA</a>
                    <a class="link" @click="displaySubscribe=false;displayLoginForm=true;displayIntroBeta=false;" v-else>Log in to BETA</a>
                 -->
                 
                    <a class="link whatsisbeta" @touchstart="goDown(1)" @click="goDown(1)" v-if="language=='fr'">Qu'est ce que la BÊTA ?</a>
                    <a class="link whatsisbeta" @touchstart="goDown(1)" v-else>What is BÊTA?</a> 
                </center>
            </div>


            <div class="subscribe-beta form" v-if="displayNoneStatus==true">
                <!-- <center>
                    
                    <button @click="displaySubscribe=true;displayLoginForm=false;displayIntroBeta=false;displayNoneStatus=false;" class="white" v-if="language=='fr'">Inscrivez-vous au BÊTA</button>
                    <button @click="displaySubscribe=true;displayLoginForm=false;displayIntroBeta=false;displayNoneStatus=false;" class="white" v-else>Sign up to BETA</button>
                    <br />
                    <a class="link" @click="displaySubscribe=false;displayLoginForm=true;displayIntroBeta=false;displayNoneStatus=false;" v-if="language=='fr'">Connectez-vous au BÊTA</a>
                    <a class="link" @click="displaySubscribe=false;displayLoginForm=true;displayIntroBeta=false;displayNoneStatus=false;" v-else>Log in to BETA</a>
                </center> -->
            </div>
            

            <div class="subscribe-beta form" v-if="displaySubscribe==true">
                <br /><br />
                <form v-if="success_subscribe==false" @submit.prevent="subscribeBeta">
                    <div class="form-line">
                        <input type="text" required v-model="surname_suscribtion" placeholder="Nom*" v-if="language=='fr'" />
                        <input type="text" required v-model="surname_suscribtion" placeholder="Last name*" v-else />
                        <input type="text" required v-model="name_suscribtion" placeholder="Prénom*" v-if="language=='fr'" />
                        <input type="text" required v-model="name_suscribtion" placeholder="First name*" v-else />
                    </div>
                    <br />
                    <input type="email" required v-model="email_suscribtion" placeholder="Email*" />
                    <br />
                    <div class="form-line">
                    <input type="password" required v-model="password_suscribtion" placeholder="Mot de passe*" v-if="language=='fr'" />
                    <input type="text" required v-model="password_suscribtion" placeholder="Password*" v-else />
                    <input type="password" required v-model="repeat_password_suscribtion" placeholder="Répétez mot de passe*" v-if="language=='fr'" />
                    <input type="text" required v-model="repeat_password_suscribtion" placeholder="Repeat password*" v-else />
                    </div>
                    <div class="little-legend" v-if="language=='fr'">
                       Si vous avez déjà un compte, utilisez le mot de passe qui y est associé
                    </div>
                    <div class="little-legend" v-else>
                        If you already have an account, use the same password
                    </div>
                    <br />
                    <textarea  v-model="message_suscribtion" v-if="language=='fr'" placeholder="Pourquoi souhaitez-vous rejoindre l'accès BÊTA ? Comment avez-vous entendu parler de VISAGES ?"></textarea>
                    <textarea  v-model="message_suscribtion" v-else  placeholder="Why would you like to join BETA access ? How did you hear about VISAGES ? "></textarea>
                    <br /><br />
                    <button  v-if="language=='fr'" class="white" type="submit">S'inscrire</button>
                    <button  v-else class="white" type="submit">Sign up</button>
                    <br />
                    <!-- <center>
                        <a class="link" @click="displaySubscribe=false;displayLoginForm=true;displayIntroBeta=false;" v-if="language=='fr'">Connectez-vous au BÊTA</a>
                        <a class="link" @click="displaySubscribe=false;displayLoginForm=true;displayIntroBeta=false;" v-else>Log in to BETA</a>
                    </center> -->
                </form>
                <div class="success-message confirm" v-if="success_subscribe==true && language=='fr'">
                    <br />

                    Votre demande a été envoyée. Si elle est acceptée, vous recevrez un email dans votre boîte de réception.
                    <br />Veuillez suivre les instructions dans l'email pour activer votre compte.

                    
                    <br /><br />
                    <center><img  src="../../public/assets/images/ok.png" width="34" /></center><br />
                </div>
                <div class="success-message confirm" v-else-if="success_subscribe==true">
                    <br />

                    Your request has been sent. If accepted, you will receive an email in your inbox. 
                    <br />Please follow the instructions in the email to activate your account. 
                    <br /><br />
                    <center><img  src="../../public/assets/images/ok.png" width="34" /></center><br />
                </div>
                <div class="success-message" v-if="error_subscribe!=null">
                    <br />
                   {{error_subscribe}}
                </div>
            </div>

            <div class="subscribe-beta form" v-if="displayLoginForm==true">
                <br /><br />
                <form  v-if="pending_message==false" @submit.prevent="checkBeta">
                    <div class="form-line">
                        <input type="email" v-model="email_login" required placeholder="Email*" />
                        <input type="password" v-if="language=='fr'" v-model="password_login" required placeholder="Mot de passe*" />
                        <input type="password" v-else v-model="password_login" required placeholder="Password*" />
                    </div>
                    <br /><br />
                
                    <button type="submit"  class="white" v-if="language=='fr'">Se connecter</button>
                    <button type="submit" class="white" v-else>Log-in</button>
                    <br />
                    <!-- <center>
                        <a class="link" @click="displaySubscribe=true;displayLoginForm=false;displayIntroBeta=false;" v-if="language=='fr'">Inscrivez-vous au BÊTA</a>
                        <a class="link" @click="displaySubscribe=true;displayLoginForm=false;displayIntroBeta=false;" v-else>Sign up to BETA</a>
                    </center> -->
                </form>
                

                <div class="success-message" v-if="error_login!=null">
                    <br />
                   {{error_login}}
                </div>
                
            </div>

            <div class="success-message confirm" v-if="pending_message==true  && language=='fr'">
                <br /><br />
                
                Votre demande a été envoyée. Si elle est acceptée, vous recevrez un email dans votre boîte de réception.
                    <br />Veuillez suivre les instructions dans l'email pour activer votre compte.

                <br /><br />
                <center><img  src="../../public/assets/images/sablier.png" width="28" /></center><br /><br />
               
            </div>
            <div class="success-message confirm" v-else-if="pending_message==true">
                <br /><br />
                
                Your request has been sent. If accepted, you will receive an email in your inbox. 
                <br />Please follow the instructions in the email to activate your account. 
                <br /><br />
                <center><img  src="../../public/assets/images/sablier.png" width="28" /></center><br /><br />
               
            </div>
            <template  v-if="pending_message">

            <br /><br />

            <center>
                    <a class="link" href="/" v-if="language=='fr'">Retour à la page d'accueil</a>
                    <a class="link" href="/" v-else>Back to home</a>
                </center>

            </template>




                <br /><br />

                

                


        </div>

        
        <div v-if="displayIntroBeta==true && betaAccess==false" class="beta-screen-container" id="beta-screen-container">
            
            <div v-if="displayIntroBeta==true" class="beta-screen screen1" id="beta1">

                <div class="content-scren">
                    <div class="text-big" v-if="language=='fr'">
                        Rejoignez notre programme BETA et participez à la révolution de l'industrie lunetière avec des produits <span class="u">sur-mesure</span>, <span class="u">fabriqués à la demande</span> & conçus pour être <span class="u">entièrement recyclables</span>.

                    </div>
                    <div class="text-big" v-else>
                        Apply for Beta and join the industry revolution with <span class="u">made-to-order</span>, <span class="u">made-to-measure</span> & <span class="u">fully recyclable</span> eyewear at scale.


                    </div>

                    <div class="visages">VISAGES</div>
                </div>
                <div class="bottom-screen" @touchstart="goDown(2)"  @click="goDown(2)">
                    <img  src="../../public/assets/images/arrow-bottom.png" class="arrow_bottom" width="40" />
                    <br />
                    Scroll
                </div>

            </div>
       

            <div v-if="displayIntroBeta==true" class="beta-screen screen2" id="beta2">

                <div class="beta-table">
                    <div class="left">
                        <div>
                            
                                <div class="title" v-if="language=='fr'">Accédez en avant-première et en exclusivité à des drops de lunettes imprimées en 3D</div>
                                <div class="title" v-else>GET EARLY & EXCLUSIVE ACCESS TO 3D PRINTED EYEWEAR DROPS</div>

                                <div class="elems-round-beta" v-if="language=='fr'">
                                    <div class="elem-round-beta">Entièrement personnalisables</div>

                                    <div class="elem-round-beta">Imprimées en 3D</div>
                                </div>
                                <div class="elems-round-beta" v-else>
                                    <div class="elem-round-beta">Fully Customisable</div>

                                    <div class="elem-round-beta">3D Printed</div>
                                </div>
                                <div class="text-left" v-if="language=='fr'">
                                    Des produits entièrement personnalisables grâce à une sélection de couleurs, textures, matériaux, verres, etc., imaginés par des marques et designers internationaux.
                                </div>
                                <div class="text-left" v-else>
                                    Fully customisable products through a selection of colors, textures, materials, lenses…; imagined by international brands and designers.
                                </div>

                            <div class="beta-table-img-right contenumob">
                                <video muted playsinline loop autoplay>
                
                <source src="../../public/assets/beta1.mp4"
                                type="video/mp4">

                        Sorry, your browser doesn't support embedded videos.
            </video>
                            </div>

                            <div class="numbers">
                                <div @click="goDown(2)" @touchstart="goDown(2)" class="num active" >1</div>
                                <div  @click="goDown(3)" @touchstart="goDown(3)" class="num" >2</div>
                                <div @click="goDown(4)" @touchstart="goDown(4)" class="num" >3</div>
                            </div>

                        </div>
                        <div class="signup" @click="displaySubscribe=true;displayLoginForm=false;displayIntroBeta=false;"  v-if="language=='fr'">S'inscrire</div>
                        <div class="signup" @click="displaySubscribe=true;displayLoginForm=false;displayIntroBeta=false;" v-else>Sign up</div>
                    </div>
                    <div class="right">
                        <video muted playsinline loop autoplay>
                
                <source src="../../public/assets/beta1.mp4"
                                type="video/mp4">

                        Sorry, your browser doesn't support embedded videos.
            </video>
                        <div class="img">

                           
                            
                        </div>
                    </div>
                </div>

            </div> 
            <div v-if="displayIntroBeta==true" class="beta-screen screen2" id="beta3">

                <div class="beta-table">
                    <div class="left">
                        <div>
                        
                            
                                <div class="title" v-if="language=='fr'">L’ajustement parfait</div>
                                <div class="title" v-else>Perfect-fit experience</div>

                                <div class="elems-round-beta" v-if="language=='fr'">
                                    <div class="elem-round-beta">Technologie de scan facial</div>

                                
                                </div>
                                <div class="elems-round-beta" v-else>
                                    <div class="elem-round-beta">Face Scan Technology</div>

                                </div>
                                <div class="text-left" v-if="language=='fr'">
                                    Des lunettes parfaitement adaptées à votre visage grâce à un scan facial effectué à partir de votre smartphone, et une production à la demande avec un engagement zéro stock et zéro déchet.
                                </div>
                                <div class="text-left" v-else>
                                    
                                    Order eyewear tailored to your unique features using a scan from your smartphone, produced on-demand with a zero stock and zero waste commitment.
                                </div>

                        

                            <div class="beta-table-img-right contenumob">  <video muted playsinline loop autoplay>
                
                <source src="../../public/assets/beta2.mp4"
                                type="video/mp4">

                        Sorry, your browser doesn't support embedded videos.
            </video></div>
                            <div class="numbers">
                                <div @click="goDown(2)" @touchstart="goDown(2)" class="num " >1</div>
                                <div @click="goDown(3)" @touchstart="goDown(3)" class="num active" >2</div>
                                <div  @click="goDown(4)" @touchstart="goDown(4)" class="num" >3</div>
                            </div>

                        </div>
                        <div class="signup" @click="displaySubscribe=true;displayLoginForm=false;displayIntroBeta=false;"  v-if="language=='fr'">S'inscrire</div>
                        <div class="signup" @click="displaySubscribe=true;displayLoginForm=false;displayIntroBeta=false;" v-else>Sign up</div>
                    </div>
                    <div class="right">
                        <video muted playsinline loop autoplay>
                
                <source src="../../public/assets/beta2.mp4"
                                type="video/mp4">

                        Sorry, your browser doesn't support embedded videos.
            </video>
                        <div class="img">
                          
                            
                        </div>
                    </div>
                </div>

            </div> 
            <div v-if="displayIntroBeta==true" class="beta-screen screen2" id="beta4">

                <div class="beta-table">
                    <div class="left">
                        <div>
                        
                                <div class="title" v-if="language=='fr'">Influencez nos futures collections</div>
                                <div class="title" v-else>Influence future collections</div>

                                <div class="elems-round-beta" v-if="language=='fr'">
                                    <div class="elem-round-beta">Rejoignez la communauté</div>

                                </div>
                                <div class="elems-round-beta" v-else>
                                    <div class="elem-round-beta">Community Driven</div>

                                </div>
                                <div class="text-left" v-if="language=='fr'">
                                    En tant que BÊTA testeur, partagez vos retours pour orienter les futures collections ainsi que les améliorations produits et technologiques.
                                </div>
                                <div class="text-left" v-else>
                                    As a Beta tester, share your feedback to influence future collections, product, and technology upgrades.
                                </div>

                        

                            <div class="beta-table-img-right contenumob">  <video muted playsinline loop autoplay>
                
                <source src="../../public/assets/beta3.mp4"
                                type="video/mp4">

                        Sorry, your browser doesn't support embedded videos.
            </video></div>

                            <div class="numbers">
                                <div @click="goDown(2)" @touchstart="goDown(2)" class="num " >1</div>
                                <div @click="goDown(3)" @touchstart="goDown(3)" class="num " >2</div>
                                <div @click="goDown(4)" @touchstart="goDown(4)" class="num active" >3</div>
                            </div>
                        </div>
                        <div class="signup" @click="displaySubscribe=true;displayLoginForm=false;displayIntroBeta=false;"  v-if="language=='fr'">S'inscrire</div>
                        <div class="signup" @click="displaySubscribe=true;displayLoginForm=false;displayIntroBeta=false;" v-else>Sign up</div>
                    </div>
                    <div class="right">
                        <video muted playsinline loop autoplay>
                
                <source src="../../public/assets/beta3.mp4"
                                type="video/mp4">

                        Sorry, your browser doesn't support embedded videos.
            </video>
                        <div class="img">
                          

                        </div>
                    </div>
                </div>

            </div> 
        </div>
        
    </div>

    
    <!-- <div v-if="displayIntroBeta==true" class="beta-screen screen2" id="beta2">

        <div class="beta-table">
            <div class="left">
                <div>
                    <template v-if="currentNumberBeta==1">
                        <div class="title" v-if="language=='fr'">Accédez en avant-première et en exclusivité à des drops de lunettes imprimées en 3D</div>
                        <div class="title" v-else>GET EARLY & EXCLUSIVE ACCESS TO 3D PRINTED EYEWEAR DROPS</div>

                        <div class="elems-round-beta" v-if="language=='fr'">
                            <div class="elem-round-beta">Entièrement personnalisables</div>

                            <div class="elem-round-beta">Imprimées en 3D</div>
                        </div>
                        <div class="elems-round-beta" v-else>
                            <div class="elem-round-beta">Fully Customisable</div>

                            <div class="elem-round-beta">3D Printed</div>
                        </div>
                        <div class="text-left" v-if="language=='fr'">
                            Des produits entièrement personnalisables grâce à une sélection de couleurs, textures, matériaux, verres, etc., imaginés par des marques et designers internationaux.
                        </div>
                        <div class="text-left" v-else>
                            Fully customisable products through a selection of colors, textures, materials, lenses…; imagined by international brands and designers.
                        </div>

                    </template>
                    <template v-if="currentNumberBeta==2">
                        <div class="title" v-if="language=='fr'">L’ajustement parfait</div>
                        <div class="title" v-else>Perfect-fit experience</div>

                        <div class="elems-round-beta" v-if="language=='fr'">
                            <div class="elem-round-beta">Technologie de scan facial</div>

                        
                        </div>
                        <div class="elems-round-beta" v-else>
                            <div class="elem-round-beta">Face Scan Technology</div>

                        </div>
                        <div class="text-left" v-if="language=='fr'">
                            Des lunettes parfaitement adaptées à votre visage grâce à un scan facial effectué à partir de votre smartphone, et une production à la demande avec un engagement zéro stock et zéro déchet.
                        </div>
                        <div class="text-left" v-else>
                            
                            Order eyewear tailored to your unique features using a scan from your smartphone, produced on-demand with a zero stock and zero waste commitment.
                        </div>

                    </template>
                    <template v-if="currentNumberBeta==3">
                        <div class="title" v-if="language=='fr'">Influencez nos futures collections</div>
                        <div class="title" v-else>Influence future collections</div>

                        <div class="elems-round-beta" v-if="language=='fr'">
                            <div class="elem-round-beta">Rejoignez la communauté</div>

                        </div>
                        <div class="elems-round-beta" v-else>
                            <div class="elem-round-beta">Community Driven</div>

                        </div>
                        <div class="text-left" v-if="language=='fr'">
                            En tant que BÊTA testeur, partagez vos retours pour orienter les futures collections ainsi que les améliorations produits et technologiques.
                        </div>
                        <div class="text-left" v-else>
                            As a Beta tester, share your feedback to influence future collections, product, and technology upgrades.
                        </div>

                    </template>

                    <div class="beta-table-img-right contenumob"><div class="img"></div></div>

                    <div class="numbers">
                        <div @click="currentNumberBeta=1" class="num" :class="{active:currentNumberBeta==1}">1</div>
                        <div @click="currentNumberBeta=2" class="num" :class="{active:currentNumberBeta==2}">2</div>
                        <div @click="currentNumberBeta=3" class="num" :class="{active:currentNumberBeta==3}">3</div>
                    </div>

                </div>
                <div class="signup" @click="displaySubscribe=true;displayLoginForm=false;displayIntroBeta=false;"  v-if="language=='fr'">S'inscrire</div>
                <div class="signup" @click="displaySubscribe=true;displayLoginForm=false;displayIntroBeta=false;" v-else>Sign up</div>
            </div>
            <div class="right">
                <div class="img"></div>
            </div>
        </div>

    </div>  -->

    
    
    <Overlay :language="language" :like="isLiking" v-if="visible" :title="titleModal" :visible="visible" @remove="visible=false"/>

    <br /><br /><br /><br /><br /><br />
    <div class="collection-description" v-if="loaded">
        <CollectionBetaHeader :language="language" />
    </div>
    
    <CollectionMenu
    :beta="true"
    :language="language"
    :filterNumber="filterNumber"
    :loaded="loaded"
    :selectedFilter="selectedFilter"
    :barPosition="barPosition"
    :barWidth="barWidth"
    :displayGaming="displayGaming"
    :displayOptic="displayOptic"
    :displayBluelight="displayBluelight"

    @filterCollectionEv="filterCollection"
    />
    <!-- <CollectionFilters
    :loaded="loaded"
    :resultsFilter="resultsFilter"
    :selectedShapeFilter="selectedShapeFilter"
    :selectedSortFilter="selectedSortFilter"
    :selectedGenderFilter="selectedGenderFilter"
    :selectedColorFilter="selectedColorFilter"
    :selectedPriceFilter="selectedPriceFilter"
    :selectedMaterialFilter="selectedMaterialFilter"
    :selectedNoseFilter="selectedNoseFilter"

    @displayPopupFilterEv="dispayPopupFilter"
    /> -->
    <br /><br />
    <div class="contenumob select-column">

        <img v-if="columns==1" src="../../public/assets/images/col1-1.png" height="12" width="12" @click="columns=1"  />  
        <img v-else src="../../public/assets/images/col1-2.png" width="12" @click="columns=1"  />  

        <img  v-if="columns==2" @click="columns=2" src="../../public/assets/images/col2-1.png" width="12" /> 
        <img  v-else @click="columns=2" src="../../public/assets/images/col2-2.png" width="12" /> 
        <br /><br />
    </div>
    <div class="collection home-section-new">
        <!-- <content-loader v-if="loaded==false"
            viewBox="0 0 520 200"
            primaryColor="#f3f3f3"
            secondaryColor="#cccccc"
            >
            <rect x="20" y="0" rx="0" ry="0" width="150" height="150" />
            <rect x="20" y="160" rx="3" ry="3" width="150" height="6" />
            <rect x="180" y="0" rx="0" ry="0" width="150" height="150" />
            <rect x="180" y="160" rx="3" ry="3" width="150" height="6" />
            <rect x="350" y="0" rx="0" ry="0" width="150" height="150" />
            <rect x="350" y="160" rx="3" ry="3" width="150" height="6" />
        </content-loader> -->
        <div class="products" v-if="loaded" :class="{cols2:columns==2}">

            <CollectionProduct 
                v-if="selectedFilter!='community'"
                :language="language"
                :glasses="glasses"
                :slider="true"
                :selectedFilter="selectedFilter"
                :community="community"
                :beta="true"
                :selectedColorFilter="selectedColorFilter"
                :betaAccess="betaAccess"
                @likePhotoEv="likePhoto"
            />
            <template v-else>
                <!-- <div class="carousel-cell product product-favorite" v-for="(favorite, index) in glasses" :key="favorite.id">

                <a @click="$router.push({ name: 'collection-glass', params:{id:favorite.glass.id, favorite:favorite.id} })" ></a>
                
                <CartImage :cart_item="favorite" />
                <div class="personnalisation-actions">
                    <div v-if="language=='fr'" class="colors-count">{{ favorite.glass.colors.length }} <span class="pls">+</span> couleurs</div>
                    <div v-else class="colors-count">{{ favorite.glass.colors.length }} <span class="pls">+</span> colors</div>
                    <div  v-if="language=='fr'" class="personnalisation-button">Personnaliser</div>
                    <div  v-else  class="personnalisation-button">Customize</div>
                </div>
               

                <div class="bottom">
                    <span class="product-title">{{ favorite.glass.name }}</span>
                   <span class="product-price">{{ favorite.amount }}€</span> 

                </div>
                
             
            </div> -->
            
            <CollectionProductCommunity
                :beta="false"
                :language="language"
                :favorites="glasses"
                :slider="true"
                @likePhotoEv="likePhoto"
            />
            </template>

        </div>
    </div>
    <br /><br /><br /><br />
    <Footer :language="language" :displayModalMenu="displayModalMenu" />
    <!-- <PopupFilter
        v-if="displayFilters" 

        :resultsFilter="resultsFilter"
        :selectedFilter="selectedFilter"
        :selectedShapeFilter="selectedShapeFilter"
        :selectedSortFilter="selectedSortFilter"
        :selectedGenderFilter="selectedGenderFilter"
        :selectedColorFilter="selectedColorFilter"
        :selectedPriceFilter="selectedPriceFilter"
        :selectedMaterialFilter="selectedMaterialFilter"
        :selectedNoseFilter="selectedNoseFilter"
        :displayGaming="displayGaming"
        :displayOptic="displayOptic"
        :displayBluelight="displayBluelight"

        @filterCollectionEv="filterCollection"
        @filterShapeEv="filterShape"
        @filterSortEv="filterSort"
        @filterGenderEv="filterGender"
        @filterColorEv="filterColor"
        @filterNoseEv="filterNose"
        @filterMaterialEv="filterMaterial"
        @filterPriceEv="filterPrice"

        @hidePopupFilterEv="hidePopupFilter" /> -->
  </div>
</template>

<script>
import Footer from '../components/layout/Footer.vue'
import jQuery  from 'jquery';
import ScrollMagic  from 'ScrollMagic';
import emitter from 'tiny-emitter/instance'
import Flickity from 'vue-flickity';
import {APIRequest} from "@simb2s/senseye-sdk"
import { ContentLoader } from 'vue-content-loader'
// Import children
import CollectionBetaHeader from '../components/children/Collection/CollectionBetaHeaderComponent.vue';
import CollectionMenu from "../components/children/Collection/CollectionMenuComponent.vue";
import CollectionProduct from '../components/children/Collection/CollectionProductComponent.vue';
import CollectionProductCommunity from '../components/children/Collection/CollectionProductCommunityComponent.vue';
import CollectionFilters from "../components/children/Collection/CollectionFiltersComponent.vue";
import PopupFilter from '../components/popup/PopupFilter.vue';
import Overlay from "../components/overlay/OverlayComponent.vue"
import CartImage from "../components/children/Cart/CartImageComponent.vue";

import { useMeta } from 'vue-meta'
import { useSettingsStore } from '@/store/SettingsStore.js';
const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;




export default {
    name: 'CollectionBeta',
    components: {
        Footer,
        Flickity,
        ContentLoader,
        CollectionBetaHeader,
        CollectionProductCommunity,
        CollectionMenu,
        CartImage,
        CollectionProduct,
        PopupFilter,
        Overlay,
        CollectionFilters
    },
    data() {
        return {
            useSettings:useSettingsStore(),
            visible:false,
            betaAccess:false,
            displayBetaAccessPopup:false,
            loading:false,
            isLiking:false,
            controller:null,
            scene:null,
            scrolling: 0,
            scrollFlag : 1,
            user_name:'',
            filterNumber:1,
            transitioning:false,
            displayNoneStatus:false,
            displayIntroBeta:true,
            displaySubscribe:false,
            displayLoginForm:false,
            pending_message:false,
            titleModal:'',
            barWidth:33,
            displayOptic:true,
            displayGaming:false,
            displayBluelight:false,
            loaded:false,
            email_login:'',
            password_login:'',
            lastScrollTop:0,
            displayFilters:false,
            resultsFilter:0,
            touchstartY:0,
            currentNumberBeta:1,
            activeSlideBeta:0,
            barPosition:0,
            glasses:[],
            community:false,
            success_subscribe:false,
            message_suscribtion:'',
            surname_suscribtion:'',
            name_suscribtion:'',
            password_suscribtion:'',
            repeat_password_suscribtion:'',
            email_suscribtion:'',
            controller: null,
            error_subscribe:null,
            error_login:null,
            tween: null,
            columns:1,
            scene: null,
            email:null,
            currentOption:'colors',
            showModal:false,
            currentImage:'',
            currentColor:'color0',
            showDevices:false,
            selectedFilter:'solar',
            selectedShapeFilter:'all',
            selectedSortFilter:'default',
            selectedGenderFilter:'unisex',
            selectedColorFilter:'all',
            selectedNoseFilter:'all',
            selectedPriceFilter:'all',
            selectedMaterialFilter:'all',
            flickityOptions: {
                cellAlign: 'center',
                prevNextButtons: false,
                contain: true,
                resultsFilter:0,
                
            }
        }
    },
    props:["displayModalMenu","language"],
    computed:{

        filterWatch: function () {
             return [this.selectedSortFilter ,this.selectedFilter, this.selectedShapeFilter, this.selectedGenderFilter, this.selectedColorFilter, this.selectedNoseFilter, this.selectedPriceFilter,  this.selectedMaterialFilter]
        }
    },
    
    watch: {
        $route : async function(to, from){

          
            this.loaded=false
           
            if(this.$route.params.toggle) {
                if(this.$route.params.toggle=="optic") {
                    this.filterCollection('optic')
                } else if(this.$route.params.toggle=="solar") {
                    this.filterCollection('solar')
                }
                else if(this.$route.params.toggle=="gaming") {
                    this.filterCollection('gaming')
                }
                else if(this.$route.params.toggle=="bluelight") {
                    this.filterCollection('bluelight')
                }
                else if(this.$route.params.toggle=="community") {
                    this.filterCollection('community')
                }
            }
            else {
                this.filterCollection('solar')
            }
            await this.getGlasses();

            this.loaded=true
        },
        filterWatch:async function(){
            if(this.selectedFilter=='community')
            {
                this.community=true
            }
            else {
                this.community=false
            }
            // console.log('filter changed')
            // await this.getFilteredGlasses(this.selectedColorFilter,this.selectedGenderFilter,this.selectedShapeFilter,this.selectedPriceFilter,this.selectedMaterialFilter,this.selectedNoseFilter)
        },
        '$route.params.toggle':function(toggle){
            if(toggle=="optique") {
                this.filterCollection('optic')
            } else if(toggle=="solar") {
                this.filterCollection('solar')
            }
            else if(toggle=="community") {
                
                    this.filterCollection('community')
                }
        }
           
    },
    methods:{
        scrollToStep2() {
            jQuery('#betacontainer').animate({
                scrollTop: jQuery(window).height()*2.5
            }, 500); 
        },
        scrollToStep1() {
            jQuery('#betacontainer').animate({
                scrollTop: jQuery(window).height()*1.5
            }, 500); 
        },
        showModall(title) {
            this.visible = true
           this.isLiking=false
            this.titleModal = title;
        },
        async userLogin (user) {

                
            if(user!==null)
            {
                let status = await APIRequest.check_status_beta(this.email_login)

                if(status.status=="pending" || status.status==false )
                {
                
                    this.pending_message=true
                    this.displayIntroBeta=false
                    this.displaySubscribe=false

                    this.displayLoginForm=true
                    this.error_login=null
                    this.displayNoneStatus=false
                }
                else if(status.status=="ok"  || status.status==true)
                {
                    this.loaded=false
                    jQuery('body').css('overflow-y','auto');
                    this.$emit('changeBetaAccessEv', true)
                    await this.getGlasses()


                    this.user_name=this.email_login
                
                    this.displayBetaAccessPopup=true
                    this.error_login=null
                    this.loaded=true
                }
                else
                {
                    this.displayIntroBeta=false
                    this.pending_message=false
                    this.displayLoginForm=false
                    this.displaySubscribe=false
                    this.displayNoneStatus=true
                    //this.error_login="Vous n'avez pas fait de demande avec cette adresse mail."
                }
            
            }
            else {
                if(this.language=='fr')
                {
                    this.showModall("Vos identifiants sont incorrects !");
                }
                else
                {
                    this.showModall("Incorrect logins!");
                }
                this.loading=false
                return
            
            }

            },
       
        async checkBeta(){
            this.loading=true
            
            if(this.email_login!="")
            {
                if(!this.email_login.match(regexEmail)){
                    
                    if(this.language=='fr')
                    {
                        this.showModall("Format email incorrect !");
                    }
                    else
                    {
                        this.showModall("Incorrect email format!");
                    }
                    this.loading=false
                    return
                }

                await emitter.emit('login',this.email_login,this.password_login)

                
               
            }
            else 
            {
                if(this.language=='fr')
                    {
                        this.error_login="Tous les champs (*) sont obligatoires !"
                    }
                    else
                    {
                        this.error_login="All fields are required (*)"
                    }
                
            }
            this.loading=false
        },
        async subscribeBeta(){

     

            this.loading=true

            if(this.email_suscribtion!="" && this.surname_suscribtion!="" && this.name_suscribtion!=""  && this.repeat_password_suscribtion!="" && this.password_suscribtion!="")
            {
                if(!this.email_suscribtion.match(regexEmail)){
                    
                    if(this.language=='fr')
                    {
                        this.showModall("Format email incorrect !");
                    }
                    else
                    {
                        this.showModall("Incorrect email format!");
                    }
                    this.loading=false
                    
                    return
                }

                if(this.repeat_password_suscribtion!=this.password_suscribtion)
                {
                    if(this.language=='fr')
                    {
                        this.showModall("Les deux mots de passe doivent être identiques !");
                    }
                    else
                    {
                        this.showModall("The two passwords must be identical!");
                    }
                    this.loading=false
                    
                    return
                }
                
                
              
            }
            else 
            {



                if(this.language=='fr')
                {
                    this.error_subscribe="Tous les champs (*) sont obligatoires !"
                }
                else
                {
                    this.error_subscribe="All fields are required (*)"
                }
                
            }
            this.loading=false
            
        },
        displayLogin(liking, id) {
            if (liking == true) {

                this.$emit('displayPopupLoginEv', {review:false,saving:false,customing:false,liking:true,likingId:id})
              
                //emitter.emit('display-popup-login', false, false, false, true, id)
            }
            else {
                this.$emit('displayPopupLoginEv', {review:false,saving:false,customing:false,liking:false,likingId:null})
               // emitter.emit('display-popup-login')
            }
        },

        showOverlay(title){
            this.isLiking=true
            this.visible = true;
            this.titleModal = title;
        },
        async likePhoto(liked,like_id,glass_index)
        {
            if(liked==false)
            {
                
                if(localStorage.id_user_senseye_auth)
                {
                    if(localStorage.id_user_senseye_auth!=null && localStorage.id_user_senseye_auth!='')
                    {
                        let data={}
                        data.user={}
                        data.glass={}
                        data.glass.id=this.glasses[glass_index].id
                        data.user.id=parseInt(localStorage.id_user_senseye_auth)
                        await APIRequest.create('likes',data)
                    }
                    this.glasses[glass_index].liked=true;

                    if(this.language=='fr')
                    {
                        this.showOverlay('Produit ajouté aux favoris !')
                    }
                    else
                    {
                        this.showOverlay('Product added to favorites!')
                    }

                    
                    
                }
                else{
                    this.displayLogin(true,this.glasses[glass_index].id)
                }
                
            }
            else
            {
                if(like_id!=null)
                {
                    await APIRequest.delete('likes',like_id)
                }
                this.glasses[glass_index].liked=false;
            }
        },
        filterPrice(filter)
        {
            this.selectedPriceFilter=filter
        },
        filterMaterial(filter)
        {
            this.selectedMaterialFilter=filter
        },
        filterNose(filter)
        {
            this.selectedNoseFilter=filter
        },
        filterShape(filter)
        {
            this.selectedShapeFilter=filter
        },
        filterSort(filter)
        {
            this.selectedSortFilter=filter
        },
        filterGender(filter)
        {
            this.selectedGenderFilter=filter
        },
        filterColor(filter)
        {
            this.selectedColorFilter=filter
        },
      
        async filterCollection(filter)
        {
            this.loaded=false
            // this.filterSort("default")
            // this.filterShape("all")
            // this.filterGender('unisex')
            // this.filterColor("all")
            // //this.filterCollection('solar')
            // this.filterPrice("all")
            // this.filterMaterial("all")
            // this.filterNose("all")

            this.selectedFilter=filter

            if(filter=='solar')
            {
                this.barPosition=0;
                
            }
            else if(filter=="optic")
            {
               
                this.barPosition=33;
                
            }
            else if(filter=="community")
            {
               
                this.barPosition=66;
                
            }

            await this.getGlasses();

            this.loaded=true
            
            
        },
        closeModal($event)
        {
             if (jQuery($event.target).parents('.content').length) {

        }
            else
            {
                this.showModal=false
            }
        },
        // async getFilteredGlasses(color,gender,shape,price,material,nose)  {

        //     let request = "?type=collection"
          
        //     if(shape!='all') {
        //         if(request=="") {
        //             request += "?shape=" + shape
        //         }
        //         else {
        //             request += "&shape=" + shape
        //         }
        //     }
        //     if(gender!='all') {
        //         if(request=="") {
        //             request += "?gender=" + gender
        //         }
        //         else {
        //             request += "&gender=" + gender
        //         }
        //     }
        //     if(nose!='all') {
        //         if(request=="") {
        //             request += "?nose=" + nose
        //         }
        //         else {
        //             request += "&nose=" + nose
        //         }
        //     }

        //     if(localStorage.id_user_senseye_auth!=null && localStorage.id_user_senseye_auth!='')
        //     {
        //         request += "&user=" + localStorage.id_user_senseye_auth
        //     }
            

        //     let glasses = await APIRequest.list_with_request('glasses', request)
        //     let glasses_results=glasses[0]
        //     if(color!="all") {
               
        //         let glasses_filtered = []
        //         for(let i=0;i<glasses_results.length;i++) {
                    
        //             let has_color=false
        //             if(glasses_results[i].colors != null) {
        //                 let request = "?glass=" + glasses_results[i].id
        //                 let colors = await APIRequest.list_with_request('colors', request)
        //                 for(let j=0;j<colors[0].length;j++) {
        //                     if(parseInt(colors[0][j].id)==parseInt(color)) {
        //                         has_color=true
        //                     }
        //                 }
        //             }
        //             if(has_color==true) {
        //                 glasses_filtered.push(glasses_results[i])
        //             }
        //         }
        //         glasses_results = glasses_filtered
        //     }
        //     if(material!="all") {
        //         let glasses_filtered = []
        //         for(let i=0;i<glasses_results.length;i++) {
        //             let has_material=false
        //             let request = "?glass=" + glasses_results[i].id
        //             let branches = await APIRequest.list_with_request('branches', request)
                
        //             if(branches[0] != null) {
        //                 for(let j=0;j<branches[0].length;j++) {
        //                     if(branches[0][j].type==material) {
        //                         has_material=true
        //                     }
        //                 }
        //             }
        //             if(has_material==true) {
        //                 glasses_filtered.push(glasses_results[i])
        //             }
        //         }
        //         glasses_results = glasses_filtered
        //     }
        //     if(price!="all") {
        //         let glasses_filtered = []
        //         for(let i=0;i<glasses_results.length;i++) {
        //             if(glasses_results[i].price<=price) {
        //                 glasses_filtered.push(glasses_results[i])
        //             }
        //         }
        //         glasses_results = glasses_filtered
        //     }
            
        //     this.glasses=glasses_results
        //     this.resultsFilter=glasses_results.length
        //     this.photos=[]
            

        // },
        async getGlasses()  {

            let glasses
            if(this.selectedFilter=='community')
            {
                glasses=await APIRequest.list_with_request('favorites','?community=1')
                let favs=glasses[0]
                for (let i = 0; i < favs.length; i++) {
                    
                    
                    favs[i].glass=favs[i].order_item_customizations[0].model_part.glass


                    for (let j = 0; j < favs[i].order_item_customizations.length; j++) {
                        if(favs[i].order_item_customizations[j].tint)
                        {
                            favs[i].tint=favs[i].order_item_customizations[j].tint
                        }
                        //favs[i].glass=favs[i].order_item_customizations[0].model_part.glass
                    }
                    
                
                }
                this.glasses = favs
            }
            else
            {
                glasses=await APIRequest.list_with_request('glasses',"?beta_collection=1&active=1&order=asc")
                this.glasses=glasses[0]
            }

            
            this.resultsFilter=this.glasses.length
           
        },
        changeNewsletterEmail(email)
        {
            this.email=email
        },
        chooseOption(option)
        {
            this.currentOption=option
            this.currentColor='color1'
        },
        displayModal($event)
        {
            this.currentImage=jQuery($event.target).attr('src');
            this.showModal=true;
        },
        chooseColor(color)
        {
            this.currentColor=color
        },
        dispayPopupFilter(shapes=false) {
            this.displayFilters=true
           
        },
        hidePopupFilter() {
            this.displayFilters=false
        },
        handleScroll(e){
            
            
            
            if(e.target.scrollTop>=jQuery(window).height() && e.target.scrollTop<jQuery(window).height()*2)
            {
                jQuery('#beta1').removeClass('hide')
                jQuery('#beta1').addClass('display')
            }
            else
            {
                
                jQuery('#beta1').removeClass('display')
                jQuery('#beta1').addClass('hide')
                
                // setTimeout(() => {
                //     jQuery('#beta1').css('opacity','0')
                //     jQuery('#beta1').css('transform','translateY(150px) scale(1.5)')
                // }, 800);
                
            }

            if(e.target.scrollTop>=jQuery(window).height()*2 && e.target.scrollTop<jQuery(window).height()*3)
            {
                jQuery('#beta2').removeClass('hide')
                jQuery('#beta2').addClass('display')
                
            }
            else
            {
                jQuery('#beta2').removeClass('display')
                jQuery('#beta2').addClass('hide')
                
                
            }

            if(e.target.scrollTop>=jQuery(window).height()*3 && e.target.scrollTop<jQuery(window).height()*4)
            {
                jQuery('#beta3').removeClass('hide')
                jQuery('#beta3').addClass('display')
            }
            else
            {
                jQuery('#beta3').removeClass('display')
                jQuery('#beta3').addClass('hide')
                
            }

            if(e.target.scrollTop>=jQuery(window).height()*4)
            {
                jQuery('#beta4').removeClass('hide')
                jQuery('#beta4').addClass('display')
            }
            else
            {
                jQuery('#beta4').removeClass('display')
                jQuery('#beta4').addClass('hide')
                
                
            }
        },
        scrollToSlide(slide)
        {
            let scrollNum=slide*(-100)
         
            this.scrolling = scrollNum
            document.getElementById(
            "betacontainer"
            ).style.transform = `translateY(${this.scrolling}vh)`;

            let slidenum=this.scrolling/100*-1
            if(this.scrolling==0)
            {
                slidenum=0;
            }



            jQuery('#beta'+(slidenum+1)).removeClass('display')
            jQuery('#beta'+(slidenum+1)).addClass('hide')


            jQuery('#beta'+(slidenum-1)).removeClass('display')
            jQuery('#beta'+(slidenum-1)).addClass('hide')

            jQuery('#beta'+slidenum).removeClass('hide')
            jQuery('#beta'+slidenum).addClass('display')

              
        },
      
        swipeEvent(e){
            
           
            let touchendY = e.changedTouches[0].screenY;

            if(touchendY<this.touchstartY)
            {
                if(this.touchstartY-touchendY>10)
                {
                    
                    if (this.scrolling > -400) {
                        this.scrolling -= 100;

                        document.getElementById(
                        "betacontainer"
                        ).style.transform = `translateY(${this.scrolling}vh)`;

                        let slidenum=this.scrolling/100*-1
                        if(this.scrolling==0)
                        {
                            slidenum=0;
                        }
                        
                     

                        jQuery('#beta'+(slidenum-1)).removeClass('display')
                        jQuery('#beta'+(slidenum-1)).addClass('hide')

                        jQuery('#beta'+slidenum).removeClass('hide')
                        jQuery('#beta'+slidenum).addClass('display')

                    }

                }
               
            }
            else
            {
                if(touchendY-this.touchstartY>10)
                {
                   

               

                if (this.scrolling !== 0) {
                  
                    this.scrolling += 100;
                    document.getElementById(
                    "betacontainer"
                    ).style.transform = `translateY(${this.scrolling}vh)`;

                    let slidenum=this.scrolling/100*-1
                    if(this.scrolling==0)
                    {
                        slidenum=0;
                    }



                    jQuery('#beta'+(slidenum+1)).removeClass('display')
                    jQuery('#beta'+(slidenum+1)).addClass('hide')


                    jQuery('#beta'+slidenum).removeClass('hide')
                    jQuery('#beta'+slidenum).addClass('display')

               
                }
            }
            }
            

          
        },
        scrollEvent(event){
           
            if (event.deltaY < 0) {

                // UP

          
            if (this.scrolling !== 0) {
               
                this.scrolling += 100;
                document.getElementById(
                "betacontainer"
                ).style.transform = `translateY(${this.scrolling}vh)`;

                let slidenum=this.scrolling/100*-1
                if(this.scrolling==0)
                {
                    slidenum=0;
                }



                jQuery('#beta'+(slidenum+1)).removeClass('display')
                jQuery('#beta'+(slidenum+1)).addClass('hide')


                jQuery('#beta'+slidenum).removeClass('hide')
                jQuery('#beta'+slidenum).addClass('display')

               
            }
            } else if (event.deltaY > 0) {

                // DOWN

            if (this.scrolling > -400) {
                this.scrolling -= 100;

                document.getElementById(
                "betacontainer"
                ).style.transform = `translateY(${this.scrolling}vh)`;

                let slidenum=this.scrolling/100*-1
                if(this.scrolling==0)
                {
                    slidenum=0;
                }
                
              

                jQuery('#beta'+(slidenum-1)).removeClass('display')
                jQuery('#beta'+(slidenum-1)).addClass('hide')

                jQuery('#beta'+slidenum).removeClass('hide')
                jQuery('#beta'+slidenum).addClass('display')

            }

            }
      },
        updateScrollTop(num)
        {
            this.lastScrollTop=num
        },
        displayDevices()
        {
            if(this.showDevices==true)
            {
                this.showDevices=false
            }
            else
            {
                this.showDevices=true
            }
        },
        goDown(slide)
        {
            if(this.scrollFlag === 1){
                setTimeout(()=>{
                    this.scrollToSlide(slide)
                    this.scrollFlag = 1;
                },1000)
                this.scrollFlag = 0;
            }
        },
        handleWheel(event)
        {
            if(this.displayIntroBeta==true)
            {
                if(this.scrollFlag === 1){
                setTimeout(()=>{
                        this.scrollEvent(event)
                        this.scrollFlag = 1;
                    },1000)
                    this.scrollFlag = 0;
                }

            }
            
        },
        handleTouchStart(e)
        {
            this.touchstartY = e.changedTouches[0].screenY;
        },
        handleTouchEnd(e)
        {
            
            if(this.displayIntroBeta==true)
            {
                if(this.scrollFlag === 1){
                setTimeout(()=>{
                        this.swipeEvent(e)
                        this.scrollFlag = 1;
                    },1000)
                    this.scrollFlag = 0;
                }

            }

            
        }
    },
    beforeUnmount() {
      
            jQuery('body').css('overflow-y','auto');
        
    },
    created() {

        useMeta({
    title: "VISAGES - Collection BETA",
    
    htmlAttrs: { lang: this.language, amp: true },

})
    

        
        if(this.language=='fr')
        {
            document.querySelector('meta[name="description"]').setAttribute('content',"Chaque monture de notre Collection optique et solaire est personnalisable. Toutes nos paires de lunettes sont produites à l'unité par impression 3D, en harmonie parfaite avec la morphologie de votre visage.")
        }
        else {
            document.querySelector('meta[name="description"]').setAttribute('content',"Every frame in our Collection is customisable. Visages ensures that each pair of glasses is unique and 3D printed on-demand, in perfect harmony with the contours of your face.")
        }
    },

    async mounted()
    {
        

        if(this.betaAccess==false)
        {


            jQuery('body').css('overflow-y','hidden');


      window.addEventListener('wheel', this.handleWheel);

      window.addEventListener('touchstart', this.handleTouchStart);
      window.addEventListener('touchend', this.handleTouchEnd);
     
      

    //   window.addEventListener("wheel", function (event) {
        
    //   });
      
            //document.getElementById('betacontainer').addEventListener('scroll', this.handleScroll);

            // var _this=this
            
            // jQuery(document).ready(function(){
               
            //     jQuery( '#betacontainer' ).on( "scroll", function(e) {


                    
                    
            //     } );

            // });
             

            // // init
            // this.controller = new ScrollMagic.Controller({
                
            // });

            // this.scene = new ScrollMagic.Scene({triggerElement: "#trigger1", duration: window.innerHeight})
			// 					.setPin("#beta1")
			// 					.addIndicators({name: "1 (duration: 300)"}) // add indicators (requires plugin)
			// 					.addTo(this.controller);

            // // get all slides
            // var slides = document.querySelectorAll("div.beta-screen");

            // // create scene for every slide
            // for (var i=0; i<slides.length; i++) {
            //     new ScrollMagic.Scene({
            //             triggerElement: slides[i]
            //         })
            //         .setPin(slides[i], {pushFollowers: false})
            //         .addIndicators() // add indicators (requires plugin)
            //         .addTo(this.controller);
            // }


            // var tween = new TimelineMax ()
            //     .add([
            //         TweenMax.to("#image-content", 1, {backgroundPosition: "center -40%"}),
            
            //     ]);

            // // build scene
            // this.scene = new ScrollMagic.Scene({triggerElement: "#trigger1", duration: window.innerHeight})
            // .setTween(tween)
            
            // .addTo(this.controller);


        }
        else
        {
            jQuery('body').css('overflow-y','auto');
        }

        if(this.useSettings.settingsLoaded==false) {
            await this.useSettings.loadSettings()
        }
        
        if(this.useSettings.displayOptic==true) {
            this.displayOptic=true;
        }
        if(this.useSettings.displayBluelight==true) {
            this.displayBluelight=false;
        }
        if(this.useSettings.displayGaming==true) {
            this.displayGaming=true;
        }

        if(this.$route.params.lang)
        {
        this.$emit('changeLanguageEv', this.$route.params.lang)
        
        }

        if(this.$route.params.toggle) {
            if(this.$route.params.toggle=="optic") {
                this.filterCollection('optic')
            } else if(this.$route.params.toggle=="solar") {
                this.filterCollection('solar')
            }
            else if(this.$route.params.toggle=="gaming") {
                this.filterCollection('gaming')
            }
            else if(this.$route.params.toggle=="bluelight") {
                this.filterCollection('bluelight')
            }
            else if(this.$route.params.toggle=="community") {
                this.filterCollection('community')
            }
        }
        else {
            this.filterCollection('solar')
        }
        if(this.betaAccess==true)
        {
            await this.getGlasses();
        }
        
      
        emitter.on('changeNewsletterEmail', function (email) {
        this.changeNewsletterEmail(email)
        }, this);

        emitter.on('reload-glasses-like', async function (likeId) {
          
            if(localStorage.id_user_senseye_auth!=null && localStorage.id_user_senseye_auth!='')
            {
                let data={}
                data.user={}
                data.glass={}
                data.glass.id=likeId
                data.user.id=parseInt(localStorage.id_user_senseye_auth)
                await APIRequest.create('likes',data)
            }
            
            
            await this.getGlasses();
            if(this.language=='fr')
                    {
                        this.showOverlay('Produit ajouté aux favoris !')
                    }
                    else
                    {
                        this.showOverlay('Product added to favorites!')
                    }
        }, this);
        
        if(this.displayOptic==true && this.displayGaming==true && this.displayBluelight==true)
        {
            this.filterNumber=4
        }
        else if((this.displayOptic==true && this.displayGaming==true) || (this.displayGaming==true && this.displayBluelight==true) || (this.displayOptic==true && this.displayBluelight==true))
        {
            this.filterNumber=3
        }
        else if(this.displayOptic==true || this.displayGaming==true || this.displayBluelight==true)
        {
            this.filterNumber=2
        }

        if(this.betaAccess==true)
        {



            this.loaded=true

        }

        

    }
}
</script>

<style>
.little-legend
{
    font-size:10px;
}
.beta-table .signup
{
    cursor: pointer;
    display: block;
    margin-right: auto;
    text-transform: none;
    font-size: 16px;
    border-radius: 16px;
    font-weight: 600;
    background: #fff;
    border: 1px solid #FFFFFF;
    color: #000;
    padding: 11px 60px;
   
}
.beta-table
{
   
    min-height:40vw;
   
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width:90%;
    display:flex;
    padding-left:5%;
    padding-right:5%;
}
.beta-table .right video {
    position:absolute;
    top:50%;
    z-index: 1;
    width:42vw;
    border-radius: 20px;
    height:auto;
    left:50%;
    transform: translate(-50%,-50%);
   
}
.beta-table .right
{
    position:relative;
    background:#393939;
    width:50%;
    overflow: hidden;
    border-radius: 20px;
   
}
.beta-table .left .text-left
{
    font-size:25px;
    margin-top:60px;
}
.beta-table .left .elems-round-beta
{
    width:100%;
    margin-top:30px;
    display: flex;
}
.beta-table .left .elem-round-beta
{
    border-radius: 50px;
    font-size:21px;
    padding-left:20px;
    padding-right:20px;
    padding-bottom:3px;
    border:1px solid #fff;
    margin-right:10px;
}
.beta-table .left .title
{
    font-size:35px;
    line-height:0.9;
    font-weight:600;
    text-transform: uppercase;
    margin-top:10px;
}
.beta-table .numbers
{
    margin-top:60px;
    display:flex;
}
.beta-table .numbers .num.active 
{
    background:#fff;
    color:#000;
   
}
.beta-table .numbers .num
{
    width:35px;
    height:35px;
    cursor: pointer;
    font-size:18px;    
    margin-right:10px;
    line-height: 1.8;
    transition: all 0.3s;
    text-align: center;
    border:1px solid white;
    border-radius:50%;
}
.beta-table .left
{
    width:50%;
    padding-right:5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.content-scren .visages
{
    font-size:30px;
    margin-top:30px;
}
.content-scren .text-big .u
{
    text-decoration: underline;
}
.content-scren .text-big
{
    font-size:50px;
    line-height: 0.9;
}
.bottom-screen img
{
   margin-left:auto;
   margin-right:auto;
   filter: brightness(0) invert(1);
}
.bottom-screen
{
    cursor: pointer;
    font-size:16px;
    text-decoration: underline;
}
.bottom-screen
{
    position:absolute;
    bottom:100px;
    left:50%;
    text-align: center;
    transform: translateX(-50%);
}
.content-scren
{
    text-align: center;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    min-width:75%;
}
.beta-screen-container
{
    height:auto;
    width:100%;
    position:relative;
    top:0;
    left:0;
    transition: all 1.5s ease;
}
#beta3
{
   

    transition:all 0.8s ease-in-out;
    
}
#beta4
{
   

    transition:all 0.8s ease-in-out;
    
}
#beta2
{
   

    transition:all 0.8s ease-in-out;
    
}
@keyframes hideanim
{
    0%{
        
        
    }
    80%{
       
    }
    100%{
        
    }
}
@keyframes displayanim2
{
    0%{
        opacity:0;
       
    }
    25%
    {
        opacity:0;
    }
    
    100%{
        opacity:1;
        
    }
}
@keyframes displayanim
{
    0%{
        opacity:0;
        
    }
    
    100%{
        opacity:1;
       
    }
}

@keyframes displayanim3
{
    0%{
        opacity:0;
      
    }
    
    100%{
        opacity:1;
       
    }
}



#beta4.display .beta-table .signup
{
    opacity:1;
    animation: displayanim2 1.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) ;
}
#beta4 .beta-table .signup
{
    opacity:0;
}
#beta4 .beta-table .left
{
    opacity:0;
  
}

#beta4.display .beta-table .left
{
    opacity:1;
    
    animation: displayanim3 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) ;
}



#beta3.display .beta-table .signup
{
    opacity:1;
    animation: displayanim2 1.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) ;
}
#beta3 .beta-table .signup
{
    opacity:0;
}
#beta3 .beta-table .left
{
    opacity:0;
    
}

#beta3.display .beta-table .left
{
    opacity:1;
   
    animation: displayanim3 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) ;
}


#beta2.display .beta-table .signup
{
    opacity:1;
    animation: displayanim2 1.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) ;
}
#beta2 .beta-table .signup
{
    opacity:0;
}
#beta2 .beta-table .left
{
    opacity:0;
   
}

#beta2.display .beta-table .left
{
    opacity:1;
 
    animation: displayanim3 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) ;
}

#beta1 .visages
{
    opacity:0;
  
    
}
#beta1.display .visages
{
    opacity:1;
   

    animation: displayanim3 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) ;
}


#beta1 .bottom-screen
{
    opacity:0;
    
}
#beta1.display .bottom-screen
{

    opacity:1;
    animation: displayanim2 1.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) ;
}
#beta1.hide
{
    transition: all 0.8s ease-in-out;
    animation: hideanim 1s;
    opacity:0;
   
}
#beta1.display
{
    z-index: 104 !important;
    opacity:1;
    
    animation: displayanim 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) ;
}
#beta2.hide
{
    transition: all 0.8s ease-in-out;
    animation: hideanim 1s;
    opacity:0;
   
}
#beta2.display
{
    z-index: 104 !important;
    opacity:1;
  
}
#beta3.hide
{
    transition: all 0.8s ease-in-out;
    animation: hideanim 1s;
    opacity:0;
  
}
#beta3.display
{
    z-index: 104 !important;
    opacity:1;
   
}
#beta4.hide
{
    transition: all 0.8s ease-in-out;
    animation: hideanim 1s;
    opacity:0;
   
}
#beta4.display
{
    z-index: 104 !important;
    opacity:1;
   
}
#beta1
{
  

    
    
}
.beta-screen
{
    min-height: 100vh;
    color:#fff;
    width:100%;
    height:100vh;
    opacity:0;
    top:0;
    left:0;
    
    position: relative;
}
.collection.home-section-new .products .product {
    margin-bottom:150px;
}
.collection.home-section-new .products {
    display: grid;
     grid-template-columns: repeat(auto-fill, 415px);
     justify-content: center;
     margin-left:auto;
     margin-right:auto;
   
}

.collection.home-section-new
{
    padding-left:5%;
    text-align: center;
    padding-right:5%;
}
.colors-count .pls
{
background: linear-gradient(80deg, #95B4F2 0%, #9F96F0 100%) 0% 0% no-repeat padding-box;
-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
 .personnalisation-button::before
{
    content:' ';
    position:absolute;
    left:5px;
    top: 7px;
    width:13px;
    height:13px;
    background-size:100% 100%;
    display:block;
    background-image:url(../../public/assets/images/bullet.svg);
}
.personnalisation-button
{
    position:relative;
    font-size:13px;
    background: transparent linear-gradient(90deg, #CBDCF8 0%, #DDD5F7 100%) 0% 0% no-repeat padding-box;
border: 1px solid #FFFFFF00;
border-radius: 8px;
padding:5px 10px 5px 25px;
} 
.products.cols2 .personnalisation-actions
{
    left:5px;
    top:5px;
}
.products.cols2 .personnalisation-button
{
    padding: 3px 7px 3px 15px;
}
.products.cols2 .personnalisation-button::before
{
    top:4px;
    width: 9px;
    height: 9px;
    left:4px;
}
.products.cols2 .like
{
    right:5px;
    top:5px;
}
.products.cols2 {
    width:calc(100% + 10vw) !important;
    left:-5vw;
    position:relative;
}
.products.cols2 .like img 
{
    width:15px !important;
}
.like
{
    cursor:pointer;
    position:absolute;
    top:10px;
    z-index:14;
    text-align: right;
    right:15px;
}
.personnalisation-actions
{
    position:absolute;
    top:10px;
    text-align: left;
    left:15px;
    z-index:10;
}
 .colors-count
{
    
    font-size:13px;

} 
.popup-beta-access .content .title {
    font-size:40px;
    font-weight: 600;
    line-height: 36px;
}
.popup-beta-access .content .text
{
    margin-top:30px;
    font-size:14px;
}
.popup-beta-access .content
{
    color:#fff;
    background: rgba(0, 0, 0, 0.5) 0% 0% no-repeat padding-box;
    border-radius: 11px;
    padding:50px;
    width:80%;
    position:absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    max-width:500px;
    backdrop-filter: blur(17px);
    -webkit-backdrop-filter: blur(17px);
}
.popup-beta-access .cross 
{
    position:absolute;
    top:-15px;
    cursor: pointer;
    right:-10px;
}
.beta-title-white.little 
{
    width:95px;
}
.popup-beta-access
{
    position:fixed;
    top:0;
    left:0;
    text-align: center;
    z-index:102;
    width:100vw;
    height:100vh;
    background: rgba(0, 0, 0, 0.32) 0% 0% no-repeat padding-box;
}
 .carousel-cell a
{
    text-decoration: none;
    color:#000;
    position:absolute;
    z-index:1;
    left:0;
    top:0;
    width:100%;
    height:100%;
} 
.title-collection .outline
{
    background-image: -webkit-linear-gradient(#000 50%, #000 50%);
    background-position: 0 0;
    background-size: 0% 0%;
    text-stroke: 1px #000;
    -webkit-text-stroke: 1px #000;
    color: #fff;
    white-space: nowrap;
    color: transparent;
}
.title-collection
{
    font-weight: 600;
    font-size:55px;
}
.collection-description
{
    text-align:center;
    max-width:700px;
    width:70%;
    margin-left:auto;
    margin-right:auto;
}

.collection-text
{
    font-size:16px;
}

.loader
{	

	background:rgba(255,255,255,0.8);
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;

	z-index:6;
}
.beta-screen-container
{
    overflow: hidden;
}
#betacontainer.nointro
{
    max-height:100vh;
    transform: none !important;
}
#betacontainer.betaintro
{
    
    transition:all .5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
 .loader img
{
	width:200px;
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
}
.beta-content .link.whatsisbeta

{
    left:50%;
    transform: translateX(-50%);
    position:absolute;
    bottom:50px;
}
.beta-content .link {
    font-size:16px;
    color:#fff;
    cursor: pointer;
}
.custom-logo-beta
{
    filter: brightness(0) invert(1);
    position:absolute;
    top:50px;
    left:50%;
    transform: translateX(-50%);
    align-self: center;

}

.beta-content .content
{
    min-height:100vh;
    
    max-width: 800px;
    position: relative;
    left: 50vw;
    top:0;
    justify-content: center;
    display: flex;
    flex-direction: column;
    transform: translateX(-50%);
    color:#fff;
}

.beta-content h2 {
    margin: 150px auto 3rem;
    font-size:68px;
    text-align: center;
    font-weight: 400;
}
.beta-video-bg .video
{
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    min-width: 100%;
    min-height:100%;
    max-width:500%;
    max-height: 500%;
}
.beta-video-bg
{
    position:fixed;
    left:0;
    top:0;
    width:100vw;
    height:100vh;
    backdrop-filter: blur(20px);
    background:rgba(0,0,0,0.8);
    z-index: 102;
    overflow: hidden;
}
.beta-voile
{
    backdrop-filter: blur(20px);
    background:rgba(0,0,0,0.8);
    position:fixed;
    left:0;
    top:0;
    width:100vw;
    z-index: 103;
    height:100vh;
}
.beta-content {
 
    position:fixed;
    left:0;
    top:0;
    width:100vw;
    min-height:100vh;
   
    z-index: 105;
    overflow-y: auto;
    overflow-x:hidden;
}
.beta-title-white {
    display: inline-block;
    width:200px;
    filter:brightness(0) invert(1);
    margin-left:20px;
   
}
.beta-content .text-bold {
    font-size:32px;
    margin-top:50px;
    margin-bottom:30px;
    font-family: "ProFontWindows";
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
}
.beta-content .text {
    text-align: center;
    font-size:18px;
}
.beta-content input::placeholder
{
    color:#fff;
}
.beta-content textarea::placeholder
{
    color:#fff;
    font-style: italic;
}
.beta-content textarea {
    color:#fff;
    font-size:16px;
    border:1px solid #a1a1a1;
    border-radius: 3px;
    padding:20px 20px;
    background:transparent;
}
.beta-content input {
    color:#fff;
    font-size:16px;
    border:1px solid #a1a1a1;
    border-radius: 3px;
    padding:13px 20px;
    background:rgba(255,255,255,0.15);

    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);

}
.beta-content .form {
    margin-top:30px;
    padding-bottom:50px;
}
.beta-content .form .form-line input{
    width:49%;
}
.beta-content .form .form-line {
    display:flex;
    justify-content: space-between;
}
.beta-content .form button.white {
    background:#fff;
    border:1px solid #FFFFFF;
    color:#000;
}
.beta-content .form button
{
    text-transform: none;
    font-size:16px;
    border:1px solid #a1a1a1;
    border-radius:16px;
    background:#393939;
    padding:11px 40px;
    margin-left:auto;
    margin-right:auto;
    display:block;
    color:#fff;
}

.success-message.confirm
{
    font-size:18px;

border-radius: 15px;
background:rgba(255,255,255,0.15);
border:1px solid #b8b8b8;
backdrop-filter: blur(30px);
-webkit-backdrop-filter: blur(30px);
display: block;
    max-width: 460px;
    padding-left: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 20px;
}
.success-message {
    font-size:14px;
    text-align: center;
}


@media screen and (max-width:760px) {


    .beta-content .link.whatsisbeta    {

        bottom:100px !important;
    }

    .beta-content h2
    {
        margin: 80px auto 3rem;
    }
    .beta-content .content.pasintro
    {
        overflow:auto;
    }
    .beta-table .numbers
    {
        margin-top:30px;
    }
    .beta-table .left
    {
        padding-right:0;
    }

    .beta-table-img-right video {
        position:absolute;
        top:50%;
        z-index: 1;
        width:90vw;
        border-radius: 20px;
        height:auto;
        left:50%;
        transform: translate(-50%,-50%);
    
    }

    
    .beta-table-img-right
    {
        position:relative;
        overflow: hidden;
        background: #393939;
        width: 100%;
        height:60vw;
        border-radius: 20px;
        margin-top:30px;
    }
    .beta-table .right 
    {
        display:none;
    }
    .beta-table .signup
    {
        margin-right:0;
        margin-left:auto;
        padding: 11px 40px;
        top:-39px;
        position: relative;
    }
    .beta-table .left .text-left
    {
        font-size:14px !important; 
        margin-top:30px;
    }
    .beta-table .left .elem-round-beta
    {
        font-size:14px !important;
    }
    .beta-table .left .title
    {
        font-size:22px !important;
    }
    .beta-table
    {
        flex-direction: column;
    }
    .beta-table .left
    {
        width:100%;
    }
    .beta-table .right
    {
        width:100%;
    }
    .bottom-screen
    {
        font-size:13px;
        bottom:50px;
    }
    .bottom-screen img
    {
        width:33px;
    }
    .content-scren .visages
    {
        font-size:18px;
    }
    .content-scren .text-big
    {
        font-size:30px;
        
    }
    .content-scren
    {
        text-align: left;
    }
    .beta-content
    {
        padding-bottom:100px;
    }
    .custom-logo-beta
    {
        width:115px;
    }
    .popup-beta-access .content
    {
        width:90%;
        padding:15px;
    }
    .beta-content h2
    {
        font-size:29px !important;
    }
    .beta-title-white
    {
        margin-left: 10px !important;
        width:90px !important;
    }
    .beta-content .content
    {
        width:90%;
    }
    .beta-content .content .text {
        font-size:16px !important;
    }
    .beta-content .content .text-bold {
        font-size:16px !important;
    }
    .beta-content .content input,.beta-content .content textarea,.beta-content .content button,.beta-content .content .link {
        font-size:15px !important;
    }
    .beta-content .content .form-line input:first-child
    {
        margin-bottom:20px;
    }
    .beta-content .content .form-line input {
        width:100% !important;
    }
    .beta-content .content .form-line{
        flex-direction: column;
    }
    .success-message.confirm {
        font-size:16px !important;
    
    }
    .success-message {
        font-size:11px !important;
    
    }
}



@media screen and (min-width:1024px) and (max-width:1240px) {
  
    .beta-content .content
    {
        min-width: 621px;
    }
 
    
}
@media screen and (min-width:1220px) and (max-width:1400px) {


    .beta-table .left .title
    {
        font-size:32px !important;
    }
    .beta-table .left .text-left
    {
        font-size:22px !important;
    }
    .beta-table .left .elem-round-beta
    {
        font-size:18px !important;
    }
    .beta-table .numbers,.beta-table .left .text-left
    {
        margin-top:30px !important;
    }
}

@media screen and (min-width:1024px) and (max-width:1220px) {
    .beta-table .left .title
    {
        font-size:30px !important;
    }
    .beta-table .left .text-left
    {
        font-size:20px !important;
    }
    .beta-table .left .elem-round-beta
    {
        font-size:16px !important;
    }
    .beta-table .numbers,.beta-table .left .text-left
    {
        margin-top:30px !important;
    }
}


@media screen and (min-width:760px) and (max-width:1024px) {

    .beta-table .numbers
    {
        margin-top:30px;
    }
    .beta-table .left
    {
        padding-right:0;
    }
    body .beta-table .beta-table-img-right
    {
        position:relative;
        overflow: hidden;
        background: #393939;
        width: 100%;
        height:60vw;
        border-radius: 20px;
        display:block !important;
        margin-top:30px;
    }

    .beta-table-img-right video {
        position:absolute;
        top:50%;
        z-index: 1;
        width:90vw;
        border-radius: 20px;
        height:auto;
        left:50%;
        transform: translate(-50%,-50%);
    
    }

  
       
    .beta-table .right 
    {
        display:none;
    }
    .beta-table .signup
    {
        margin-right:0;
        margin-left:auto;
   
        top:-39px;
        position: relative;
    }
    .beta-table .left .text-left
    {
        /* font-size:14px !important;  */
        margin-top:30px;
    }
    /* .beta-table .left .elem-round-beta
    {
        font-size:14px !important;
    }
    .beta-table .left .title
    {
        font-size:22px !important;
    } */
    .beta-table
    {
        flex-direction: column;
    }
    .beta-table .left
    {
        width:100%;
    }
    .beta-table .right
    {
        width:100%;
    }
    .bottom-screen
    {
        /* font-size:13px; */
        bottom:50px;
    }
    /* .bottom-screen img
    {
        width:33px;
    } */
    /* .content-scren .visages
    {
        font-size:18px;
    }
    .content-scren .text-big
    {
        font-size:30px;
        
    } */
    .content-scren
    {
        text-align: left;
    }

    .beta-content h2
    {
        font-size:55px !important;
    }
    .beta-title-white
    {
        margin-left: 15px !important;
        width:160px !important;
    }
    .beta-content .content
    {
        width:80%;
    }
    .beta-content .content .text {
        font-size:18px !important;
    }
    .beta-content .content .text-bold {
        font-size:24px !important;
    }
    .beta-content .content input,.beta-content .content textarea,.beta-content .content button,.beta-content .content .link {
        font-size:16px !important;
    }
    .success-message.confirm {
        font-size:16px !important;
    
    }
    .success-message {
        font-size:14px !important;
    
    }
}
</style>