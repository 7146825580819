<template>
  
  <div class="collection-content" v-if="glass.brand_name=='Visages'">
        <br /><br /><br /><br />
        <video class="contenumob video-collec-big" autoplay loop playsinline muted>

<source src="https://player.vimeo.com/progressive_redirect/playback/920079968/rendition/720p/file.mp4?loc=external&log_user=0&signature=1f081d7251214e099ff30093abe3f10ac0cf954735e5a78059621c4fc5714d0d"
        type="video/mp4">

Sorry, your browser doesn't support embedded videos.
</video> 
        <div class="collection-classic-table" v-if="language=='fr'">
            <div class="cell">
                <img src="../../../../public/assets/images/icon1.png" width="57"  /><br />
                <div class="cell-title">
                    LÉGÈRE, CONFORTABLE & DURABLE
                </div>
                <div class="cell-description">
                    Un Poids de 30g en Moyenne, Fabriquée avec des Matériaux Éco-Responsables et Robustes.
                </div>
                <img class="arrow" src="../../../../public/assets/images/arrow_white.png"  />
            </div>
            <div class="cell">
                <img src="../../../../public/assets/images/icon2.png" width="41"  /><br />
                <div class="cell-title">
                    MADE IN FRANCE
                </div>
                <div class="cell-description">
                    100% Conçue et Fabriquée en France.
                </div>
                <img class="arrow" src="../../../../public/assets/images/arrow_white.png"  />
            </div>
            <div class="cell">
                <img src="../../../../public/assets/images/icon3.png" width="29"  /><br />
                <div class="cell-title">
                    PROCESSUS ZÉRO DÉCHET
                </div>
                <div class="cell-description">
                    Zero Stock, Uniquement Produit à la Demande.
                </div>
                <img class="arrow" src="../../../../public/assets/images/arrow_white.png"  />
            </div>
            <div class="cell">
                <img src="../../../../public/assets/images/icon4.png" width="80"  /><br />
                <div class="cell-title">
                    IMPRESSION 3D
                </div>
                <div class="cell-description">
                    Fabrication Additive avec des Matériaux Bio-Sourcés.
                </div>
                <img class="arrow" src="../../../../public/assets/images/arrow_white.png"  />
            </div>
        </div>
        <div class="collection-classic-table" v-else>
            <div class="cell">
                <img src="../../../../public/assets/images/icon1.png" width="57"  /><br />
                <div class="cell-title">
                    LIGHT, COMFORTABLE & DURABLE
                </div>
                <div class="cell-description">
                    Weighing 30g on Average, with Robust Sustainable Materials.
                </div>
                <img class="arrow" src="../../../../public/assets/images/arrow_white.png"  />
            </div>
            <div class="cell">
                <img src="../../../../public/assets/images/icon2.png" width="41"  /><br />
                <div class="cell-title">
                    MADE IN FRANCE
                </div>
                <div class="cell-description">
                    100% Designed and Produced in France.
                </div>
                <img class="arrow" src="../../../../public/assets/images/arrow_white.png"  />
            </div>
            <div class="cell">
                <img src="../../../../public/assets/images/icon3.png" width="29"  /><br />
                <div class="cell-title">
                    ZERO WASTE PROCESS
                </div>
                <div class="cell-description">
                    Zero Stock, Only Produced On-Demand.
                </div>
                <img class="arrow" src="../../../../public/assets/images/arrow_white.png"  />
            </div>
            <div class="cell">
                <img src="../../../../public/assets/images/icon4.png" width="80"  /><br />
                <div class="cell-title">
                    3D PRINTED
                </div>
                <div class="cell-description">
                    Additive Manufacturing Process with Bio-Sourced Materials.
                </div>
                <img class="arrow" src="../../../../public/assets/images/arrow_white.png"  />
            </div>
        </div>
        <video class="contenudesk video-big" autoplay loop playsinline muted>

            <source src="../../../../public/assets/fabrication.mp4"
                    type="video/mp4">

            Sorry, your browser doesn't support embedded videos.
        </video>

        <img src="../../../../public/assets/images/packaging2.jpg" style="width:100%;"  /><br />

        <br /><br /><br />
        
        <div class="collection-classic-black">
            <img class="zero" src="../../../../public/assets/images/zero.png"  />
            <div class="left" v-if="language=='fr'">
                <div class="black-title">Sur-<span class="i">mesure</span></div>
                <div class="black-description">
                    Dans une société de Surproduction et de Surconsommation, l'Industrie de la Mode Fabrique en Excès. Notre Solution ? La Production à la Demande.
                </div>
                <div class="black-bottom-elements">
                    <div class="black-bottom-element">ZÉRO STOCK, ZÉRO DÉCHET.</div>
                    <div class="black-bottom-element">ENTIÈREMENT INDIVIDUALISÉ & UNIQUE.</div>
                    <div class="black-bottom-element">FABRIQUÉE EXCLUSIVEMENT POUR VOUS.</div>
                </div>
            </div>
            <div class="left" v-else>
                <div class="black-title">Made-<span class="i">to-order</span></div>
                <div class="black-description">
                    In a World of Overproduction and Overconsumption, The Fashion Industry Produces More Than Necessary. Our Solution ? On-Demand Production. 
                </div>
                <div class="black-bottom-elements">
                    <div class="black-bottom-element">ZERO INVENTORY, ZERO WASTE.</div>
                    <div class="black-bottom-element">FULLY INDIVIDUALISED & UNIQUE.</div>
                    <div class="black-bottom-element">MADE FOR YOU AND YOU ONLY.</div>
                </div>
            </div>
            <div class="right">

            </div>
        </div>

        <br /><br /><br />
        <!-- <div class="creations-content">
            <div class="title">#Vos créations</div>
            <br /><br />
            <div class="slider">
                <div class="slider-creations real">
                    <template v-for="(l,index) in loop" :key="index">
                        <div class="carousel-cell"><img src="../../../../public/assets/images/creation.png" /></div>
                    </template>
                </div>
                <div id="prev-creation"><img src="../../../../public/assets/images/arrow_scan.svg" width="45" /></div>
                <div id="next-creation"><img src="../../../../public/assets/images/arrow_scan.svg" width="45" /></div>
            </div>

        </div>
        <br /><br /><br /> -->
            <!-- <ReviewsToggle :language="language" :glass="glass" />
           
            <br /><br /><br /> -->
            <HomeFaqComponent :language="language"  />
            <!-- <br /><br /><br />
            <RelatedSlider /> -->
    
        <!-- <div class="line-top">
            <div class="left">
                <div class="image">
                     <video autoplay loop playsinline muted>

                        <source src="../../../../public/assets/fabrication.mp4"
                                type="video/mp4">

                        Sorry, your browser doesn't support embedded videos.
                    </video>
                </div>
            </div>
            <div class="right">
                <div class="title">{{glass.product_content.title1}}</div><br />
                <div class="text">{{glass.product_content.text1}}</div>
            </div>
        </div>
        <div class="line-bottom">
            <div class="left">
                <br /><br />
                <div class="title">{{glass.product_content.title2}}</div><br />
                <div class="text">{{glass.product_content.text2}}</div>
            </div>
            <div class="right">
                <div class="image" :style="'background-image:url('+glass.product_content.photo2+')'"></div>
                
            </div>
        </div>
        <br /><br /><br />
        <div class="creations-content">
            <div class="title">#Vos créations</div>
            <br /><br />
            <div class="slider">
                <div class="slider-creations real">
                    <template v-for="(l,index) in loop" :key="index">
                        <div class="carousel-cell"><img src="../../../../public/assets/images/creation.png" /></div>
                    </template>
                </div>
                <div id="prev-creation"><img src="../../../../public/assets/images/arrow_scan.svg" width="45" /></div>
                <div id="next-creation"><img src="../../../../public/assets/images/arrow_scan.svg" width="45" /></div>
            </div>

        </div>
        <br /><br /><br /><br /><br /><br /><br />
        <img :src="glass.product_content.photo3" class="image-collection" />
        <br /><br />
        <img :src="glass.product_content.photo4" class="image-collection" /> -->

    </div>

    <div class="collection-content" v-else-if="glass.id==30">
        <!-- <BrandBigComponent   :language="language"/>

        <BrandProductionComponent   :language="language"/>
        <BrandBottomComponent    :language="language"/> -->
    </div>
    
    <div  class="collection-content" v-else-if="glass.id==25 || glass.id==26 || glass.id==27 || glass.id==28">
        <div class="collection element-big" v-if="language=='fr'" :class="{op:glass.id==26,infini:glass.id==25,s:glass.id==28}">
            <div class="content">
                <div class="title">Expertise du <span class="italic">Sur-Mesure</span></div>
                <div class="col intro">
                    Fort d'un héritage de plus de 150 ans, EB Meyrowitz se distingue comme une référence internationale dans le domaine du sur-mesure lunettier, renommé pour ses nombreuses créations à destination d'une clientèle très exclusive, comprenant des célébrités d'Hollywood jusqu'à des personnalités politiques iconiques.
                </div>
                <div class="col text">
                    Cette institution s'associe désormais à VISAGES® pour créer la collection LOUIS – un hommage à l'héritage de Meyrowitz fusionné à l'approche révolutionnaire de VISAGES® en matière d'innovation lunetière.
<br /><br />
À travers une série limitée, chaque pièce est une expression singulière de tradition, luxe et précision, symbolisant un nouveau chapitre de l'artisanat d'exception.
                </div>
            </div>
        </div>
        <div class="collection element-big" v-else :class="{op:glass.id==26,infini:glass.id==25,s:glass.id==28}">
            <div class="content">
                <div class="title">Custom-fit <span class="italic">Expertise</span></div>
                <div class="col intro">
                    With its storied 150-year history, EB Meyrowitz is an international reference in bespoke eyewear craftsmanship, renowned for tailoring products to an exclusive clientele that ranges from Hollywood celebrities to iconic political figures. 

                </div>
                <div class="col text">
                    In a special collaboration, EB Meyrowitz unites with VISAGES® to create the meticulously curated LOUIS collection. This partnership stands as a tribute to Meyrowitz’s enduring heritage, merged with VISAGES®’s innovative approach to modern craftsmanship.
                    <br /><br />Together, presenting a limited series where each piece is a unique narrative of luxury, precision, and sustainable elegance.
                </div>
            </div>
        </div>
        <div class="collection element-two">
            <div class="top">
                <div class="left image"  :class="{op:glass.id==26,infini:glass.id==25,s:glass.id==28,c:glass.id==27}">
                </div>
                <div class="right content">
                    <template v-if="glass.id==25">
                        <span v-if="language=='fr'">Titane <br /><span class="italic">Imprimé en 3D</span></span>
                        <span v-else>3D-Printed<br /><span class="italic">Titanium</span></span>
                    </template>
                    <template v-else-if="glass.id==26">
                        <span v-if="language=='fr'">Titane <br /><span class="italic">Imprimé en 3D</span></span>
                        <span v-else>
                            Fully 3D-Printed<br /><span class="italic">Titanium</span>
                        </span>
                       
                    </template>
                    <template v-else>
                        <span v-if="language=='fr'">
                            Matériaux Bio-Sourcés<br /><span class="italic">Imprimé en 3D</span>
                        </span> 
                        <span v-else>
                            3D-Printed<br /><span class="italic"> Bio-Sourced Materials</span>
                        </span>
                    </template>
                </div>

            </div>
            <div class="bottom">
                <div class="left content">
                    <template v-if="glass.id==25">
                        <span v-if="language=='fr'">
                            <span class="italic">& Inserts</span><br />en Bio-Plastique
                        </span>
                        <span v-else>

                              


                            <span class="italic">& Bio-Plastic</span><br />Inserts
                        </span>
                        <div v-if="language=='fr'" class="round-element">Finition Patine</div>
                        <div v-else class="round-element">Patinated finish</div>
                        <div  v-if="language=='fr'" class="round-element">Système de Charnières Sans Vis</div>
                        <div  v-else class="round-element">Proprietary Screw-Less Hinge System</div>

                    </template>
                    <template v-else-if="glass.id==26">
                        <span v-if="language=='fr'">
                            <span class="italic">Design</span><br />Primé
                        </span>
                        <span v-else>
                            <span class="italic">Award Winning</span><br />Design
                        </span>

                    </template>

                    <template v-else>
                       
                       
                        <div  v-if="language=='fr'" class="round-element">Finition Patine</div>
                        <div v-else class="round-element">Patinated Finish</div>
                       

                    </template>
                </div>
                <div class="right image" :class="{op:glass.id==26,infini:glass.id==25,s:glass.id==28,c:glass.id==27}">
                </div>

            </div>
        </div>
        <div class="collection element-big2">


            <img v-if="glass.id==26" class="img" src="../../../../public/assets/images/bigop.jpg" />
            <img v-if="glass.id==25" class="img" src="../../../../public/assets/images/biginfini.jpg" />
            <img v-if="glass.id==27" class="img" src="../../../../public/assets/images/bigc.jpg" />
            <img v-if="glass.id==28" class="img" src="../../../../public/assets/images/bigs.jpg" />
        </div>
        <!-- <div class="collection element-sustainability">
            <img class="zero" src="../../../../public/assets/images/zero2.png"  />
            <div class="left"  :style="'background-image:url(/assets/images/collec5.jpg);'"></div>
            <div v-if="language=='fr'" class="right">
                
                <div class="round-element">
                    IMPRESSION 3D
                </div>
                <br />
                <div class="round-element">
                    À LA DEMANDE
                </div>
                <br />
                <div class="round-element">
                    ZÉRO DÉCHET
                </div>

                <div class="title">L'Éco-Innovation dans Chaque Monture</div>
                Chaque monture est imprimée en 3D, à la demande, en Europe, avec des matériaux éco-responsables. À travers nos techniques de fabrication innovantes, nous offrons des lunettes entièrement sur-mesure, tout en assurant un processus de production zéro déchet. 
            </div>
            <div v-else class="right">
                
                <div class="round-element">
                     3D PRINTED
                </div>
                <br />
                <div class="round-element">
                    ON DEMAND
                </div>
                <br />
                <div class="round-element">
                    ZERO WASTE
                </div>

                <div class="title">Eco-Innovation in Every Frame</div>
                Each product is fully 3D-printed on-demand in Europe using sustainable materials. Through our innovative manufacturing techniques, we ensure fully individualized eyewear with a zero waste manufacturing process - setting new standards in sustainability.
            </div>
        </div> -->
    </div>
</template>

<script>
import RelatedSlider from '../../widget/RelatedSlider.vue'
import CollectionWidget from '../../widget/CollectionWidget.vue'
import HomeFaqComponent from './HomeFaqComponent.vue'
import ReviewsToggle from './ReviewsToggle.vue'

import BrandBottomComponent from '../Brand/Reveur/BrandBottomComponent.vue';
import BrandProductionComponent from '../Brand/Reveur/BrandProductionComponent.vue';
import BrandBigComponent from '../Brand/Reveur/BrandBigComponent.vue';

export default {
    name: "CollectionContent",
    props: ["glass","language"],
    components:{
        CollectionWidget,
        RelatedSlider,
        ReviewsToggle,
        HomeFaqComponent,
        BrandBottomComponent,
        BrandProductionComponent,
        BrandBigComponent
    },

    methods: {},
    data(){
        return{
            loop: 7
        }
    }
}
</script>

<style scoped>
@media screen and (min-width:760px) and (max-width:1024px)
{
.element-big{
    min-height: 55vh !important;
}
}

@media screen and (max-width:760px)
{
    .element-big{
        min-height:100px !important;
        background-repeat: no-repeat !important;
        background-size: 100% auto !important;
    background-position: center top !important;
    margin-bottom: 250px !important;
        margin-top:0 !important;
    }
   
    .round-element
    {
        font-size: 12px !important;
        margin-top:7px !important;
    }
    .element-sustainability .zero
    {
        top:-50px !important;
        left:auto !important;
        right:0 !important;
        width:115px !important;
    }
    .element-sustainability .right .title
    {
        margin-top:15px;
        margin-bottom: 15px;
        font-size: 32px !important;
    }
    .collection-content .element-sustainability .right {
        order:2;
        width:100% !important;
        padding-bottom:0px;
        padding-top:30px;
    }
    .collection-content .element-sustainability .left {
        order:1;
        height:35vh !important;
        min-height: 350px !important;
        width:100% !important;
    }
    .collection-content .element-sustainability {
        flex-direction: column;
    }
    body .collection-content .element-sustainability {
        margin-top:50px !important;
        padding-bottom:100px;
    }
    .element-big2
    {
        min-height: 40vh !important;
        margin-top:0 !important;
        margin-bottom: 0 !important;
    }
    .element-two .top,
    .element-two .bottom {
        position: relative;
    }
    .element-two .image.right {
        margin-left:15%;
    }
    .element-two .image {
        width:60% !important;
        height:180px !important;
        position: absolute !important;
    }
   
    .element-two .content.left {
        margin-left:0 !important;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end
    }
    .element-two .content.right {
        margin-left:35%;
        text-align: left !important;
    }
    .element-two .content {
        width:65% !important;
        font-size:25px !important;
        height:300px !important;
    }
    .element-big .content {
        flex-direction: column !important;
        position:relative !important;
        top:156px;
        
    }
    .element-big .content .col {
        width:100% !important;
    }
    .element-big .content .title {
        width:100% !important;
    }
    .collection-classic-black .zero
    {
        top: calc(50% - 60px) !important;
        left: calc(50% - 60px) !important;
        width:115px !important;
    }
    .collection-classic-black .left {
        padding:100px 5% !important;
    }
    .black-bottom-elements {
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .black-bottom-element
    {
        padding: 6px 10px 7px 10px !important;
    font-size: 16px !important;
    }
    .black-description {
        font-size:18px !important;
        text-align: center;
        padding-left: 10%;
        padding-right:10%;
        
    }
    .black-title {
        text-align: center;
     font-size:40px !important;   
    }
    .cell-title
    {
        font-size:23px !important;
    }
    .collection-classic-table .arrow {
        width:43px !important;
    }
}
.black-bottom-elements {
    margin-top:10px;
}
.collection-classic-black .zero {
    position:absolute;
    left:calc(52% - 110px);
    width:220px;
    z-index: 2;
    animation: rotating 6s linear infinite;
    top:calc(50% - 110px);
    filter: brightness(0) invert(1);
}
.black-bottom-element:hover
{
    color:#000;
    background:#fff;
}
.black-bottom-element
{
    white-space: nowrap;
    margin-top:10px;
    display:inline-block;
    transition: all 0.3s;
    padding:4px 20px 10px 20px; 
    
    font-size:25px;
    border:1px solid #fff;
    border-radius: 34px;
}
.black-title .i {
    font-weight: 600;
    font-style: italic;
}
.black-title {
    font-size:85px;
    line-height: 0.8;
}
.black-description {
    font-size:22px;
    margin-top:20px;
}
.collection-classic-black .right {
    background-image:url('../../../../public/assets/images/made_to_order_visages_1x1_v2.jpg');
    background-size:cover;
    background-position:center center;
    position:relative;
    display:table-cell;
    width:48%;
}
.collection-classic-black .left {
    background:#000;
    padding:10% 5%;
    color:#fff;
    display:table-cell;
    width:52%;
}
.collection-classic-black {
    display: table;
    position: relative;
}  
.collection-classic-table .cell:last-child
{
   
    border-right:0;
    
}
.collection-classic-table .cell
{
    display: table-cell;
    vertical-align: bottom;
    padding:5%;
    height:100%;
    width:25%;
    border-right:1px solid #ddd;
    
}
.collection-classic-table .arrow {
    width:56px;
    height:auto;
    margin-top:20px;
}
.cell-description
{
    margin-top:20px;
   font-size:18px; 
}
.cell-title
{
    line-height: 0.8;
   font-size:30px; 

}
.collection-classic-table {
  
    height:100%;
   
    border-top:1px solid #ddd;
    display: table;
}
.round-element:hover
{
    background: rgba(117, 110, 93, 1);
    color:#fff;
}

.round-element {
    margin-top:10px;
    border: 1px solid rgba(117, 110, 93, 1);
border-radius: 34px;
font-size:25px;
width:auto;
transition: all 0.3s;
line-height: 1;
display:inline-block;
clear: both;
padding:0px 15px 4px 15px;
}


.element-sustainability .zero {
    position:absolute;
    top:-110px;
    animation: rotating 6s linear infinite;
    left:calc(50% - 45px);
    width:220px;
}

.element-sustainability .right .title {
    font-size: 60px;
    font-weight: 600;
    font-style: italic;
    word-wrap:unset;
    margin-top:10px;
    margin-bottom: 10px;

    line-height:0.8;
}
.element-sustainability .right {
    width:45%;
    padding-left:5%;
    position: relative;
    padding-right:5%;
    font-size: 15px;
    color:rgba(117, 110, 93, 1);
}
.element-sustainability .left {
    width:55%;
    min-height: 450px;
    height: 50vh;
    background:grey;
    background-size:cover;
    background-position: center center;
   
}
.collection-content .element-sustainability
{
    margin-top:250px !important;
    position:relative;
    padding-left:10%;
    padding-right:10%;
    display: flex;
    align-items: flex-end;
}
.collection-content .collection
{
    margin-top:150px;
}
.element-big2 .img {
    width:80%;
    margin-left:auto;
    margin-right:auto;

}

.element-big2 {
    min-height:80vh;
    
    display:flex;
    align-items: center;
    justify-content: center;
    width:100%;

}
.element-two .left.image.s {
    background-image:url('../../../../public/assets/images/lefts.jpg'); 
}
.element-two .left.image.op {
    background-image:url('../../../../public/assets/images/leftop.jpg'); 
}
.element-two .left.image.infini {
    background-image:url('../../../../public/assets/images/leftinfini.jpg'); 
}
.element-two .left.image.c {
    background-image:url('../../../../public/assets/images/leftc.jpg'); 
}

.element-two .right.image.s {
    background-image:url('../../../../public/assets/images/rights.jpg'); 
}
.element-two .right.image.op {
    background-image:url('../../../../public/assets/images/rightop.jpg'); 
}
.element-two .right.image.infini {
    background-image:url('../../../../public/assets/images/rightinfini.jpg'); 
}
.element-two .right.image.c {
    background-image:url('../../../../public/assets/images/rightc.jpg'); 
}
.element-two .image {
  
    background-size:cover;
    background-position: center center;
    height:500px;
    width:55%;
}
.element-two .content .italic {
    font-weight: 600;
    font-style: italic;
}
.element-two .content 
{
    text-align:right;
    height:400px;
    width:40%;
}
.element-two .top {
    align-items: flex-end;
}
.element-two .left.content .round-element {
    border:1px solid #fff;
    text-transform: uppercase;
    font-size:18px;
    margin-top:0;
}
.element-two .left.content 
{
    margin-left:5%;
    text-align:left;
}
.element-two .right.content 
{
    margin-right:5%;
    text-align:right;
}
.element-two .top,
.element-two .bottom {
    display:flex;
    padding-left:10%;
    padding-right:10%;
}

.element-two .content {
    color:#fff;
    font-size:60px;
    padding:5%;
    line-height: 0.8;

    background:rgba(117, 110, 93, 1);
}
.element-big.infini
{
    background-image:url('../../../../public/assets/images/topinfini.jpg'); 
}
.element-big.s
{
    background-image:url('../../../../public/assets/images/tops.jpg'); 
}
.element-big.op
{
    background-image:url('../../../../public/assets/images/topop.jpg'); 
}
.element-big {
    min-height:100vh;
    position:relative;
    width:100%;
    background-image:url('../../../../public/assets/images/collection-top.jpg');
    background-size:cover;
    background-position:center center;

}
.element-big .content .col.intro {
    font-weight: 600;
    text-transform: uppercase;
}
.element-big .content .col {
    font-size:15px;padding:20px;
}
.element-big .content .col {
    width:30%;
}
.element-big .content .title .italic
{
    font-weight: 600;
    font-style: italic;
    
}
.element-big .content .title {
    padding:10px;
    font-size:60px;
    width:40%;
    line-height: 0.8;
}
.element-big .content {

    background: rgba(117, 110, 93, 0.8) 0% 0% no-repeat padding-box;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    color:#fff;
    padding:5%;
    display:flex;
    position:absolute;
    bottom:0;
    width:100%;
    left:0;
}
.video-big
{
    width:100vw;
    max-width: 100vw;
    height:auto;
}
/* .line-top
{
    position: relative;
    width:90%;
    margin-left:auto;
    margin-right:auto;
    padding:0;
    display:flex;
    flex-direction: row;
    min-height:50vh;
}

.line-top .left .image video {
    position:absolute;
    min-width:100%;
    height:auto;
    min-height:100%;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);

}
.line-top .left .image
{
    
    overflow:hidden;
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-position: center center;
    background-size:cover;
}
.line-top .right .text
{
    font-size:13px;
}
.line-top .right .title
{
    font-size:30px;
}
.line-top .right
{
    width:50%;
    padding-left:5%;
    padding-bottom:10%;
}
.line-top .left
{
    width:50%;
    position: relative;
    
}

.line-bottom
{
    position: relative;
    width:90%;
    margin-left:auto;
    margin-right:auto;
    padding:0;
    display:flex;
    flex-direction: row;
}
.line-bottom .right .image
{
    position:absolute;
    left:0;
    top:0;
    width:75%;
    height:100%;
    background-position: center center;
    background-size:cover;
}
.line-bottom .left .text
{
    font-size:13px;
}
.line-bottom .left .title
{
    font-size:30px;
}
.line-bottom .left
{
    width:50%;
    padding-right:5%;
    padding-bottom:10%;
}
.line-bottom .right
{
    width:50%;
    position: relative;
    
}

#next-creation
{
    position:absolute;
    top:50%;
    right:10px;
    transform: translateY(-50%);
    z-index:3;
}
#prev-creation img
{
    transform:rotate(-180deg) ;
}
#prev-creation
{
    position:absolute;
    top:50%;
    left:10px;
    z-index:3;
    transform: translateY(-50%);
}

.slider-creations.real .carousel-cell img
{
    width:358px;
    height:auto;
    border-radius: 13px;
}
.creations-content .title
{
    position: relative;
    width:90%;
    margin-left:auto;
    margin-right:auto;
    padding:0;
    font-size:70px;
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    transition: all 0.5s;
    background-image: -webkit-linear-gradient(#000 50%, #000 50%);
    background-position: 0 0;
    background-size: 0% 0%;
    text-stroke: 1px #000;
    -webkit-text-stroke: 1px #000;
    color: #000;
    white-space: nowrap;
    color: transparent;
    text-decoration: none;
} */

</style>import { lang } from 'moment'
